nav.navbar.navbar-expand-lg.navbar-dark.bg-dark {
  background-color: #007bff !important;
}
div.container.py-2 {
  margin: 0px 0px 0px 7vw !important;
}

img {
  margin-top: -6px !important;
}

/* ------------------ my mobile nav ---------------- */
.myMobileNav {
  position: absolute;
  top: 38px;
  left: 40px;
  cursor: pointer;
  filter: opacity(0.7);
  padding: 10px;
  width: 10%;
}

.sidenav {
  position: relative;
  height: 100%;
  width: 0;
  position: fixed;
  z-index: 111111111111;
  top: 0;
  left: 0;
  /* background-color: #111; */
  background-color: #fff;
  color: #111;
  overflow-x: hidden;
  padding-top: 2px;
  transition: 0.5s;
}

.sidenav a {
  padding: 8px 8px 8px 32px;
  text-decoration: none;
  font-size: 25px;
  font-family: inherit;
  color: #818181;
  display: block;
  transition: 0.3s;
}

.sidenav a:hover,
.offcanvas a:focus {
  color: #302e2e;
}

.sidenav .closebtn {
  position: absolute;
  font-size: 36px;
  top: 30px;
  font-weight: 900;
  right: 40px;
  color: #fff;
}

#main {
  transition: margin-left 0.5s;
  padding: 20px;
}

.mobile-link {
  margin-left: 20px;
  margin-top: 12px;
  font-size: 1rem !important;
}
.drop-btn {
  background-color: #007bff;
  font-weight: bold;
  color: #fff;
  text-decoration: underline;
  line-height: 4;
  width: 90%;
  text-align: left;
  padding-left: 50px;
  font-size: 1rem;
  letter-spacing: 2.4px;
}
.sub-links {
  margin-left: 20px;
}
.sub-links.hide {
  display: none;
}

.header__conatiner {
  width: 100vw;
  height: 90px;
}
.logo {
  height: 90px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
}
.logo a {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40%;
  object-fit: contain;
}
.logo a img {
  height: 100px;
  width: 100%;
  object-fit: contain;
}

@media screen and (max-height: 450px) {
  .sidenav {
    padding-top: 15px;
  }
  .sidenav a {
    font-size: 18px;
  }
}

@media only screen and (min-width: 768px) {
  .myMobileNav {
    display: none;
  }
  .sidenav {
    display: none;
  }
  .logo {
    display: none;
  }
}
@media only screen and (max-width: 768px) {
  .social-box {
    display: none;
  }
}

/* nav alignment */
@media only screen and (min-width: 1020px) {
  .outer-container.clearfix {
    display: flex;
    align-items: center;
  }
}
@media only screen and (max-width: 1020px) {
  .outer-container.clearfix {
    display: block;
  }
}
@media only screen and (max-width: 1120px) {
  #mega-menu {
    /* background-color: #007bff; */
    max-height: 230px;
  }
}
@media only screen and (min-width: 1120px) {
  #mega-menu {
    /* background-color: red; */
    max-height: 160px;
  }
}
