.imp-section-colored .imp-section__list .imp-section__list-item {
  color: #fff;
}
.imp-section-colored .imp-section__list h4 {
  border-color: #fff;
}

.imp-section-colored .imp-section__list .imp-section__list-item:nth-child(1) {
  background-color: #98dbf9;
}
.imp-section-colored .imp-section__list .imp-section__list-item:nth-child(2) {
  background-color: #005661b5;
}
.imp-section-colored .imp-section__list .imp-section__list-item:nth-child(3) {
  background-color: #e2bc4d;
}
.imp-section-colored .imp-section__list .imp-section__list-item:nth-child(4) {
  background-color: #708eaa;
}
.imp-section-colored .imp-section__list .imp-section__list-item:nth-child(5) {
  background-color: #494952;
}
.imp-section-colored .imp-section__list .imp-section__list-item:nth-child(6) {
  background-color: #bf9656;
}

/* need of pcc */
.importance_of_ppc-ul {
  font-size: 1.1rem;
  margin-left: 20px;
}
.importance_of_ppc-ul li {
  margin: 15px 0;
  list-style: disc;
}
