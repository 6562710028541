body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100vw;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
/* mobile global */
@media only screen and (max-width: 600px) {
  body {
    width: 88vw;
  }
  .main-header .header-upper .logo-box .logo {
    display: flex;
    justify-content: flex-end;
    width: 100%;
  }
  .instructor-section .background-layer-one {
    width: 100%;
  }
  .benefit-section .background-layer-two {
    width: 100%;
  }
  .benefit-section .content-column .inner-column {
    width: 100%;
  }

  /* BANNER */
  .banner-section .content-column .title {
    font-size: 11px;
    line-height: 1em;
    font-weight: 400;
    letter-spacing: 1px;
  }
  .banner-section .content-column h1 {
    font-weight: 700;
    line-height: 51px;
  }
  .banner-section {
    padding: 0;
    padding-top: 50px;
    padding-bottom: 100px;
    margin: 0px 20px;
    padding: 70px;
  }
  .banner-section .btns-box {
    width: 180%;
  }
  .banner-section .btns-box h4 {
    width: 80%;
  }
  .banner-section .btns-box h5 {
    width: 80%;
  }
  .banner-section .image-column {
    display: none;
  }
  #home {
    padding: 0px !important;
  }
  .benefit-section .images-column .image-three {
    right: 45px;
    top: 130px;
    left: 60px;
  }
}

/* primary text */
.primary_text {
  color: #06092d !important;
  font-size: 16px !important;
  line-height: 1.7em !important;
}

.primary_text-sm {
  color: #06092d !important;
  font-size: 14px !important;
  line-height: 1.5em !important;
}

.circle-layer {
  display: none;
}

.main-header .header-upper .outer-container {
  position: relative;
  padding: 20px 0px 0px 20px;
}

@media only screen and (max-width: 1023px) {
  .main-header {
    position: relative;
    background-color: #fff;
  }
  .main-header .header-upper .outer-container {
    background: #fff;
  }
}

@media only screen and (max-width: 1028px) {
  .auto-container > .row.clearfix {
    width: 90%;
    margin: 0 auto;
  }
}

/* mega menu */
/* .mega-menu {
  padding: 10px !important;
  max-height: 130px !important;
} */
