.section-1 article {
  display: flex;
  flex-wrap: wrap;
}
.section-1 article section img {
  width: 80%;
}
.section-1 article .left {
  flex: 1;
  flex-basis: 320px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 20px;
}
.section-1 article .right {
  flex: 1;
  flex-basis: 320px;
  display: flex;
  justify-content: center;
  align-items: center;
}
/* item1 */
.section-1 article.item1 .left {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.section-1 article.item1 .left h5 {
  font-weight: 700;
  margin-bottom: 10px;
}

.section-1 article.item1 .right {
}
/* item2 */
.section-1 article.item2 {
  flex-direction: row-reverse;
}
.section-1 article.item2 .left {
}
.section-1 article.item2 .right {
}

/* item3 */
.section-1 article.item3 {
  flex-direction: row-reverse;
}
.section-1 article.item3 .left {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}
.section-1 article.item3 .left ul {
  margin-top: 10px;
  margin-left: 15px;
}
.section-1 article.item3 .left ul li {
  list-style: disc;
}
.section-1 article.item3 .left h5 {
  font-weight: 700;
  margin-bottom: 10px;
}
.section-1 article.item3 .right {
}

.imp-section__list-item {
  color: #222 !important;
}
.imp-section__list-item:nth-child(1) {
  background-color: rgb(187, 236, 255);
}
.imp-section__list-item:nth-child(2) {
  background-color: rgb(220, 255, 250);
}
.imp-section__list-item:nth-child(3) {
  background-color: rgb(231, 255, 231);
}
.imp-section__list-item:nth-child(4) {
  background-color: rgb(251, 228, 192);
}
.imp-section__list-item:nth-child(5) {
  background-color: rgba(187, 236, 255, 0.5);
}
.imp-section__list-item:nth-child(6) {
  background-color: rgb(247, 251, 192);
}
