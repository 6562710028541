/* start home page css */

.main_outer {
    display: flex;
    width: 100%;
    min-height: 100vh;
}

.side_menu .user_profile {
    padding: 0;
}

.side_menu .user_profile .profile {
    width: 65px;
    height: 65px;
    border-radius: 50%;
    overflow: hidden;
    box-shadow: 5px 0 17px #e4dede;
    border: 4px solid #fff;
}

.side_menu .profile img {
    max-width: 100%;
    min-height: 100%;
    object-fit: cover;
}

.side_menu .user_profile .media-body h2 {
    font-size: 19px;
    font-weight: bold;
    color: #0a0f2d;
}

.side_menu .user_profile .media-body {
    padding-left: 16px;
}

.side_menu .user_profile .media-body p {
    font-size: 14px;
    color: #838484;
}

.side_menu .menu {
    margin-top: 93px;
}

.side_menu .menu li {
    margin: 0 0 40px 0;
}

.side_menu .menu li:last-child {
    margin: 0;
}

.side_menu .menu a {
    font-size: 20px;
    font-weight: 800;
    color: #cfd6de;
    transition: 0.8s;
}

.side_menu .menu a.active,
.side_menu .menu a:hover {
    color: #0a0f2d;
}

.side_menu .menu a span {
    min-width: 35px;
    display: inline-block;
}

.main_outer .content_right {
    display: inline-block;
    width: 100%;
    background-color: #eaf4f9;
    padding: 33px 50px;
    max-width: calc(100vw - 10px);
    margin-left: 317px;
    float: right;
}

.active_device .media-left .icon-plus {
    width: 42px;
    height: 42px;
    display: inline-block;
    text-align: center;
    border-radius: 50%;
    background-color: #1e82d2;
    line-height: 42px;
    color: #fff;
    font-size: 23px;
}

.content_right .active_device .media-body {
    padding-left: 15px;
}

.content_right .active_device h3 {
    color: #0a0f2d;
    font-size: 20px;
    font-weight: bold;
}

.content_right .active_device p {
    color: #979797;
    font-size: 14px;
}

.content_right .list_icon {
    text-align: right;
}

.content_right .list_icon .icon {
    color: #d3d9e0;
    font-size: 30px;
}

.content_right .list_icon a {
    margin: 0 0 0 11px;
}

.content_right .list_icon a.selected .icon {
    color: #1e82d2;
}


/* start video section css */

.video_outer {
    margin-top: 40px;
}

.video_outer .video_box {
    max-width: 100%;
    width: 100%;
    display: inline-block;
    margin-bottom: 22px;
}

.video_outer .video_box iframe {
    width: 100%;
}

.video_outer .video_box.first_video iframe {
    height: 500px;
}

.video_outer .video_box.video_kitchen {
    margin-bottom: 24px;
}

.video_outer .video_box.video_kitchen iframe {
    height: 234px;
}

