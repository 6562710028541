#accordionExample .card {
  border: none;
}
#accordionExample .card .card-header {
  background-color: #fff;
}
#accordionExample .card .card-header .btn {
  border: none;
  color: #06092d;
  line-height: 32px;
  text-align: left;
}
#accordionExample .card .card-header .btn:hover {
  color: #fff;
  background-color: #06092d !important;
}

#accordionExample .card:nth-child(1) .card-header .btn {
  background-color: #fbeff7;
}
#accordionExample .card:nth-child(2) .card-header .btn {
  background-color: #f9eee1;
}
#accordionExample .card:nth-child(3) .card-header .btn {
  background-color: #e4f5ff;
}
#accordionExample .card:nth-child(4) .card-header .btn {
  background-color: #fdf0f0;
}
#accordionExample .card:nth-child(5) .card-header .btn {
  background-color: #eaf0ed;
}
#accordionExample .card:nth-child(6) .card-header .btn {
  background-color: #f9f3e2;
}
#accordionExample .card:nth-child(7) .card-header .btn {
  background-color: #edfbeb;
}
#accordionExample .card:nth-child(8) .card-header .btn {
  background-color: #f0f2f9;
}
