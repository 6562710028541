.imp-section {
}

.imp-section article {
  width: 80%;
  margin: 0 auto;
}
.imp-section h4 {
  font-weight: bold;
  /* border-bottom: 2px solid #a99f9f; */
  margin-bottom: 5px;
}

.imp-section__list {
  margin-top: 20px;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  gap: 5px;
}
.imp-section__list-item {
  display: flex;
  /* border: 1px solid #62626221; */
  flex-basis: 300px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px;
  border-radius: 2px;
}
.list_image-conatiner {
  padding: 10px;
  width: 200px;
}

/* section 2 */
.section-2 .container {
  display: flex;
}
.section-2 .container .left {
  flex: 1;
  text-align: left;
  font-size: 13px;
}
.section-2 .container .left ul {
  padding: 10px 30px;
}
.section-2 .container .left ul li {
  list-style: disc;
}
.section-2 .container .right {
  flex: 1;
  padding: 10px;
}

.importance_ul {
  font-size: 1.1rem;
  margin-left: 20px;
}
.importance_ul li {
  margin: 15px 0;
  list-style: disc;
}
