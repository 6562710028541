/* LeBari HTML Template */

/************ TABLE OF CONTENTS ***************
1. Fonts
2. Reset
3. Global
4. Main Header / Style Two
5. Banner Section / Two / Three
6. Instructor Section
7. Benefits Section
8. Feature Section / Two
9. Course Section
10. Career Section
11. Skill Section
12. Testimonial Section
13. News Section
14. Clients Section
15. Main Footer Section
16. Professional Section
17. Event Section
18. Goal Section
19. Contact Section
20. Page Title Section
21. Counter Section
22. Team Section
23. Faq Section
24. Profile Section
25. Membership Section
26. Course Filter Section
27. Course Detail Section
28. Blog Section
29. Blog Wudgets Section
30. Blog Detail Section
31. Not Found
32. Contact Form Section
33. Map Section
34. Donate Section

**********************************************/

/* 
	font-family: 'Noto Sans TC', sans-serif;
*/

@import url("font-awesome.css");
@import url("flaticon.css");
@import url("animate.css");
@import url("owl.css");
@import url("animation.css");
@import url("jquery-ui.css");
@import url("custom-animate.css");
@import url("jquery.fancybox.min.css");
@import url("jquery.bootstrap-touchspin.css");
@import url("jquery.mCustomScrollbar.min.css");

/*** 

====================================================================
	Reset
====================================================================

 ***/

* {
  margin: 0px;
  padding: 0px;
  border: none;
  outline: none;
}

/*** 

====================================================================
	Global Settings
====================================================================

 ***/

body {
  font-family: "Noto Sans TC", sans-serif;
  font-size: 14px;
  color: #777777;
  line-height: 1.7em;
  font-weight: 400;
  background: #ffffff;
  -webkit-font-smoothing: antialiased;
  -moz-font-smoothing: antialiased;
}

.bordered-layout .page-wrapper {
  padding: 0px 50px 0px;
}

a {
  text-decoration: none;
  cursor: pointer;
  color: #007cba;
}

button,
a:hover,
a:focus,
a:visited {
  text-decoration: none;
  outline: none !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  position: relative;
  font-weight: normal;
  margin: 0px;
  background: none;
  line-height: 1.6em;
  font-family: "Noto Sans TC", sans-serif;
}

input,
button,
select,
textarea {
}

textarea {
  overflow: hidden;
}

p {
  position: relative;
  line-height: 1.8em;
}

/* Typography */

h1 {
  font-size: 55px;
}

h2 {
  font-size: 40px;
}

h3 {
  font-size: 30px;
}

h4 {
  font-size: 24px;
}

h5 {
  font-size: 20px;
}

h6 {
  font-size: 18px;
}

.auto-container {
  position: static;
  max-width: 1200px;
  padding: 0px 15px;
  margin: 0 auto;
}

.medium-container {
  max-width: 850px;
}

.page-wrapper {
  position: relative;
  margin: 0 auto;
  width: 100%;
  min-width: 300px;
}

ul,
li {
  list-style: none;
  padding: 0px;
  margin: 0px;
}

.main-header .header-upper .outer-container img {
  display: inline-block;
  max-width: 100%;
  margin-right: 20px;
}

.main-menu .navigation > li > .mega-menu .upper-box .page-links-box a i {
  color: #007cba;
  margin-right: 10px;
}
.main-menu .navigation > li > .mega-menu .upper-box .page-links-box a span {
  color: #007cba;
  margin-right: 10px;
}

.theme-btn {
  cursor: pointer;
  display: inline-block;
  transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
}

.centered {
  text-align: center;
}

/* List Style One */

.list-style-one {
  position: relative;
}

.list-style-one li {
  position: relative;
  color: #06092d;
  font-size: 16px;
  padding-left: 65px;
  font-weight: 400;
  line-height: 1.9em;
  margin-bottom: 25px;
}

.list-style-one li:last-child {
  margin-bottom: 0px;
}

.list-style-one li strong {
  position: relative;
  display: block;
  color: #000000;
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 10px;
}

.list-style-one li .icon {
  position: absolute;
  left: 0px;
  top: 5px;
  color: #ffffff;
  font-size: 22px;
  line-height: 34px;
  font-weight: 300;
  -webkit-transition: all 300ms ease;
  -moz-transition: all 300ms ease;
  -ms-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
}

.list-style-one li .icon:after {
  position: absolute;
  content: "";
  left: 0px;
  top: 0px;
  width: 20px;
  height: 38px;
  z-index: -1;
  display: inline-block;
  transform: rotate(30deg);
  background-color: #44bb7f;
}

.list-style-one li:nth-child(2) .icon:after {
  background-color: #fe6b8d;
}

.list-style-one li:nth-child(3) .icon:after {
  background-color: #fbb890;
}

/* List Style Two */

.list-style-two {
  position: relative;
}

.list-style-two li {
  position: relative;
  color: #ffffff;
  font-size: 16px;
  padding-left: 30px;
  font-weight: 400;
  line-height: 1.6em;
  margin-bottom: 20px;
}

.list-style-two li .icon {
  position: absolute;
  left: 0px;
  top: 5px;
  color: #ffffff;
  font-size: 18px;
  line-height: 1em;
  font-weight: 300;
  -webkit-transition: all 300ms ease;
  -moz-transition: all 300ms ease;
  -ms-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
}

/*Btn Style One*/

.btn-style-one {
  position: relative;
  display: inline-block;
  font-size: 16px;
  line-height: 30px;
  color: #ffffff;
  padding: 17px 56px;
  font-weight: 500;
  overflow: hidden;
  border-radius: 3px;
  overflow: hidden;
  background-color: #007cba;
  text-transform: uppercase;
  font-family: "Noto Sans TC", sans-serif;
}

.btn-style-one:before {
  position: absolute;
  content: "";
  left: 110%;
  top: 0px;
  width: 100%;
  height: 100%;
  z-index: 1;
  opacity: 1;
  background-color: #6af0fc;
  -webkit-transition: all 0.4s;
  -moz-transition: all 0.4s;
  -o-transition: all 0.4s;
  transition: all 0.4s;
  transform: skewX(-25deg);
}

.btn-style-one .txt {
  position: relative;
  z-index: 1;
}

.btn-style-one .txt:before {
  position: absolute;
  content: "";
  left: -30px;
  top: 10px;
  width: 21px;
  height: 4px;
  background: url(../images/icons/button-icon-one.png) no-repeat;
}

.btn-style-one .txt:after {
  position: absolute;
  content: "";
  right: -30px;
  top: 10px;
  width: 21px;
  height: 4px;
  background: url(../images/icons/button-icon-two.png) no-repeat;
}

.btn-style-one:hover::before {
  left: 0%;
  -webkit-transform: scale(1, 1);
  transform: scale(1, 1);
}

.btn-style-one:hover {
  color: #222222;
  border-color: #00e49a;
}

/* Btn Style Two */

.btn-style-two {
  position: relative;
  display: inline-block;
  font-size: 16px;
  line-height: 30px;
  color: #ffffff;
  padding: 15px 56px;
  font-weight: 500;
  overflow: hidden;
  border-radius: 3px;
  overflow: hidden;
  background-color: #007cba;
  text-transform: capitalize;
  border: 5px solid #eef4f1;
  font-family: "Noto Sans TC", sans-serif;
}

.btn-style-two:before {
  position: absolute;
  content: "";
  left: 110%;
  top: 0px;
  width: 100%;
  height: 100%;
  z-index: 1;
  opacity: 1;
  background-color: #6af0fc;
  -webkit-transition: all 0.4s;
  -moz-transition: all 0.4s;
  -o-transition: all 0.4s;
  transition: all 0.4s;
  transform: skewX(-25deg);
}

.btn-style-two .txt {
  position: relative;
  z-index: 1;
}

.btn-style-two .txt:before {
  position: absolute;
  content: "";
  left: -30px;
  top: 10px;
  width: 21px;
  height: 4px;
  background: url(../images/icons/button-icon-one.png) no-repeat;
}

.btn-style-two .txt:after {
  position: absolute;
  content: "";
  right: -30px;
  top: 10px;
  width: 21px;
  height: 4px;
  background: url(../images/icons/button-icon-two.png) no-repeat;
}

.btn-style-two:hover::before {
  left: 0%;
  -webkit-transform: scale(1, 1);
  transform: scale(1, 1);
}

.btn-style-two:hover {
  color: #222222;
  border-color: #6af0fc;
}

/* Btn Style Three */

.btn-style-three {
  position: relative;
  display: inline-block;
  font-size: 16px;
  line-height: 30px;
  color: #007cba;
  padding: 12px 40px;
  font-weight: 600;
  overflow: hidden;
  border-radius: 3px;
  overflow: hidden;
  border: 1px solid #007cba;
}

.btn-style-three .fa {
  margin-left: 6px;
}

.btn-style-three:hover {
  color: #ffffff;
  border-color: #007cba;
  background-color: #007cba;
}

/* Btn Style Four */

.btn-style-four {
  position: relative;
  display: inline-block;
  font-size: 16px;
  line-height: 30px;
  color: #007cba;
  padding: 13px 55px;
  font-weight: 500;
  overflow: hidden;
  border-radius: 3px;
  overflow: hidden;
  border: 2px solid #eef4f1;
  background-color: #ffffff;
  text-transform: capitalize;
  font-family: "Noto Sans TC", sans-serif;
}

.btn-style-four:before {
  position: absolute;
  content: "";
  left: 110%;
  top: 0px;
  width: 100%;
  height: 100%;
  z-index: 1;
  opacity: 1;
  background-color: #007cba;
  -webkit-transition: all 0.4s;
  -moz-transition: all 0.4s;
  -o-transition: all 0.4s;
  transition: all 0.4s;
  transform: skewX(-25deg);
}

.btn-style-four .txt {
  position: relative;
  z-index: 1;
}

.btn-style-four .txt:before {
  position: absolute;
  content: "";
  left: -30px;
  top: 10px;
  width: 21px;
  height: 4px;
  background: url(../images/icons/button-icon-three.png) no-repeat;
}

.btn-style-four .txt:after {
  position: absolute;
  content: "";
  right: -30px;
  top: 10px;
  width: 21px;
  height: 4px;
  background: url(../images/icons/button-icon-three.png) no-repeat;
}

.btn-style-four:hover .txt:before,
.btn-style-four:hover .txt:after {
  background: url(../images/icons/button-icon-two.png) no-repeat;
}

.btn-style-four:hover::before {
  left: 0%;
  -webkit-transform: scale(1, 1);
  transform: scale(1, 1);
}

.btn-style-four:hover {
  color: #ffffff;
  border-color: #007cba;
}

/* Btn Style Five */

.btn-style-five {
  position: relative;
  display: inline-block;
  font-size: 16px;
  line-height: 30px;
  color: #000000;
  padding: 15px 40px;
  font-weight: 700;
  overflow: hidden;
  border-radius: 3px;
  overflow: hidden;
  background-color: #6af0fc;
}

.btn-style-five:hover {
  color: #ffffff;
  border-color: #007cba;
  background-color: #007cba;
}

/* Social Icon One */

.social-icon-one {
  position: relative;
  display: block;
}

.social-icon-one .title {
  position: relative;
  font-size: 20px;
  line-height: 26px;
  color: #ffffff;
  font-weight: 700;
  margin-right: 15px;
}

.social-icon-one li {
  position: relative;
  display: inline-block;
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
  margin-right: 22px;
}

.social-icon-one li:last-child {
  margin-right: 0;
}

.social-icon-one li a {
  position: relative;
  display: block;
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
  -webkit-transition: all 300ms ease;
  -moz-transition: all 300ms ease;
  -ms-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
}

.social-icon-one li a:hover {
  color: #007cba;
}

.theme_color {
  color: #007cba;
}

.preloader {
  position: fixed;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  z-index: 999999;
  background-color: #ffffff;
  background-position: center center;
  background-repeat: no-repeat;
  background-image: url(../images/icons/preloader.svg);
  background-size: 140px;
}

img {
  display: inline-block;
  max-width: 100%;
  height: auto;
}

/*** 

====================================================================
	Section Title
====================================================================

***/

.sec-title {
  position: relative;
  margin-bottom: 55px;
}

.sec-title .title {
  position: relative;
  color: #007cba;
  font-size: 16px;
  font-weight: 400;
  text-transform: capitalize;
}

.sec-title h2 {
  position: relative;
  color: #06092d;
  font-weight: 700;
  line-height: 1.2em;
  margin-top: 18px;
}

.sec-title .text {
  position: relative;
  color: #06092d;
  font-weight: 400;
  line-height: 1.7em;
  margin-top: 25px;
  font-size: 20px;
}

.sec-title.light .text,
.sec-title.light .title,
.sec-title.light h2 {
  color: #ffffff;
}

.sec-title.centered {
  text-align: center !important;
}

/*** 

====================================================================
	Main Header style
====================================================================

***/

.main-header {
  position: relative;
  z-index: 99;
  width: 100%;
}

.main-header .main-box {
  position: relative;
  padding: 0px 0px;
  left: 0px;
  top: 0px;
  width: 100%;
  background: none;
  -webkit-transition: all 300ms ease;
  -ms-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  -moz-transition: all 300ms ease;
  transition: all 300ms ease;
}

.main-header .main-box .outer-container {
  position: relative;
  padding: 0px 40px;
}

.main-header .main-box .logo-box {
  position: relative;
  float: left;
  left: 0px;
  z-index: 10;
  padding: 30px 0px;
}

.main-header .main-box .logo-box .logo img {
  display: inline-block;
  max-width: 100%;
  -webkit-transition: all 300ms ease;
  -ms-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  -moz-transition: all 300ms ease;
  transition: all 300ms ease;
}

.main-header .header-upper {
  position: relative;
  background-color: #fbfbfb;
}

.main-header .header-upper .upper-right {
  position: relative;
  padding-top: 22px;
}

.main-header .header-upper .inner-container {
  position: relative;
}

.main-header .header-upper .outer-container {
  position: relative;
  padding: 0px 0px;
}

.main-header .nav-outer {
  position: static;
  float: left;
  padding-left: 0px;
}

.main-header .header-upper .logo-box {
  position: relative;
  padding: 10px 0px;
}

.main-header .header-upper .logo-box .logo {
  position: relative;
}

.main-header .outer-box {
  position: absolute;
  right: 60px;
  margin-left: 20px;
  padding: 20px 0px;
}

/* Header Search Form */

.main-header .header-upper .outer-box .search-box {
  position: relative;
  float: left;
}

.main-header .header-upper .outer-box .search-box .form-group {
  position: relative;
  margin: 0px;
  width: 265px;
  max-width: 100%;
}

.main-header .header-upper .outer-box .form-group input[type="text"],
.main-header .header-upper .outer-box .form-group input[type="search"] {
  position: relative;
  line-height: 43px;
  padding: 10px 15px 10px 45px;
  border: 1px solid #007cba;
  background: #fbfbfb;
  display: block;
  font-size: 14px;
  height: 50px;
  width: 100%;
  color: #000000;
  border-radius: 5px;
  transition: all 500ms ease;
  -moz-transition: all 500ms ease;
  -webkit-transition: all 500ms ease;
  -ms-transition: all 500ms ease;
  -o-transition: all 500ms ease;
}

.main-header .header-upper .outer-box .form-group button {
  position: absolute;
  left: 0px;
  top: 0px;
  height: 50px;
  width: 50px;
  display: block;
  font-size: 16px;
  color: #007cba;
  cursor: pointer;
  line-height: 100%;
  background: none;
  font-weight: normal;
  text-align: center;
  border-radius: 0px 5px 5px 0px;
}

.main-header .header-upper .outer-box .social-box {
  position: relative;
  float: left;
  margin-left: 20px;
  padding: 7px 0px;
}

.main-header .header-upper .outer-box .social-box li {
  position: relative;
  margin-left: 4px;
  display: inline-block;
}

.main-header .header-upper .outer-box .social-box li a {
  position: relative;
  width: 36px;
  height: 36px;
  color: #ffffff;
  line-height: 36px;
  text-align: center;
  border-radius: 50%;
  display: inline-block;
  background-color: #007cba;
}

.main-header .header-upper .outer-box .social-box li a::before {
  -webkit-transition: all 600ms ease;
  -ms-transition: all 600ms ease;
  -o-transition: all 600ms ease;
  -moz-transition: all 600ms ease;
  transition: all 600ms ease;
  display: inline-block;
}

.main-header .header-upper .outer-box .social-box li a:hover::before {
  transform: rotateY(360deg);
}

.main-header .header-upper .outer-box .social-box li.instagram a {
  background-color: #e4405f;
}

.main-header .header-upper .outer-box .social-box li.facebook a {
  background-color: #3b5999;
}

.main-header .header-upper .outer-box .social-box li.twitter a {
  background-color: #55acee;
}

.main-header .header-upper .outer-box .social-box li.pinterest a {
  background-color: #bd081c;
}

/* Outer Box */

.main-header .cart-box {
  position: relative;
  float: left;
  color: #007cba;
  font-size: 16px;
  background: none;
  margin-top: 14px;
  display: inline-block;
  margin-right: 24px;
  padding-left: 25px;
  border-left: 1px solid #d3d4df;
}

.main-header .cart-box .cart-box-btn {
  background: none;
  color: #007cba;
  cursor: pointer;
  font-size: 24px;
  transition: all 300ms ease;
  -webkit-transition: all 300ms ease;
  -ms-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  -moz-transition: all 300ms ease;
}

.main-header .cart-box .cart-box-btn:after {
  display: none;
}

.main-header .cart-box .cart-box-btn .total-cart {
  position: absolute;
  right: -8px;
  top: -8px;
  width: 18px;
  height: 18px;
  color: #ffffff;
  font-weight: 700;
  font-size: 10px;
  border-radius: 50%;
  text-align: center;
  line-height: 18px;
  background-color: #222222;
}

.main-header .cart-box .cart-box-btn:hover {
}

.main-header .cart-product {
  position: relative;
  margin-bottom: 18px;
  min-height: 90px;
  border-bottom: 1px solid #e4e4e4;
}

.main-header .cart-product .inner {
  position: relative;
  padding-left: 90px;
  min-height: 70px;
}

.main-header .cart-product .inner .cross-icon {
  position: absolute;
  right: 0px;
  top: -2px;
  z-index: 1;
  color: #263120;
  font-size: 16px;
  cursor: pointer;
  transition: all 500ms ease;
  -moz-transition: all 500ms ease;
  -webkit-transition: all 500ms ease;
  -ms-transition: all 500ms ease;
  -o-transition: all 500ms ease;
}

.main-header .cart-product .inner .cross-icon:hover {
  color: #007cba;
}

.main-header .cart-product .inner .image {
  position: absolute;
  width: 70px;
  height: 70px;
  left: 0px;
  top: 0px;
}

.main-header .cart-product .inner h3 {
  position: relative;
  font-weight: 600;
  font-size: 15px;
  margin: 0px;
  letter-spacing: 1px;
  text-transform: capitalize;
}

.main-header .cart-product .inner h3 a {
  color: #222222;
  transition: all 300ms ease;
  -webkit-transition: all 300ms ease;
  -ms-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  -moz-transition: all 300ms ease;
}

.main-header .cart-product .inner h3 a:hover {
  color: #007cba;
}

.main-header .cart-product .inner .quantity-text {
  position: relative;
  font-weight: 300;
  color: #666666;
  font-size: 13px;
  margin-bottom: 0px;
}

.main-header .cart-product .inner .price {
  position: relative;
  font-weight: 700;
  font-size: 16px;
  margin: 0px;
  color: #222222;
}

.main-header .cart-box .cart-panel {
  left: auto !important;
  right: 0px !important;
  border-radius: 20px;
  padding: 20px 20px;
  min-width: 300px;
  margin-top: 62px;
  z-index: -1;
  box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.15);
  border: 3px solid #007cba;
  -webkit-transform: translate3d(0px, 0px, 0px) !important;
  transform: translate3d(0px, 0px, 0px) !important;
}

.main-header .cart-box .cart-panel .cart-total {
  color: #000000;
  font-size: 15px;
  font-weight: 400;
  text-align: center;
  text-transform: capitalize;
}

.main-header .cart-box .cart-panel .cart-total span {
  font-weight: 800;
  font-size: 18px;
}

.main-header .cart-box .cart-panel .btns-boxed {
  position: relative;
  text-align: center;
  margin-top: 20px;
}

.main-header .cart-box .cart-panel .btns-boxed li {
  position: relative;
  margin: 0px 5px;
  display: inline-block;
}

.main-header .cart-box .cart-panel .btns-boxed li a {
  position: relative;
  color: #ffffff;
  font-weight: 500;
  font-size: 12px;
  padding: 10px 20px 8px;
  border-radius: 2px;
  display: inline-block;
  background-color: #007cba;
  border: 1px solid #007cba;
  text-transform: uppercase;
  transition: all 300ms ease;
  -webkit-transition: all 300ms ease;
  -ms-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  -moz-transition: all 300ms ease;
}

.main-header .cart-box .cart-panel .btns-boxed li a:hover {
  color: #007cba;
  background: none;
}

.main-header .cart-box .icon a {
  color: #999999;
}

.main-header .btn-box {
  position: relative;
  float: left;
  margin-left: 30px;
}

/* Header Lower */

.main-header .header-lower .search-box {
  float: right;
}

.main-header .header-lower .search-box .form-group {
  position: relative;
  margin: 0px;
  top: -1px;
}

.main-header .header-lower .search-box .form-group input[type="text"],
.main-header .header-lower .search-box .form-group input[type="search"] {
  position: relative;
  line-height: 28px;
  padding: 10px 50px 10px 25px;
  background: none;
  display: block;
  font-size: 14px;
  width: 200px;
  height: 74px;
  color: #222222;
  font-weight: 400;
  transition: all 500ms ease;
  -moz-transition: all 500ms ease;
  -webkit-transition: all 500ms ease;
  -ms-transition: all 500ms ease;
  -o-transition: all 500ms ease;
  background-color: #ffffff;
  border-radius: 0px 50px 50px 0px;
}

.main-header .header-lower .search-box .form-group button {
  position: absolute;
  right: 0px;
  top: 0px;
  height: 74px;
  width: 45px;
  font-size: 14px;
  color: #000048;
  line-height: 100%;
  background: none;
  display: inline-block;
  font-weight: normal;
  text-align: left;
  cursor: pointer;
}

/* Main Menu */

.main-menu {
  position: static;
  float: left;
  padding: 7px 0px;
  -webkit-transition: all 300ms ease;
  -ms-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  -moz-transition: all 300ms ease;
  transition: all 300ms ease;
}

.main-menu .navbar-collapse {
  padding: 0px;
  float: left;
  display: block !important;
}

.main-menu .navigation {
  position: static;
  margin: 0px;
}

.main-menu .navigation > li {
  position: relative;
  float: left;
  margin-right: 25px;
  transition: all 500ms ease;
  -moz-transition: all 500ms ease;
  -webkit-transition: all 500ms ease;
  -ms-transition: all 500ms ease;
  -o-transition: all 500ms ease;
}

.main-menu .navigation > li:last-child {
  margin-right: 0px;
}

.sticky-header .main-menu .navigation > li > a {
  color: #444444 !important;
}

.sticky-header .main-menu .navigation > li:hover > a,
.sticky-header .main-menu .navigation > li.current > a {
  color: #007cba;
  background-color: inherit !important;
}

.sticky-header .nav-outer .options-box {
  margin-top: 45px;
}

.header-style-two .main-menu {
  padding-left: 150px;
}

.header-style-two .main-menu .navigation > li.has-mega-menu {
  float: left !important;
}

.header-style-two .main-menu .navigation > li.has-mega-menu > a {
  background: none;
  color: #007cba;
}

.header-style-two .main-menu .navigation > li.has-mega-menu > a:before {
  display: none;
}

.header-style-two .main-menu .navigation > li.has-mega-menu > a span {
  border-bottom: 1px solid #007cba;
}

/************************ Mega Menu ***************************/

.main-menu .navigation > li.has-mega-menu {
  position: static;
}

.main-menu .navigation > li.has-mega-menu > a {
  color: #ffffff;
  padding-left: 25px;
  padding-right: 25px;
  text-transform: uppercase;
  background-color: #007cba;
}

.main-menu .navigation > li.has-mega-menu > a:before {
  position: absolute;
  content: "";
  left: 0px;
  top: 0px;
  right: 0px;
  bottom: 0px;
  /* background:url(../images/icons/menu-bg.png) no-repeat; */
  background-position: center center;
}

.main-menu .navigation > li.has-mega-menu > a span {
  position: relative;
  padding-bottom: 6px;
}

.main-menu .navigation > li.has-mega-menu > a span > i {
  margin-left: 4px;
  font-size: 14px;
}

.main-menu .navigation > li.has-mega-menu > a span:before {
  position: absolute;
  content: "";
  left: 0px;
  bottom: 0px;
  height: 2px;
  right: 22px;
  opacity: 0.3;
  background-color: #ffffff;
  transition: all 500ms ease;
  -moz-transition: all 500ms ease;
  -webkit-transition: all 500ms ease;
  -ms-transition: all 500ms ease;
  -o-transition: all 500ms ease;
}

.main-menu .navigation > li > .mega-menu {
  position: absolute;
  left: 0px;
  right: 0;
  margin: auto;
  width: 100%;
  max-width: 1170px;
  background-color: #ffffff;
  padding: 45px 40px 30px 45px;
  top: 100%;
  z-index: 100;
  opacity: 0;
  -webkit-transform: scaleY(0);
  -moz-transform: scaleY(0);
  -ms-transform: scaleY(0);
  -o-transform: scaleY(0);
  transform: scaleY(0);
  -webkit-transform-origin: top;
  -moz-transform-origin: top;
  -ms-transform-origin: top;
  -o-transform-origin: top;
  transform-origin: top;
  transition: all 500ms ease;
  -moz-transition: all 500ms ease;
  -webkit-transition: all 500ms ease;
  -ms-transition: all 500ms ease;
  -o-transition: all 500ms ease;
  -webkit-box-shadow: 2px 2px 5px 1px rgba(0, 0, 0, 0.05),
    -2px 0px 5px 1px rgba(0, 0, 0, 0.05);
  -ms-box-shadow: 2px 2px 5px 1px rgba(0, 0, 0, 0.05),
    -2px 0px 5px 1px rgba(0, 0, 0, 0.05);
  -o-box-shadow: 2px 2px 5px 1px rgba(0, 0, 0, 0.05),
    -2px 0px 5px 1px rgba(0, 0, 0, 0.05);
  -moz-box-shadow: 2px 2px 5px 1px rgba(0, 0, 0, 0.05),
    -2px 0px 5px 1px rgba(0, 0, 0, 0.05);
  box-shadow: 2px 2px 5px 1px rgba(0, 0, 0, 0.05),
    -2px 0px 5px 1px rgba(0, 0, 0, 0.05);
}

.main-menu .navigation > li:hover > .mega-menu {
  opacity: 1;
  visibility: visible;
  -webkit-transform: scaleY(1);
  -moz-transform: scaleY(1);
  -ms-transform: scaleY(1);
  -o-transform: scaleY(1);
  transform: scaleY(1);
}

.main-menu .navigation > li > .mega-menu .mega-menu-bar {
  position: relative;
  padding: 35px 35px;
  box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.15);
}

.main-menu .navigation > li > .mega-menu .upper-box .page-links-box {
  text-align: left;
}

.main-menu .navigation > li > .mega-menu .upper-box .page-links-box a {
  margin-right: 12px;
  margin-left: 0px;
  margin-bottom: 18px;
}

.main-menu .navigation > li > .mega-menu .lower-box {
  position: relative;
  max-width: 870px;
  width: 100%;
  padding-top: 30px;
  text-align: left;
  border-top: 1px solid #e7e7e7;
}

.main-menu .navigation > li > .mega-menu .lower-box h3 {
  position: relative;
  color: #000000;
  font-weight: 700;
  line-height: 1.3em;
  margin-bottom: 18px;
}

.main-menu .navigation > li > .mega-menu .lower-box .text {
  position: relative;
  color: #06092d;
  font-size: 16px;
  line-height: 1.8em;
}

.main-menu .navigation > li > .mega-menu .lower-box .btn-box {
  position: absolute;
  right: 0px;
  top: 65px;
}

.main-menu .navigation > li > .mega-menu .lower-box .side-icon {
  position: absolute;
  right: -240px;
  bottom: -30px;
}

/* Sticky Header */

.sticky-header {
  position: fixed;
  opacity: 0;
  visibility: hidden;
  left: 0px;
  top: 0px;
  width: 100%;
  padding: 0px 0px;
  background: #ffffff;
  z-index: 0;
  border-bottom: 1px solid #e8f1f7;
  transition: all 500ms ease;
  -moz-transition: all 500ms ease;
  -webkit-transition: all 500ms ease;
  -ms-transition: all 500ms ease;
  -o-transition: all 500ms ease;
}

.sticky-header .logo {
  padding: 6px 0px 6px;
}

.fixed-header .sticky-header {
  z-index: 999;
  opacity: 1;
  visibility: visible;
  -ms-animation-name: fadeInDown;
  -moz-animation-name: fadeInDown;
  -op-animation-name: fadeInDown;
  -webkit-animation-name: fadeInDown;
  animation-name: fadeInDown;
  -ms-animation-duration: 500ms;
  -moz-animation-duration: 500ms;
  -op-animation-duration: 500ms;
  -webkit-animation-duration: 500ms;
  animation-duration: 500ms;
  -ms-animation-timing-function: linear;
  -moz-animation-timing-function: linear;
  -op-animation-timing-function: linear;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  -ms-animation-iteration-count: 1;
  -moz-animation-iteration-count: 1;
  -op-animation-iteration-count: 1;
  -webkit-animation-iteration-count: 1;
  animation-iteration-count: 1;
}

.main-menu .navigation > li > a {
  position: relative;
  display: block;
  color: #06142d;
  text-align: center;
  line-height: 30px;
  text-transform: capitalize;
  letter-spacing: 0px;
  opacity: 1;
  font-weight: 500;
  padding: 22px 0px;
  font-size: 16px;
  transition: all 500ms ease;
  -moz-transition: all 500ms ease;
  -webkit-transition: all 500ms ease;
  -ms-transition: all 500ms ease;
  -o-transition: all 500ms ease;
}

.sticky-header .main-menu .navigation > li {
  margin-left: 30px;
  margin-right: 0px;
}

.sticky-header .main-menu .navigation > li:before,
.sticky-header .main-menu .navigation > li:after {
}

.main-menu .navigation > li:hover > a,
.main-menu .navigation > li.current > a {
  opacity: 1;
}

.main-menu .navigation > li > ul {
  position: absolute;
  left: -30px;
  top: 100%;
  width: 230px;
  z-index: 100;
  display: none;
  opacity: 0;
  visibility: hidden;
  transition: all 900ms ease;
  -moz-transition: all 900ms ease;
  -webkit-transition: all 900ms ease;
  -ms-transition: all 900ms ease;
  -o-transition: all 900ms ease;
  padding: 20px 15px;
  border-radius: 10px;
  background-color: #ffffff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}

.main-menu .navigation > li > ul.from-right {
  left: auto;
  right: 0px;
}

.main-menu .navigation > li > ul > li {
  position: relative;
  width: 100%;
  border-bottom: 1px solid #f9f9f9;
}

.main-menu .navigation > li > ul > li:last-child {
  border-bottom: none;
}

.main-menu .navigation > li > ul > li > a {
  position: relative;
  display: block;
  padding: 10px 18px;
  line-height: 24px;
  font-weight: 600;
  font-size: 15px;
  text-align: left;
  text-transform: capitalize;
  color: #7c7b7b;
  transition: all 500ms ease;
  -moz-transition: all 500ms ease;
  -webkit-transition: all 500ms ease;
  -ms-transition: all 500ms ease;
  -o-transition: all 500ms ease;
}

.sticky-header .main-menu .navigation > li > a {
  padding: 19px 0px;
  background: none;
}

.main-menu .navigation > li > ul > li > a:before {
  position: absolute;
  content: "//";
  left: 10px;
  top: 8px;
  opacity: 0;
  color: #007cba;
  transition: all 500ms ease;
  -moz-transition: all 500ms ease;
  -webkit-transition: all 500ms ease;
  -ms-transition: all 500ms ease;
  -o-transition: all 500ms ease;
}

.main-menu .navigation > li > ul > li:hover > a::before {
  opacity: 1;
}

.sticky-header .main-menu .navigation > li > a:before {
  display: none;
}

.main-menu .navigation > li > ul > li:hover > a {
  color: #007cba;
  padding-left: 30px;
}

.main-menu .navigation > li > ul > li.dropdown > a:after {
  font-family: "FontAwesome";
  content: "\f105";
  position: absolute;
  right: 15px;
  top: 13px;
  width: 10px;
  height: 20px;
  display: block;
  color: #253d4a;
  line-height: 20px;
  font-size: 16px;
  font-weight: normal;
  text-align: center;
  z-index: 5;
}

.main-menu .navigation > li > ul > li.dropdown:hover > a:after {
  color: #222222;
}

.main-menu .navigation > li > ul > li > ul {
  position: absolute;
  left: 120%;
  top: 0%;
  width: 230px;
  z-index: 100;
  display: none;
  transition: all 900ms ease;
  -moz-transition: all 900ms ease;
  -webkit-transition: all 900ms ease;
  -ms-transition: all 900ms ease;
  -o-transition: all 900ms ease;
  padding: 20px 15px;
  border-radius: 10px;
  background-color: #ffffff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}

.main-menu .navigation > li > ul > li > ul.from-right {
  left: auto;
  right: 0px;
}

.main-menu .navigation > li > ul > li > ul > li {
  position: relative;
  width: 100%;
  border-bottom: 1px solid #f9f9f9;
}

.main-menu .navigation > li > ul > li > ul > li:last-child {
  border-bottom: none;
}

.main-menu .navigation > li > ul > li > ul > li > a {
  position: relative;
  display: block;
  padding: 6px 18px;
  line-height: 24px;
  font-weight: 600;
  font-size: 15px;
  color: #7c7b7b;
  padding-left: 10px;
  text-align: left;
  text-transform: capitalize;
  transition: all 500ms ease;
  -moz-transition: all 500ms ease;
  -webkit-transition: all 500ms ease;
  -ms-transition: all 500ms ease;
  -o-transition: all 500ms ease;
}

.main-menu .navigation > li > ul > li > ul > li:hover > a {
  color: #007cba;
}

.main-menu .navigation > li > ul > li > ul > li > a:before {
  position: absolute;
  content: "//";
  left: 10px;
  top: 8px;
  opacity: 0;
  color: #007cba;
  transition: all 500ms ease;
  -moz-transition: all 500ms ease;
  -webkit-transition: all 500ms ease;
  -ms-transition: all 500ms ease;
  -o-transition: all 500ms ease;
}

.main-menu .navigation > li > ul > li > ul > li:hover > a::before {
  opacity: 1;
}

.main-menu .navigation > li > ul > li > ul > li:hover > a {
  color: #007cba;
  padding-left: 30px;
}

.main-menu .navigation > li.dropdown:hover > ul {
  visibility: visible;
  opacity: 1;
  left: 0px;
  -moz-transform: rotateX(0deg);
  -webkit-transform: rotateX(0deg);
  -ms-transform: rotateX(0deg);
  -o-transform: rotateX(0deg);
  transform: rotateX(0deg);
}

.main-menu .navigation li > ul > li.dropdown:hover > ul {
  visibility: visible;
  opacity: 1;
  top: 0;
  left: 100%;
  -moz-transform: rotateX(0deg);
  -webkit-transform: rotateX(0deg);
  -ms-transform: rotateX(0deg);
  -o-transform: rotateX(0deg);
  transform: rotateX(0deg);
  transition: all 300ms ease;
  -moz-transition: all 300ms ease;
  -webkit-transition: all 500ms ease;
  -ms-transition: all 300ms ease;
  -o-transition: all 300ms ease;
}

.main-menu .navbar-collapse > ul li.dropdown .dropdown-btn {
  position: absolute;
  right: 10px;
  top: 6px;
  width: 30px;
  height: 30px;
  text-align: center;
  color: #ffffff;
  line-height: 28px;
  border: 1px solid #ffffff;
  background-size: 20px;
  cursor: pointer;
  z-index: 5;
  display: none;
}

.main-header .header-lower .options-box {
  position: relative;
  float: right;
  padding: 10px 15px 10px;
}

.main-header .header-lower .options-box .option-inner:before {
  position: absolute;
  content: "";
  left: -10px;
  top: -10px;
  bottom: -10px;
  width: 110%;
  background-color: #007cba;
  border-radius: 50px 50px 50px 50px;
}

.main-header .header-lower .options-box .option-inner {
  position: relative;
  padding-left: 70px;
}

.main-header .header-lower .options-box .option-inner .icon {
  position: absolute;
  left: 0px;
  top: 0px;
  color: #ffffff;
  font-size: 50px;
  line-height: 1em;
}

.main-header .header-lower .options-box .option-inner .number {
  position: relative;
}

.main-header .header-lower .options-box .option-inner .number span {
  position: relative;
  display: block;
  color: #ffffff;
  margin-bottom: 3px;
  text-transform: uppercase;
}

.main-header .header-lower .options-box .option-inner .number a {
  position: relative;
  font-weight: 800;
  font-size: 22px;
  color: #ffffff;
}

.header-style-two .header-upper .logo-box {
  padding: 17.5px 0px;
  padding-left: 12px;
  background-color: #007cba;
}

.header-style-two .main-menu,
.header-style-two .main-menu .navbar-collapse {
  width: 100%;
  text-align: center;
}

.header-style-two .nav-outer {
  width: 100%;
  margin-top: -90px;
  text-align: center;
}

.header-style-two .main-menu .navigation > li {
  float: none;
  display: inline-block;
}

.header-style-two .header-upper .outer-box .form-group input[type="search"] {
  border: none;
}

.header-style-two .header-upper .outer-box .search-box .form-group {
  width: 200px;
}

.header-style-two .header-upper .outer-box .nav-btn {
  position: relative;
  width: 42px;
  height: 42px;
  float: left;
  font-size: 18px;
  color: #ffffff;
  line-height: 42px;
  cursor: pointer;
  margin-top: 4px;
  margin-left: 5px;
  border-radius: 50px;
  background-color: #007cba;
}

/*** 

====================================================================
	Hidden Sidebar style
====================================================================

***/

.sidebar-info-contents {
  position: relative;
}

.sidebar-info-contents .content-inner {
  position: relative;
}

.sidebar-info-contents .content-inner .logo {
  padding: 0px 0px 40px;
}

.sidebar-info-contents .content-inner .logo img {
  display: inline-block;
  max-width: 100%;
}

.sidebar-info-contents .content-inner .content-box {
  position: relative;
}

.sidebar-info-contents .content-inner .content-box h2 {
  position: relative;
  font-size: 20px;
  color: #ffffff;
  font-weight: 500;
  margin-bottom: 20px;
}

.sidebar-info-contents .content-inner .content-box .text {
  position: relative;
  font-size: 14px;
  color: rgba(255, 255, 255, 0.9);
  margin-bottom: 25px;
}

.sidebar-info-contents .content-inner .contact-info {
  position: relative;
  margin-top: 60px;
}

.sidebar-info-contents .content-inner .contact-info h2 {
  position: relative;
  font-size: 20px;
  color: #ffffff;
  font-weight: 500;
  margin-bottom: 20px;
}

.sidebar-info-contents .content-inner .social-box {
  position: relative;
  margin-top: 20px;
  margin-bottom: 30px;
}

.sidebar-info-contents .content-inner .social-box li {
  position: relative;
  display: inline-block;
  margin-right: 6px;
  transition: all 900ms ease;
  -moz-transition: all 900ms ease;
  -webkit-transition: all 900ms ease;
  -ms-transition: all 900ms ease;
  -o-transition: all 900ms ease;
}

.sidebar-info-contents .content-inner .social-box li a {
  position: relative;
  width: 36px;
  height: 36px;
  color: #222222;
  z-index: 1;
  font-size: 13px;
  line-height: 36px;
  text-align: center;
  border-radius: 50%;
  display: inline-block;
  background-color: #ffffff;
}

.close-side-widget,
.close-side-widget:hover {
  color: #ffffff;
  font-size: 24px;
}

/*** 

====================================================================
	Mobile Menu
====================================================================

***/

.nav-outer .mobile-nav-toggler {
  position: relative;
  float: right;
  font-size: 36px;
  line-height: 50px;
  cursor: pointer;
  color: #000000;
  display: none;
}

.mobile-menu {
  position: fixed;
  right: 0;
  top: 0;
  width: 300px;
  padding-right: 30px;
  max-width: 100%;
  height: 100%;
  opacity: 0;
  visibility: hidden;
  z-index: 999999;
}

.mobile-menu .nav-logo {
  position: relative;
  padding: 20px 20px;
  text-align: left;
}

.mobile-menu .nav-logo img {
  max-width: 200px;
}

.mobile-menu-visible {
  overflow: hidden;
}

.mobile-menu-visible .mobile-menu {
  opacity: 1;
  visibility: visible;
}

.mobile-menu .menu-backdrop {
  position: fixed;
  right: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  background: rgba(0, 0, 0, 0.9);
  -webkit-transform: translateX(101%);
  -ms-transform: translateX(101%);
  transform: translateX(101%);
}

.mobile-menu-visible .mobile-menu .menu-backdrop {
  opacity: 1;
  visibility: visible;
  -webkit-transition: all 0.7s ease;
  -moz-transition: all 0.7s ease;
  -ms-transition: all 0.7s ease;
  -o-transition: all 0.7s ease;
  transition: all 0.7s ease;
  -webkit-transform: translateX(0%);
  -ms-transform: translateX(0%);
  transform: translateX(0%);
}

.mobile-menu .menu-box {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  max-height: 100%;
  overflow-y: auto;
  background: #ffffff;
  padding: 0px 0px;
  z-index: 5;
  opacity: 0;
  visibility: hidden;
  border-radius: 0px;
  -webkit-transform: translateX(101%);
  -ms-transform: translateX(101%);
  transform: translateX(101%);
}

.mobile-menu-visible .mobile-menu .menu-box {
  opacity: 1;
  visibility: visible;
  -webkit-transition: all 0.7s ease 500ms;
  -moz-transition: all 0.7s ease 500ms;
  -ms-transition: all 0.7s ease 500ms;
  -o-transition: all 0.7s ease 500ms;
  transition: all 0.7s ease 500ms;
  -webkit-transform: translateX(0%);
  -ms-transform: translateX(0%);
  transform: translateX(0%);
}

.mobile-menu .close-btn {
  position: absolute;
  right: 15px;
  top: 15px;
  line-height: 30px;
  width: 30px;
  text-align: center;
  font-size: 14px;
  color: #202020;
  cursor: pointer;
  z-index: 10;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
  -webkit-transform: translateY(-50px);
  -ms-transform: translateY(-50px);
  transform: translateY(-50px);
}

.mobile-menu-visible .mobile-menu .close-btn {
  -webkit-transform: translateY(0px);
  -ms-transform: translateY(0px);
  transform: translateY(0px);
}

.mobile-menu .close-btn:hover {
  opacity: 0.5;
}

.mobile-menu .navigation {
  position: relative;
  display: block;
  width: 100%;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.mobile-menu .navigation li {
  position: relative;
  display: block;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.mobile-menu .navigation li > ul > li:last-child {
  border-bottom: none;
}

.mobile-menu .navigation li > ul > li:first-child {
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.mobile-menu .navigation li > a {
  position: relative;
  display: block;
  line-height: 24px;
  padding: 10px 20px;
  font-size: 15px;
  color: #404040;
  text-transform: capitalize;
}

.mobile-menu .navigation li:hover > a,
.mobile-menu .navigation li.current > a {
  color: #000000;
}

.mobile-menu .navigation li.dropdown .dropdown-btn {
  position: absolute;
  right: 0px;
  top: 0px;
  width: 44px;
  height: 44px;
  text-align: center;
  font-size: 16px;
  line-height: 44px;
  color: #404040;
  cursor: pointer;
  z-index: 5;
}

.mobile-menu .navigation li.dropdown .dropdown-btn:after {
  content: "";
  position: absolute;
  left: 0px;
  top: 10px;
  width: 1px;
  height: 24px;
  border-left: 1px solid rgba(0, 0, 0, 0.1);
}

.mobile-menu .navigation li > ul,
.mobile-menu .navigation li > ul > li > ul {
  display: none;
}

.sticky-header .navbar-header {
  display: none;
}

.main-header .sticky-header .outer-box {
  margin-left: 20px;
  padding: 13px 0px;
}

.main-header .sticky-header .outer-box .cart-box {
  color: #000000;
  margin-top: 11px;
}

.main-header .sticky-header .outer-box .cart-box .cart-box-btn,
.main-header .sticky-header .outer-box .cart-box .cart-box-btn:hover {
  color: #000000;
}

.main-header .sticky-header .outer-box .cart-box .cart-box-btn .total-cart {
  background-color: #007cba;
}

/*** 

====================================================================
	Scroll To Top style
====================================================================

***/

.scroll-to-top {
  position: fixed;
  bottom: 15px;
  right: 15px;
  width: 40px;
  height: 40px;
  color: #ffffff;
  font-size: 13px;
  text-transform: uppercase;
  line-height: 38px;
  text-align: center;
  z-index: 100;
  cursor: pointer;
  background: #222222;
  display: none;
  -webkit-transition: all 300ms ease;
  -ms-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  -moz-transition: all 300ms ease;
  transition: all 300ms ease;
}

.scroll-to-top:hover {
  color: #ffffff;
  background: #000000;
}

/*** 

====================================================================
	Banner Section
====================================================================

***/

.banner-section {
  position: relative;
  overflow: hidden;
  padding-top: 50px;
  padding-bottom: 100px;
}

.banner-section .image-column .pattern-layer-one {
  position: absolute;
  left: -40px;
  top: -20px;
  width: 467px;
  height: 327px;
  z-index: 2;
  background-position: center bottom;
  background-repeat: no-repeat;
  background-size: cover;
  transition: all 900ms ease;
  -moz-transition: all 900ms ease;
  -webkit-transition: all 900ms ease;
  -ms-transition: all 900ms ease;
  -o-transition: all 900ms ease;
}

.banner-section .image-column .pattern-layer-two {
  position: absolute;
  left: -40px;
  top: 320px;
  width: 86px;
  height: 84px;
  z-index: 2;
  transition: all 900ms ease;
  -moz-transition: all 900ms ease;
  -webkit-transition: all 900ms ease;
  -ms-transition: all 900ms ease;
  -o-transition: all 900ms ease;
}

.banner-section .image-column .pattern-layer-three {
  position: absolute;
  left: 140px;
  top: 360px;
  width: 195px;
  height: 109px;
  z-index: 1;
  transition: all 900ms ease;
  -moz-transition: all 900ms ease;
  -webkit-transition: all 900ms ease;
  -ms-transition: all 900ms ease;
  -o-transition: all 900ms ease;
}

.banner-section .image-column .pattern-layer-four {
  position: absolute;
  right: 100px;
  top: 380px;
  width: 105px;
  height: 104px;
  transition: all 900ms ease;
  -moz-transition: all 900ms ease;
  -webkit-transition: all 900ms ease;
  -ms-transition: all 900ms ease;
  -o-transition: all 900ms ease;
}

.banner-section .image-column .pattern-layer-five {
  position: absolute;
  right: 120px;
  top: 320px;
  width: 20px;
  height: 20px;
  z-index: 1;
  transition: all 900ms ease;
  -moz-transition: all 900ms ease;
  -webkit-transition: all 900ms ease;
  -ms-transition: all 900ms ease;
  -o-transition: all 900ms ease;
}

.banner-section .pattern-layer-six {
  position: absolute;
  right: 0px;
  top: 90px;
  width: 284px;
  height: 278px;
  z-index: 2;
  background-position: center bottom;
  background-repeat: no-repeat;
  background-size: cover;
  transition: all 900ms ease;
  -moz-transition: all 900ms ease;
  -webkit-transition: all 900ms ease;
  -ms-transition: all 900ms ease;
  -o-transition: all 900ms ease;
}

.banner-section .pattern-layer-seven {
  position: absolute;
  right: 0px;
  bottom: 190px;
  width: 733px;
  height: 171px;
  z-index: 2;
  background-position: center bottom;
  background-repeat: no-repeat;
  background-size: cover;
  transition: all 900ms ease;
  -moz-transition: all 900ms ease;
  -webkit-transition: all 900ms ease;
  -ms-transition: all 900ms ease;
  -o-transition: all 900ms ease;
}

.banner-section .pattern-layer-eight {
  position: absolute;
  right: 350px;
  top: 230px;
  width: 20px;
  height: 20px;
  z-index: 2;
  background-position: center bottom;
  background-repeat: no-repeat;
  background-size: cover;
  transition: all 900ms ease;
  -moz-transition: all 900ms ease;
  -webkit-transition: all 900ms ease;
  -ms-transition: all 900ms ease;
  -o-transition: all 900ms ease;
}

.banner-section .image-column .image-two {
  position: absolute;
  left: -10px;
  bottom: -80px;
  z-index: 2;
  transition: all 900ms ease;
  -moz-transition: all 900ms ease;
  -webkit-transition: all 900ms ease;
  -ms-transition: all 900ms ease;
  -o-transition: all 900ms ease;
}

.banner-section .image-column {
  position: relative;
  z-index: 10;
}

.banner-section .image-column .inner-column {
  position: relative;
  padding-right: 100px;
  margin-left: -100px;
}

.banner-section .image-column .image-content {
  position: absolute;
  right: 130px;
  bottom: -60px;
  width: 238px;
  height: 224px;
  padding-top: 90px;
  background-repeat: no-repeat;
}

.banner-section .image-column .image-content p {
  position: relative;
  color: #ffffff;
  text-align: center;
  line-height: 1.7em;
  font-size: 24px;
}

.banner-section .content-column {
  position: relative;
  z-index: 10;
}

.banner-section .content-column .inner-column {
  position: relative;
  margin-left: -60px;
  padding-top: 120px;
}

.banner-section .content-column .title {
  position: relative;
  color: #06092d;
  font-size: 16px;
  line-height: 1.8em;
  margin-bottom: 25px;
  font-weight: 500;
  letter-spacing: 4px;
  text-transform: uppercase;
  -webkit-transition: all 1000ms ease;
  -moz-transition: all 100ms ease;
  -ms-transition: all 1000ms ease;
  -o-transition: all 1000ms ease;
  transition: all 1000ms ease;
}

.banner-section .content-column h1 {
  position: relative;
  color: #06092d;
  font-weight: 900;
  line-height: 1.1em;
  -webkit-transition: all 1000ms ease;
  -moz-transition: all 100ms ease;
  -ms-transition: all 1000ms ease;
  -o-transition: all 1000ms ease;
  transition: all 1000ms ease;
}

.banner-section .content-column .btns-box {
  margin-top: 45px;
  -webkit-transition: all 1000ms ease;
  -moz-transition: all 100ms ease;
  -ms-transition: all 1000ms ease;
  -o-transition: all 1000ms ease;
  transition: all 1000ms ease;
}

.banner-section .content-column .theme-btn {
  margin-right: 15px;
}

.banner-section .owl-dots {
  position: absolute;
  left: 0px;
  right: 0px;
  bottom: 40px;
  display: none;
  text-align: center;
}

.banner-section .owl-dots .owl-dot {
  position: relative;
  width: 30px;
  height: 6px;
  margin-bottom: 10px;
  border-radius: 0px;
  margin: 0px 5px;
  display: inline-block;
  background-color: #007cba;
  transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
}

.banner-section .owl-dots .owl-dot.active,
.banner-section .owl-dots .owl-dot:hover {
  background-color: #06092d;
}

.banner-section .owl-nav {
  position: absolute;
  left: 0px;
  top: 50%;
  z-index: 99999;
  width: 100%;
  opacity: 0;
  margin-top: -10px;
  transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
}

.banner-section:hover .owl-nav {
  opacity: 1;
}

.banner-section .owl-nav .owl-prev {
  position: absolute;
  left: 20px;
  width: 60px;
  height: 60px;
  color: #007cba;
  line-height: 56px;
  font-size: 24px;
  text-align: center;
  border-radius: 50px;
  transition: all 500ms ease;
  -moz-transition: all 500ms ease;
  -webkit-transition: all 500ms ease;
  -ms-transition: all 500ms ease;
  -o-transition: all 500ms ease;
  border: 2px solid #007cba;
}

.banner-section .owl-nav .owl-next {
  position: absolute;
  right: 20px;
  color: #007cba;
  font-size: 24px;
  width: 60px;
  height: 60px;
  line-height: 56px;
  border-radius: 50px;
  text-align: center;
  transition: all 500ms ease;
  -moz-transition: all 500ms ease;
  -webkit-transition: all 500ms ease;
  -ms-transition: all 500ms ease;
  -o-transition: all 500ms ease;
  border: 2px solid #007cba;
}

.banner-section .owl-nav .owl-prev:hover,
.banner-section .owl-nav .owl-next:hover {
  color: #ffffff;
  border-color: #007cba;
  background-color: #007cba;
}

/*** 

====================================================================
	Instructor Section
====================================================================

***/

.instructor-section {
  position: relative;
}

.instructor-section .background-layer {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  background-position: center top;
  background-repeat: repeat-x;
}

.instructor-section .background-layer-one {
  position: absolute;
  left: 0px;
  top: 140px;
  width: 620px;
  height: 120px;
  background-position: center top;
  background-repeat: repeat-x;
}

.instructor-section .background-layer-two {
  position: absolute;
  right: 300px;
  top: 60px;
  width: 774px;
  height: 416px;
  background-position: center top;
  background-repeat: repeat-x;
}

.instructor-section .blocks-column {
  position: relative;
  margin-bottom: 40px;
}

.instructor-section .blocks-column .inner-column {
  position: relative;
  padding: 0px 0px 0px;
  margin-top: 60px;
}

.service-block {
  position: relative;
  z-index: 1;
}

.service-block .inner-box {
  position: relative;
  padding: 50px 30px;
  margin-left: 10px;
  margin-bottom: 40px;
}

.service-block .inner-box .border-layer {
  position: absolute;
  left: -12px;
  top: 0px;
  z-index: -1;
  width: 32px;
  bottom: 30px;
  border: 4px solid #06092d;
  -ms-transform: skewY(20deg);
  transform: skewY(20deg);
  transition: all 500ms ease;
  -moz-transition: all 500ms ease;
  -webkit-transition: all 500ms ease;
  -ms-transition: all 500ms ease;
  -o-transition: all 500ms ease;
}

.service-block .inner-box:hover .border-layer {
  border-color: #49bc60;
  -ms-transform: skewY(-20deg);
  transform: skewY(-20deg);
}

.service-block .inner-box:before {
  position: absolute;
  content: "";
  left: 0px;
  top: 0px;
  right: 0px;
  bottom: 0px;
  background-color: #ffffff;
  -ms-transform: skewY(4deg);
  transform: skewY(4deg);
  transition: all 500ms ease;
  -moz-transition: all 500ms ease;
  -webkit-transition: all 500ms ease;
  -ms-transition: all 500ms ease;
  -o-transition: all 500ms ease;
}

.service-block .inner-box:hover::before {
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
}

.service-block .inner-box .icon-box {
  position: relative;
  width: 75px;
  height: 75px;
  display: inline-block;
  transition: all 500ms ease;
  -moz-transition: all 500ms ease;
  -webkit-transition: all 500ms ease;
  -ms-transition: all 500ms ease;
  -o-transition: all 500ms ease;
}

.service-block .inner-box:hover .icon-box {
  -webkit-transform: scale(-1) rotate(180deg);
  -moz-transform: scale(-1) rotate(180deg);
  -ms-transform: scale(-1) rotate(180deg);
  -o-transform: scale(-1) rotate(180deg);
  transform: scale(-1) rotate(180deg);
}

.service-block .inner-box .icon-box:before {
  position: absolute;
  content: "";
  left: 0px;
  top: 0px;
  right: 0px;
  bottom: 0px;
  /* background-color:#e3fbe8; */
  -ms-transform: skewX(3deg);
  transform: skewX(3deg);
}

.service-block .inner-box .icon-box .icon {
  position: relative;
  width: 55px;
  height: 55px;
  color: #ffffff;
  font-size: 28px;
  margin-top: 10px;
  margin-left: 10px;
  line-height: 50px;
  text-align: center;
  border-radius: 50%;
  display: inline-block;
  background-color: #49bc60;
}

.service-block .inner-box h4 {
  position: relative;
  font-weight: 500;
  line-height: 1.3em;
  margin-top: 30px;
  margin-bottom: 18px;
}

.service-block .inner-box h4 a {
  position: relative;
  color: #06092d;
  transition: all 500ms ease;
  -moz-transition: all 500ms ease;
  -webkit-transition: all 500ms ease;
  -ms-transition: all 500ms ease;
  -o-transition: all 500ms ease;
}

.service-block .inner-box h4 a:hover {
  color: #49bc60;
}

.service-block .inner-box .text {
  position: relative;
  color: #06092d;
  font-size: 16px;
  line-height: 1.8em;
}

.service-block:nth-child(2) .inner-box .icon-box:before {
  /* background-color:#f9f6e2; */
}

.service-block:nth-child(2) .inner-box .icon-box .icon {
  /* background-color:#f8c333; */
}

.service-block:nth-child(2) .inner-box:hover .border-layer {
  border-color: #f8c333;
}

.service-block:nth-child(2) .inner-box h4 a:hover {
  color: #f8c333;
}

.instructor-section .instructor-column {
  position: relative;
  margin-bottom: 40px;
  margin-top: 50px;
}

.instructor-section .instructor-column .inner-column {
  position: relative;
  padding: 40px 35px;
  background-color: #ffffff;
  outline: 2px dashed #acd9c2;
  outline-offset: -12px;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
}

.instructor-section .instructor-column h4 {
  position: relative;
  color: #06092d;
  font-weight: 700;
  line-height: 1.3em;
  margin-bottom: 20px;
}

.instructor-section .instructor-column p {
  position: relative;
  color: #06092d;
  font-size: 16px;
  opacity: 0.9;
  line-height: 1.7em;
  margin-bottom: 15px;
}

.instructor-section .instructor-column p:last-child {
  margin-bottom: 0px;
}

.instructor-section .instructor-column .click-here {
  position: relative;
  color: #007cba;
  font-size: 16px;
  margin-top: 5px;
  font-weight: 500;
  margin-bottom: 20px;
  display: inline-block;
  border-bottom: 1px solid #007cba;
  transition: all 500ms ease;
  -moz-transition: all 500ms ease;
  -webkit-transition: all 500ms ease;
  -ms-transition: all 500ms ease;
  -o-transition: all 500ms ease;
}

.instructor-section .instructor-column .click-here:hover {
  color: #111111;
  border-color: #111111;
}

.instructor-section .instructor-column .image {
  position: relative;
  margin-top: 20px;
}

/*** 

====================================================================
	Benefit Section
====================================================================

***/

.benefit-section {
  position: relative;
  padding: 150px 0px 0px;
}

.benefit-section1 {
  position: relative;
  padding: 0px 0px 0px;
}

.benefit-section .background-layer-one {
  position: absolute;
  right: 0px;
  top: 20px;
  width: 606px;
  height: 343px;
  background-repeat: no-repeat;
}

.benefit-section .background-layer-two {
  position: absolute;
  left: 0px;
  bottom: 0px;
  width: 774px;
  height: 416px;
  background-repeat: no-repeat;
}

.benefit-section .images-column {
  position: relative;
  margin-bottom: 40px;
}

.benefit-section .images-column .inner-column {
  position: relative;
  margin-left: -50px;
}

.benefit-section .images-column .color-layer {
  position: absolute;
  right: 80px;
  top: -60px;
  width: 350px;
  height: 720px;
  /* background: #F2B4C4;
	background: -webkit-linear-gradient(to right, #F2B4C4 0%, #f4e3fb 100%);
	background: -moz-linear-gradient(to right, #F2B4C4 0%, #f4e3fb 100%);
	background: linear-gradient(to right, #F2B4C4 0%, #f4e3fb 100%); */
}

.benefit-section .images-column .inner-column .pattern-layer {
  position: absolute;
  left: 40px;
  top: -150px;
  width: 241px;
  height: 388px;
  background-repeat: no-repeat;
}

.benefit-section .images-column .inner-column .pattern-layer-two {
  position: absolute;
  left: -80px;
  bottom: -40px;
  width: 241px;
  height: 388px;
  background-repeat: no-repeat;
}

.benefit-section .images-column .image {
  position: relative;
  padding: 10px;
  display: inline-block;
  background-color: #ffffff;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.15);
}

.benefit-section .images-column .image-two {
  position: relative;
  margin-left: 130px;
  margin-top: -160px;
  padding: 10px;
  display: inline-block;
  background-color: #ffffff;
  box-shadow: 0px 10px 35px rgba(0, 0, 0, 0.25);
}

.benefit-section .images-column .image-three {
  position: absolute;
  right: 15px;
  top: 130px;
  padding: 10px;
  display: inline-block;
  background-color: #ffffff;
  box-shadow: 0px 10px 25px rgba(0, 0, 0, 0.25);
}

.benefit-section .content-column {
  position: relative;
  margin-bottom: 40px;
}

.benefit-section .content-column .inner-column {
  position: relative;
  margin-right: -60px;
}

.benefit-section .content-column .inner-column .btn-box {
  position: relative;
  margin-top: 40px;
}

.benefit-section .lower-text {
  position: relative;
  text-align: center;
  margin-top: 30px;
}

.benefit-section .lower-text p {
  position: relative;
  color: #06092d;
  font-size: 24px;
  line-height: 1.6em;
  font-weight: 500;
}

/*** 

====================================================================
	Feature Section
====================================================================

***/

.feature-section {
  position: relative;
  overflow: hidden;
  padding: 120px 0px 40px;
}

.feature-section .content-column {
  position: relative;
  margin-bottom: 40px;
}

.feature-section .content-column .inner-column {
  position: relative;
  padding-top: 45px;
  z-index: 1;
}

.feature-section .content-column .inner-column .sec-title {
  margin-bottom: 30px;
}

.feature-section .image-column {
  position: relative;
  margin-bottom: 40px;
}

.feature-section .image-column .feature-icon {
  position: absolute;
  right: -120px;
  top: -90px;
  z-index: 2;
}

.feature-section .image-column .inner-column {
  position: relative;
  padding-left: 45px;
}

.feature-section .image-column .color-layer {
  position: absolute;
  right: -30px;
  top: -20px;
  width: 520px;
  height: 520px;
  border-radius: 50%;
  background-color: #ffe4e8;
}

.feature-section .image-column .image {
  position: relative;
  width: 400px;
}

.feature-section .image-column .image img {
  position: relative;
  width: 100%;
  display: block;
}

.feature-section .image-column .image .overlay-box {
  position: absolute;
  left: 0px;
  top: 0px;
  right: 0px;
  bottom: 0px;
  padding: 50px 30px;
}

.feature-section .pattern-layer {
  position: absolute;
  right: 0px;
  top: 100px;
  width: 954px;
  height: 505px;
  background-repeat: no-repeat;
}

.feature-section .image-column .image h2 {
  position: relative;
  color: #ffffff;
  font-weight: 700;
  line-height: 1.2em;
  margin-bottom: 20px;
}

.feature-section .image-column .image p {
  position: relative;
  color: #ffffff;
  font-size: 16px;
  line-height: 1.7em;
  margin-bottom: 12px;
}

.feature-section .image-column .image .learn {
  position: relative;
  color: #007cba;
  margin-top: 15px;
  display: inline-block;
  border-bottom: 1px solid #007cba;
}

/*** 

====================================================================
	Courses Section
====================================================================

***/

.courses-section {
  position: relative;
  overflow: hidden;
  padding: 30px 0px 40px;
}

.courses-section .pattern-layer {
  position: absolute;
  right: -80px;
  bottom: 450px;
  width: 774px;
  height: 416px;
}

.courses-section .circle-one {
  position: absolute;
  left: -350px;
  top: 0px;
  width: 600px;
  height: 600px;
  border-radius: 50%;
  display: inline-block;
  background-color: #6af0fc;
}

.courses-section .circle-two {
  position: absolute;
  left: -350px;
  top: 260px;
  width: 600px;
  height: 600px;
  border-radius: 50%;
  display: inline-block;
  background-color: #fbfbff;
}

.course-block {
  position: relative;
  margin-bottom: 30px;
}

.course-block .inner-box {
  position: relative;
}

.course-block .inner-box .image {
  position: relative;
  overflow: hidden;
  background-color: #007cba;
}

.course-block .inner-box .image img {
  position: relative;
  width: 100%;
  display: block;
  transition: all 500ms ease;
  -moz-transition: all 500ms ease;
  -webkit-transition: all 500ms ease;
  -ms-transition: all 500ms ease;
  -o-transition: all 500ms ease;
}

.course-block .inner-box:hover .image img {
  opacity: 0.6;
  transform: scale(1.05, 1.05);
}

.course-block .inner-box .image .tag {
  position: absolute;
  right: 15px;
  bottom: 15px;
  color: #ffffff;
  padding: 8px 12px;
  border-radius: 5px;
  display: inline-block;
  background-color: #007cba;
  text-transform: uppercase;
}

.course-block .inner-box .lower-content {
  position: relative;
  padding: 30px 25px;
  background-color: #fbfbff;
}

.course-block .inner-box .lower-content h4 {
  position: relative;
  font-weight: 700;
  line-height: 1.4em;
  padding-bottom: 20px;
  border-bottom: 1px solid #dadada;
}

.course-block .inner-box .lower-content h4 a {
  position: relative;
  color: #000000;
  transition: all 500ms ease;
  -moz-transition: all 500ms ease;
  -webkit-transition: all 500ms ease;
  -ms-transition: all 500ms ease;
  -o-transition: all 500ms ease;
}

.course-block .inner-box .lower-content h4 a:hover {
  color: #007cba;
}

.course-block .inner-box .lower-content .uni-name {
  position: relative;
  color: #007cba;
  font-size: 15px;
  margin-top: 18px;
  margin-bottom: 12px;
}

.course-block .inner-box .lower-content .rating {
  position: relative;
  color: #fbb039;
  font-size: 16px;
}

.course-block .inner-box .lower-content .rating strong {
  position: relative;
  color: #000000;
  margin-left: 10px;
  font-size: 16px;
}

.course-block .inner-box .lower-content .rating i {
  position: relative;
  color: #000000;
  top: -2px;
  font-size: 14px;
  font-weight: 400;
  font-style: normal;
  margin-left: 5px;
}

.course-block .inner-box .lower-content .price {
  position: relative;
  color: #423b3c;
  font-size: 20px;
  font-weight: 700;
  margin-top: 10px;
  margin-bottom: 22px;
}

.course-block .inner-box .lower-content .price span {
  position: relative;
  font-weight: 400;
  font-size: 16px;
  margin-left: 10px;
  text-decoration: line-through;
}

.course-block .inner-box .lower-content .hovers {
  position: relative;
  color: #000000;
  font-size: 13px;
}

.course-block .inner-box .lower-content .enroll-now {
  position: relative;
  color: #ffffff;
  font-size: 13px;
  padding: 12px 18px;
  border-radius: 5px;
  letter-spacing: 1px;
  background-color: #007cba;
  text-transform: uppercase;
  transition: all 500ms ease;
  -moz-transition: all 500ms ease;
  -webkit-transition: all 500ms ease;
  -ms-transition: all 500ms ease;
  -o-transition: all 500ms ease;
}

.course-block .inner-box .lower-content .enroll-now:hover {
  color: #111111;
  background-color: #6af0fc;
}

.course-block .inner-box .overlay-content-box {
  position: absolute;
  left: 100%;
  top: -35px;
  width: 360px;
  z-index: 1;
  opacity: 0;
  visibility: hidden;
  padding: 25px 25px;
  background-color: #ffffff;
  transition: all 500ms ease;
  -moz-transition: all 500ms ease;
  -webkit-transition: all 500ms ease;
  -ms-transition: all 500ms ease;
  -o-transition: all 500ms ease;
  transform: translateX(20px);
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
}

.course-block .inner-box:hover .overlay-content-box {
  opacity: 1;
  visibility: visible;
  transform: translateX(0px);
}

.course-block .inner-box .overlay-content-box:before {
  position: absolute;
  content: "";
  left: -10px;
  top: 32%;
  border-top: 6px solid transparent;
  border-right: 10px solid #ffffff;
  border-bottom: 6px solid transparent;
}

.course-block:nth-child(4n + 0) .inner-box .overlay-content-box {
  left: auto;
  right: 100%;
}

.course-block:nth-child(4n + 0) .inner-box .overlay-content-box:before {
  right: -10px;
  left: auto;
  border-right: 0px;
  border-top: 6px solid transparent;
  border-left: 10px solid #ffffff;
  border-bottom: 6px solid transparent;
}

.course-block .inner-box .overlay-content-box h4 {
  position: relative;
  font-weight: 700;
  line-height: 1.3em;
  color: #000000;
  font-size: 24px;
  margin-bottom: 20px;
  padding-bottom: 18px;
  border-bottom: 1px solid #dadada;
}

.course-block .inner-box .overlay-content-box .uni-name {
  position: relative;
  color: #007cba;
  font-size: 15px;
  margin-top: 18px;
  margin-bottom: 12px;
}

.course-block .inner-box .overlay-content-box .rating {
  position: relative;
  color: #fbb039;
  font-size: 16px;
}

.course-block .inner-box .overlay-content-box .rating strong {
  position: relative;
  color: #000000;
  margin-left: 10px;
  font-size: 16px;
}

.course-block .inner-box .overlay-content-box .rating i {
  position: relative;
  color: #000000;
  top: -2px;
  font-size: 14px;
  font-weight: 400;
  font-style: normal;
  margin-left: 5px;
}

.course-block .inner-box .overlay-content-box .price {
  position: relative;
  color: #423b3c;
  font-size: 20px;
  font-weight: 700;
  margin-top: 10px;
  margin-bottom: 22px;
}

.course-block .inner-box .overlay-content-box .price span {
  position: relative;
  font-weight: 400;
  font-size: 16px;
  margin-left: 10px;
  text-decoration: line-through;
}

.course-block .inner-box .overlay-content-box .hovers {
  position: relative;
  color: #000000;
  font-size: 13px;
}

.course-block .inner-box .overlay-content-box .enroll-now {
  position: relative;
  color: #ffffff;
  font-size: 13px;
  padding: 12px 18px;
  border-radius: 5px;
  letter-spacing: 1px;
  background-color: #007cba;
  text-transform: uppercase;
  transition: all 500ms ease;
  -moz-transition: all 500ms ease;
  -webkit-transition: all 500ms ease;
  -ms-transition: all 500ms ease;
  -o-transition: all 500ms ease;
}

.course-block .inner-box .overlay-content-box .enroll-now:hover {
  background-color: #111111;
}

.course-block .inner-box .overlay-content-box .text {
  position: relative;
  color: #000000;
  font-size: 14px;
  line-height: 1.8em;
  margin-top: 15px;
}

.course-block .inner-box .overlay-content-box .lists {
  position: relative;
  margin-top: 20px;
}

.course-block .inner-box .overlay-content-box .lists li {
  position: relative;
  margin-bottom: 12px;
  color: #000000;
  font-size: 14px;
  padding-left: 25px;
}

.course-block .inner-box .overlay-content-box .lists li:before {
  position: absolute;
  content: "";
  left: 0px;
  top: 10px;
  width: 15px;
  height: 3px;
  background-color: #007cba;
}

.course-block .inner-box .overlay-content-box .lists li:last-child {
  margin-bottom: 0px;
}

.course-block .inner-box .overlay-content-box .btns-box {
  position: relative;
  margin-top: 22px;
}

.course-block .inner-box .overlay-content-box .btns-box .enrol-btn {
  position: relative;
  color: #000000;
  font-size: 16px;
  padding: 16px 26px;
  border-radius: 6px;
  font-weight: 500;
  margin-right: 10px;
  display: inline-block;
  background-color: #6af0fc;
}

.course-block .inner-box .overlay-content-box .btns-box .wishlist-btn {
  position: relative;
  color: #000000;
  font-size: 16px;
  padding: 16px 26px;
  border-radius: 6px;
  font-weight: 500;
  display: inline-block;
  background-color: #eef7f3;
}

.course-block.style-two .inner-box .lower-content {
  position: relative;
  padding: 30px 20px;
  background-color: #ffffff;
  border: 1px solid #f5f3f3;
}

.course-block.style-two .inner-box .lower-content .price {
  float: left;
  margin-bottom: 0px;
}

.course-block.style-two .inner-box .lower-content .hovers {
  float: left;
  margin-top: 10px;
  margin-left: 12px;
}

/*** 

====================================================================
	Courses Section
====================================================================

***/

.career-section {
  position: relative;
  padding: 0px 0px 100px;
}

.career-section .image-column {
  position: relative;
}

.career-section .image-column .inner-column {
  position: relative;
}

.career-section .image-column .image {
  position: relative;
}

.career-section .content-column {
  position: relative;
}

.career-section .content-column .inner-column {
  position: relative;
  padding-top: 50px;
  padding-right: 100px;
}

.career-section .content-column h5 {
  position: relative;
  color: #06092d;
  font-weight: 700;
  line-height: 1.3em;
  margin-bottom: 15px;
}

.career-section .content-column .text {
  position: relative;
  margin-bottom: 30px;
}

.career-section .content-column .text p {
  position: relative;
  color: #06092d;
  font-size: 16px;
  line-height: 1.6em;
  margin-bottom: 15px;
}

.career-section .content-column .text p:last-child {
  margin-bottom: 0px;
}

/*** 

====================================================================
	Skill Section
====================================================================

***/

.skill-section {
  position: relative;
  padding: 90px 0px 40px;
  background-color: #f3fbf8;
}

.skill-section .pattern-layer {
  position: absolute;
  left: 0px;
  top: 60px;
  width: 100%;
  height: 654px;
}

.skill-section .skill-column {
  position: relative;
  margin-bottom: 40px;
}

.skill-section .skill-column .inner-column {
  position: relative;
  padding-top: 20px;
  padding-right: 80px;
}

.skill-section .skill-column .inner-column .sec-title {
  margin-bottom: 20px;
}

/* Skills Section */

.skills .skill-item {
  position: relative;
  margin-bottom: 30px;
}

.skills .skill-item:last-child {
  margin-bottom: 0px;
}

.skills .skill-item .skill-bar {
  position: relative;
  width: 100%;
  height: 8px;
}

.skills .skill-item .skill-bar .bar-inner {
  position: relative;
  width: 100%;
  height: 8px;
  overflow: hidden;
  border-radius: 50px;
  background: #ffffff;
}

.skills .skill-item .skill-bar .bar-inner .bar {
  position: absolute;
  left: 0px;
  top: 0px;
  height: 8px;
  width: 0px;
  border-radius: 50px;
  border-bottom: 8px solid #6af0fc;
  -webkit-transition: all 2000ms ease;
  -ms-transition: all 2000ms ease;
  -o-transition: all 2000ms ease;
  -moz-transition: all 2000ms ease;
  transition: all 2000ms ease;
}

.skills .skill-item .skill-header {
  position: relative;
  margin-bottom: 12px;
}

.skills .skill-item .skill-header .skill-title {
  position: relative;
}

.skills .skill-item .skill-header .skill-title {
  float: left;
  color: #000000;
  font-size: 16px;
  font-weight: 600;
  line-height: 1.4em;
  text-transform: capitalize;
}

.skills .skill-item .skill-header .skill-percentage {
  position: relative;
  float: right;
  font-weight: 600;
  color: #000000;
  font-size: 16px;
  text-transform: uppercase;
}

.skills .skill-item:nth-child(2) .skill-bar .bar-inner .bar {
  border-color: #007cba;
}

.skills .skill-item:nth-child(3) .skill-bar .bar-inner .bar {
  border-color: #fc7f92;
}

.skill-section .image-column {
  position: relative;
  margin-bottom: 40px;
}

.skill-section .image-column .inner-column {
  position: relative;
}

.skill-section .image-column .inner-column .image {
  position: relative;
  z-index: 1;
}

.skill-section .image-column .circle-one {
  position: absolute;
  left: 50%;
  top: 50%;
  width: 270px;
  height: 270px;
  border-radius: 50%;
  background-color: #effef7;
}

.skill-section .image-column .circle-two {
  position: absolute;
  left: 0%;
  top: 0%;
  width: 270px;
  height: 270px;
  border-radius: 50%;
  background-color: #f3fbf8;
}

/*** 

====================================================================
	Testimonial Section
====================================================================

***/

.testimonial-section {
  position: relative;
  overflow: hidden;
  padding: 110px 0px 100px;
}

.testimonial-section .circle-one {
  position: absolute;
  right: -150px;
  top: 150px;
  width: 400px;
  height: 400px;
  border-radius: 50%;
  display: inline-block;
  background-color: #6af0fc;
}

.testimonial-section .circle-two {
  position: absolute;
  right: -200px;
  top: 320px;
  width: 400px;
  height: 400px;
  border-radius: 50%;
  display: inline-block;
  background-color: #ffffff;
}

.testimonial-section .pattern-layer-two {
  position: absolute;
  left: 0px;
  bottom: 150px;
  width: 446px;
  height: 406px;
  background-repeat: no-repeat;
}

.testimonial-section .inner-container {
  position: relative;
}

.testimonial-section .inner-container .pattern-layer {
  position: absolute;
  left: 0px;
  top: 0px;
  right: 0px;
  height: 519px;
  background-position: center top;
  background-repeat: no-repeat;
}

.testimonial-section .owl-carousel .owl-stage-outer {
  padding: 20px 0px 20px;
}

.testimonial-section .owl-theme .testimonial-block {
  margin: 0px 15px;
}

.testimonial-section .owl-carousel {
  margin: 0px -15px;
  width: auto;
}

.testimonial-section .owl-dots {
  position: relative;
  text-align: center;
  margin-top: 40px;
}

.testimonial-section .owl-dots .owl-dot {
  position: relative;
  margin: 0px 5px;
  width: 10px;
  height: 10px;
  border-radius: 50px;
  display: inline-block;
  border: 2px solid #cce3d7;
  transition: all 500ms ease;
  -moz-transition: all 500ms ease;
  -webkit-transition: all 500ms ease;
  -ms-transition: all 500ms ease;
  -o-transition: all 500ms ease;
}

.testimonial-section .owl-dots .owl-dot.active,
.testimonial-section .owl-dots .owl-dot:hover {
  border-color: #007cba;
  background-color: #007cba;
}

.testimonial-section .owl-nav {
  display: none;
}

.testimonial-block {
  position: relative;
  margin-bottom: 30px;
}

.testimonial-block .inner-box {
  position: relative;
  padding: 45px 25px 50px;
}

.testimonial-block .inner-box:before {
  position: absolute;
  content: "";
  left: 0px;
  top: 0px;
  right: 0px;
  bottom: 0px;
  border-radius: 8px;
  background-color: #ffffff;
  -ms-transform: skewY(2deg);
  transform: skewY(2deg);
  transition: all 500ms ease;
  -moz-transition: all 500ms ease;
  -webkit-transition: all 500ms ease;
  -ms-transition: all 500ms ease;
  -o-transition: all 500ms ease;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
}

.testimonial-block .inner-box .text {
  position: relative;
  color: #06092d;
  font-size: 20px;
  line-height: 1.5em;
  margin-bottom: 30px;
}

.testimonial-block .inner-box .author-info {
  position: relative;
}

.testimonial-block .inner-box .author-info .info-inner {
  position: relative;
  padding-left: 82px;
  padding-top: 10px;
}

.testimonial-block .inner-box .author-info .info-inner .author-image {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 62px;
  height: 62px;
  overflow: hidden;
  border-radius: 50%;
  display: inline-block;
}

.testimonial-block .inner-box .author-info h6 {
  position: relative;
  color: #06092d;
  font-weight: 500;
  line-height: 1.3em;
}

.testimonial-block .inner-box .author-info .designation {
  position: relative;
  color: #007cba;
  font-weight: 400;
  line-height: 1.2em;
  margin-top: 4px;
}

.testimonial-block .inner-box .quote-icon {
  position: absolute;
  right: 40px;
  bottom: 20px;
  color: #000000;
  line-height: 1em;
  font-size: 36px;
}

/*** 

====================================================================
	News Section
====================================================================

***/

.news-section {
  position: relative;
}

.news-section.style-two {
  padding-top: 150px;
  overflow: hidden;
  padding-bottom: 40px;
}

.news-section .outer-container {
  position: relative;
  padding: 0px 80px;
  max-width: 1850px;
  margin: 0 auto;
}

.news-section .pattern-layer {
  position: absolute;
  left: 0px;
  top: 300px;
  width: 100%;
  height: 643px;
  background-repeat: no-repeat;
}

.news-section .inner-container {
  position: relative;
}

.news-section .icon-layer-one {
  position: absolute;
  left: -15%;
  bottom: 100px;
  width: 160px;
  height: 133px;
  background-repeat: no-repeat;
}

.news-section .icon-layer-two {
  position: absolute;
  right: 0%;
  top: 0px;
  width: 69px;
  height: 69px;
  background-repeat: no-repeat;
}

.news-section .icon-layer-three {
  position: absolute;
  right: -6%;
  bottom: 120px;
  width: 69px;
  height: 69px;
  opacity: 0.5;
  background-repeat: no-repeat;
}

.news-block {
  position: relative;
  margin-bottom: 30px;
}

.news-block .inner-box {
  position: relative;
}

.news-block .inner-box .image {
  position: relative;
  overflow: hidden;
  display: inline-block;
  background-color: #007cba;
}

.news-block .inner-box .image img {
  position: relative;
  transition: all 500ms ease;
  -moz-transition: all 500ms ease;
  -webkit-transition: all 500ms ease;
  -ms-transition: all 500ms ease;
  -o-transition: all 500ms ease;
}

.news-block .inner-box:hover .image img {
  opacity: 0.5;
  transform: scale(1.05, 1.05);
}

.news-block .inner-box .lower-content {
  position: relative;
  margin-left: 200px;
  padding: 35px 25px;
  margin-top: -165px;
  z-index: 1;
}

.news-block .inner-box .lower-content .border-layer {
  position: absolute;
  left: -12px;
  top: -30px;
  z-index: -1;
  width: 32px;
  bottom: -10px;
  border: 4px solid #007cba;
  -ms-transform: skewY(20deg);
  transform: skewY(20deg);
  transition: all 500ms ease;
  -moz-transition: all 500ms ease;
  -webkit-transition: all 500ms ease;
  -ms-transition: all 500ms ease;
  -o-transition: all 500ms ease;
}

.news-block .inner-box:hover .lower-content .border-layer {
  -ms-transform: skewY(-20deg);
  transform: skewY(-20deg);
}

.news-block .inner-box .lower-content:before {
  position: absolute;
  content: "";
  left: 0px;
  top: 0px;
  right: 0px;
  bottom: 0px;
  border-radius: 0px;
  background-color: #ffffff;
  -ms-transform: skewY(2deg);
  transform: skewY(2deg);
  transition: all 500ms ease;
  -moz-transition: all 500ms ease;
  -webkit-transition: all 500ms ease;
  -ms-transition: all 500ms ease;
  -o-transition: all 500ms ease;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
}

.news-block .inner-box .lower-content .post-info {
  position: relative;
}

.news-block .inner-box .lower-content .post-info li:first-child {
  position: relative;
  color: #ffffff;
  border-radius: 50px;
  padding: 0px 12px;
  background-color: #007cba;
}

.news-block .inner-box .lower-content .post-info li {
  position: relative;
  color: #272626;
  font-size: 14px;
  margin-right: 15px;
  display: inline-block;
}

.news-block .inner-box .lower-content h4 {
  position: relative;
  font-weight: 700;
  line-height: 1.3em;
  margin-top: 25px;
  padding-bottom: 20px;
  border-bottom: 1px solid #dad4d5;
}

.news-block .inner-box .lower-content h4 a {
  position: relative;
  color: #06092d;
  transition: all 500ms ease;
  -moz-transition: all 500ms ease;
  -webkit-transition: all 500ms ease;
  -ms-transition: all 500ms ease;
  -o-transition: all 500ms ease;
}

.news-block .inner-box .lower-content .more {
  position: relative;
  color: #06092d;
  font-size: 16px;
  margin-top: 22px;
  font-weight: 600;
  display: inline-block;
  transition: all 500ms ease;
  -moz-transition: all 500ms ease;
  -webkit-transition: all 500ms ease;
  -ms-transition: all 500ms ease;
  -o-transition: all 500ms ease;
}

.news-block .inner-box .lower-content h4 a:hover,
.news-block .inner-box .lower-content .more:hover {
  color: #007cba;
}

.news-block.style-two .inner-box .lower-content {
  margin-left: 80px;
}

/*** 

====================================================================
	Clients Section
====================================================================

***/

.clients-section {
  position: relative;
  padding-top: 100px;
  padding-bottom: 100px;
}

.clients-section .sponsors-outer .owl-dots,
.clients-section .sponsors-outer .owl-nav {
  position: relative;
  display: none;
}

.clients-section .sponsors-outer .image-box {
  position: relative;
  text-align: center;
  margin: 0px;
}

.clients-section .sponsors-outer .image-box img {
  max-width: 100%;
  width: auto;
  margin: 0 auto;
  display: inline-block;
  -webkit-transition: all 300ms ease;
  -ms-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  -moz-transition: all 300ms ease;
  transition: all 300ms ease;
}

.clients-section .sponsors-outer .image-box img:hover {
  opacity: 0.7;
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
}

/*** 

====================================================================
	Main Footer
====================================================================

***/

.main-footer {
  position: relative;
  overflow: hidden;
  background: #f8fbff;
}

.main-footer .widgets-section {
  position: relative;
  padding: 70px 0px 4px;
}

.main-footer .circle-layer {
  position: absolute;
  right: -400px;
  bottom: -400px;
  width: 600px;
  height: 600px;
  border-radius: 50%;
  background-color: #6af0fc;
}

.main-footer .pattern-layer-one {
  position: absolute;
  left: 0px;
  bottom: -80px;
  width: 256px;
  height: 392px;
  background-repeat: no-repeat;
}

.main-footer .pattern-layer-two {
  position: absolute;
  left: 140px;
  bottom: 40px;
  width: 69px;
  height: 69px;
  background-position: center top;
  background-repeat: no-repeat;
}

.main-footer .pattern-layer-three {
  position: absolute;
  bottom: 0px;
  right: 0px;
  width: 292px;
  height: 120px;
  background-repeat: no-repeat;
}

.main-footer .pattern-layer-four {
  position: absolute;
  right: 140px;
  bottom: 40px;
  width: 69px;
  height: 69px;
  background-position: center top;
  background-repeat: no-repeat;
}

.main-footer .footer-widget {
  position: relative;
  z-index: 1;
  margin-bottom: 40px;
}

.main-footer .footer-column h5 {
  position: relative;
  font-weight: 700;
  color: #00184f;
  line-height: 1.2em;
  margin-bottom: 25px;
  margin-top: 30px;
  text-transform: capitalize;
}

.main-footer .logo-widget {
  position: relative;
}

.main-footer .logo-widget .logo {
  position: relative;
  margin-bottom: 15px;
}

.main-footer .logo-widget .info-list {
  position: relative;
  margin-bottom: 20px;
}

.main-footer .logo-widget .info-list li {
  position: relative;
  color: #00184f;
  font-size: 16px;
  margin-bottom: 6px;
}

.main-footer .logo-widget .info-list li a {
  position: relative;
  color: #00184f;
}

.main-footer .logo-widget .social-box {
  position: relative;
}

.main-footer .logo-widget .social-box li {
  position: relative;
  margin-right: 8px;
  display: inline-block;
}

.main-footer .logo-widget .social-box li a {
  position: relative;
  color: #ffffff;
  font-size: 15px;
  width: 34px;
  height: 34px;
  line-height: 34px;
  text-align: center;
  border-radius: 50px;
  display: inline-block;
  background-color: #55acee;
}

.main-footer .logo-widget .social-box li a::before {
  -webkit-transition: all 600ms ease;
  -ms-transition: all 600ms ease;
  -o-transition: all 600ms ease;
  -moz-transition: all 600ms ease;
  transition: all 600ms ease;
  display: inline-block;
}

.main-footer .logo-widget .social-box li a:hover::before {
  transform: rotateY(360deg);
}

.main-footer .logo-widget .social-box li.twitter a {
  background-color: #55acee;
}

.main-footer .logo-widget .social-box li.pinterest a {
  background-color: #bd081c;
}

.main-footer .logo-widget .social-box li.facebook a {
  background-color: #3b5999;
}

.main-footer .logo-widget .social-box li.dribbble a {
  background-color: #ea4c89;
}

.main-footer .logo-widget .text {
  position: relative;
  color: #00184f;
  font-size: 20px;
  line-height: 1.6em;
  margin-top: 30px;
}

.main-footer .list {
  position: relative;
}

.main-footer .list li {
  position: relative;
  margin-bottom: 8px;
}

.main-footer .list li a {
  position: relative;
  color: #00184f;
  font-size: 16px;
  -webkit-transition: all 300ms ease;
  -ms-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  -moz-transition: all 300ms ease;
  transition: all 300ms ease;
}

.main-footer .list li a:hover {
  color: #007cba;
}

.main-footer .lower-box {
  position: relative;
  padding-bottom: 15px;
  margin-bottom: 50px;
}

/* Subscribe Form */

.subscribe-form {
  position: relative;
  width: 300px;
  margin-bottom: 20px;
}

.subscribe-form h6 {
  position: relative;
  color: #00184f;
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 15px;
}

.subscribe-form .form-group {
  position: relative;
  display: block;
  z-index: 1;
  padding-right: 60px;
  margin-bottom: 0px;
}

.subscribe-form .form-group input[type="text"],
.subscribe-form .form-group input[type="tel"],
.subscribe-form .form-group input[type="email"],
.subscribe-form .form-group textarea {
  position: relative;
  width: 100%;
  line-height: 28px;
  padding: 10px 25px;
  height: 50px;
  color: #00184f;
  display: inline-block;
  font-size: 14px;
  letter-spacing: 1px;
  border-radius: 6px;
  -webkit-transition: all 300ms ease;
  -ms-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  -moz-transition: all 300ms ease;
  transition: all 300ms ease;
  background-color: #e8ebef;
}

.subscribe-form .form-group input:focus {
}

.subscribe-form .form-group input[type="submit"],
.subscribe-form .form-group button {
  position: absolute;
  width: 45px;
  height: 50px;
  right: 0px;
  top: 0px;
  bottom: 5px;
  color: #ffffff;
  cursor: pointer;
  border-radius: 5px;
  background-color: #007cba;
  display: inline-block;
  -webkit-transition: all 300ms ease;
  -ms-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  -moz-transition: all 300ms ease;
  transition: all 300ms ease;
}

.subscribe-form .form-group button:hover {
  background-color: #6af0fc;
}

.main-footer .lower-box .text {
  position: relative;
  color: #00184f;
  font-size: 16px;
  line-height: 1.8em;
}

.main-footer .lower-box .singup {
  position: absolute;
  right: 15px;
  top: 15px;
  font-weight: 400;
  color: #007cba;
  font-size: 16px;
  text-decoration: underline;
}

.main-footer .footer-bottom {
  position: relative;
  padding: 25px 0px;
  border-top: 1px solid #dee0e4;
}

.main-footer .footer-bottom .copyright {
  position: relative;
  color: #102659;
  font-size: 16px;
}

.main-footer .footer-bottom .copyright a {
  position: relative;
  color: #102659;
}

.main-footer .footer-bottom .nav-column {
  position: relative;
}

.main-footer .footer-bottom .nav-column ul {
  position: relative;
  text-align: right;
}

.main-footer .footer-bottom .nav-column ul li {
  position: relative;
  margin-right: 12px;
  padding-right: 12px;
  line-height: 1.2em;
  display: inline-block;
  border-right: 1px solid #dee0e4;
}

.main-footer .footer-bottom .nav-column ul li a {
  position: relative;
  color: #102659;
  font-size: 15px;
}

.main-footer .footer-bottom .nav-column ul li:last-child {
  margin-right: 0px;
  padding-right: 0px;
  border-right: 0px;
}

/*** 

====================================================================
	Banner Section Two
====================================================================

***/

.banner-section-two {
  position: relative;
  overflow: hidden;
  padding-top: 100px;
  padding-bottom: 200px;
}

.banner-section-two .content-column .pattern-layer-one {
  position: absolute;
  left: -240px;
  top: -20px;
  width: 467px;
  height: 327px;
  z-index: 2;
  background-position: center bottom;
  background-repeat: no-repeat;
  background-size: cover;
  transition: all 900ms ease;
  -moz-transition: all 900ms ease;
  -webkit-transition: all 900ms ease;
  -ms-transition: all 900ms ease;
  -o-transition: all 900ms ease;
}

.banner-section-two .content-column .icon-layer-one {
  position: absolute;
  left: -70px;
  top: 240px;
  width: 20px;
  height: 20px;
  z-index: 2;
  background-position: center bottom;
  background-repeat: no-repeat;
  background-size: cover;
  transition: all 900ms ease;
  -moz-transition: all 900ms ease;
  -webkit-transition: all 900ms ease;
  -ms-transition: all 900ms ease;
  -o-transition: all 900ms ease;
}

.banner-section-two .content-column .icon-layer-two {
  position: absolute;
  left: -220px;
  top: 340px;
  width: 86px;
  height: 84px;
  z-index: 2;
  background-position: center bottom;
  background-repeat: no-repeat;
  background-size: cover;
  transition: all 900ms ease;
  -moz-transition: all 900ms ease;
  -webkit-transition: all 900ms ease;
  -ms-transition: all 900ms ease;
  -o-transition: all 900ms ease;
}

.banner-section-two .content-column .icon-layer-three {
  position: absolute;
  left: -120px;
  top: 480px;
  width: 195px;
  height: 109px;
  z-index: 2;
  background-position: center bottom;
  background-repeat: no-repeat;
  background-size: cover;
  transition: all 900ms ease;
  -moz-transition: all 900ms ease;
  -webkit-transition: all 900ms ease;
  -ms-transition: all 900ms ease;
  -o-transition: all 900ms ease;
}

.banner-section-two .content-column .icon-layer-four {
  position: absolute;
  left: 10px;
  bottom: -80px;
  width: 91px;
  height: 93px;
  z-index: 2;
  background-position: center bottom;
  background-repeat: no-repeat;
  background-size: cover;
  transition: all 900ms ease;
  -moz-transition: all 900ms ease;
  -webkit-transition: all 900ms ease;
  -ms-transition: all 900ms ease;
  -o-transition: all 900ms ease;
}

.banner-section-two .content-column .icon-layer-five {
  position: absolute;
  left: 250px;
  bottom: -60px;
  width: 105px;
  height: 105px;
  z-index: 2;
  background-position: center bottom;
  background-repeat: no-repeat;
  background-size: cover;
  transition: all 900ms ease;
  -moz-transition: all 900ms ease;
  -webkit-transition: all 900ms ease;
  -ms-transition: all 900ms ease;
  -o-transition: all 900ms ease;
}

.banner-section-two .pattern-layer-two {
  position: absolute;
  right: -50px;
  top: 160px;
  width: 410px;
  height: 436px;
  z-index: 2;
  background-position: center bottom;
  background-repeat: no-repeat;
  background-size: cover;
  transition: all 900ms ease;
  -moz-transition: all 900ms ease;
  -webkit-transition: all 900ms ease;
  -ms-transition: all 900ms ease;
  -o-transition: all 900ms ease;
}

.banner-section-two .pattern-layer-three {
  position: absolute;
  right: 150px;
  top: 220px;
  width: 20px;
  height: 20px;
  z-index: 2;
  background-position: center bottom;
  background-repeat: no-repeat;
  background-size: cover;
  transition: all 900ms ease;
  -moz-transition: all 900ms ease;
  -webkit-transition: all 900ms ease;
  -ms-transition: all 900ms ease;
  -o-transition: all 900ms ease;
}

.banner-section-two .image-column {
  position: relative;
  z-index: 10;
}

.banner-section-two .image-column .inner-column {
  position: relative;
  padding-left: 30px;
}

.banner-section-two .image-column .inner-column .image {
  position: relative;
  margin-right: -60px;
}

.banner-section-two .content-column {
  position: relative;
  z-index: 10;
}

.banner-section-two .content-column .inner-column {
  position: relative;
  margin-left: -60px;
  padding-top: 120px;
}

.banner-section-two .content-column .title {
  position: relative;
  color: #06092d;
  font-size: 16px;
  line-height: 1.8em;
  margin-bottom: 25px;
  font-weight: 500;
  letter-spacing: 4px;
  opacity: 0;
  transform: scaleY(0);
  transform-origin: top;
  text-transform: uppercase;
  -webkit-transition: all 1000ms ease;
  -moz-transition: all 100ms ease;
  -ms-transition: all 1000ms ease;
  -o-transition: all 1000ms ease;
  transition: all 1000ms ease;
}

.banner-section-two .content-column h1 {
  position: relative;
  color: #06092d;
  font-weight: 900;
  line-height: 1.2em;
  -webkit-transition: all 1000ms ease;
  -moz-transition: all 100ms ease;
  -ms-transition: all 1000ms ease;
  -o-transition: all 1000ms ease;
  transition: all 1000ms ease;
}

.banner-section-two .content-column .btns-box {
  margin-top: 45px;
  -webkit-transition: all 1000ms ease;
  -moz-transition: all 100ms ease;
  -ms-transition: all 1000ms ease;
  -o-transition: all 1000ms ease;
  transition: all 1000ms ease;
}

.banner-section-two .content-column .theme-btn {
  margin-right: 15px;
}

.banner-section-two .owl-dots {
  position: absolute;
  left: 0px;
  right: 0px;
  bottom: 40px;
  display: none;
  text-align: center;
}

.banner-section-two .owl-dots .owl-dot {
  position: relative;
  width: 30px;
  height: 6px;
  margin-bottom: 10px;
  border-radius: 0px;
  margin: 0px 5px;
  display: inline-block;
  background-color: #007cba;
  transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
}

.banner-section-two .owl-dots .owl-dot.active,
.banner-section-two .owl-dots .owl-dot:hover {
  background-color: #06092d;
}

.banner-section-two .owl-nav {
  position: absolute;
  left: 0px;
  top: 50%;
  z-index: 99999;
  width: 100%;
  opacity: 0;
  margin-top: -10px;
  transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
}

.banner-section-two:hover .owl-nav {
  opacity: 1;
}

.banner-section-two .owl-nav .owl-prev {
  position: absolute;
  left: 20px;
  width: 60px;
  height: 60px;
  color: #007cba;
  line-height: 56px;
  font-size: 24px;
  text-align: center;
  border-radius: 50px;
  transition: all 500ms ease;
  -moz-transition: all 500ms ease;
  -webkit-transition: all 500ms ease;
  -ms-transition: all 500ms ease;
  -o-transition: all 500ms ease;
  border: 2px solid #007cba;
}

.banner-section-two .owl-nav .owl-next {
  position: absolute;
  right: 20px;
  font-size: 24px;
  width: 60px;
  height: 60px;
  color: #007cba;
  line-height: 56px;
  border-radius: 50px;
  text-align: center;
  transition: all 500ms ease;
  -moz-transition: all 500ms ease;
  -webkit-transition: all 500ms ease;
  -ms-transition: all 500ms ease;
  -o-transition: all 500ms ease;
  border: 2px solid #007cba;
}

.banner-section-two .owl-nav .owl-prev:hover,
.banner-section-two .owl-nav .owl-next:hover {
  color: #ffffff;
  border-color: #007cba;
  background-color: #007cba;
}

/*** 

====================================================================
	Professional Section
====================================================================

***/

.professional-section {
  position: relative;
  padding: 160px 0px 50px;
}

.professional-section.style-two {
  padding-top: 220px;
}

.professional-section.style-two .background-layer-one {
  top: 240px;
}

.professional-section .background-layer-one {
  position: absolute;
  right: 0px;
  top: 100px;
  width: 606px;
  height: 343px;
  background-repeat: no-repeat;
}

.professional-section .images-column {
  position: relative;
  margin-bottom: 40px;
}

.professional-section .images-column .inner-column {
  position: relative;
  padding-right: 45px;
}

.professional-section .images-column .color-layer {
  position: absolute;
  right: 30px;
  top: -50px;
  width: 150px;
  height: 480px;
  background: #f4fbf3;
  background: -webkit-linear-gradient(to right, #f4fbf3 0%, #edf6f1 100%);
  background: -moz-linear-gradient(to right, #f4fbf3 0%, #edf6f1 100%);
  background: linear-gradient(to right, #f4fbf3 0%, #edf6f1 100%);
}

.professional-section .images-column .color-layer-two {
  position: absolute;
  left: -55px;
  bottom: -50px;
  width: 360px;
  height: 475px;
  background: #f2b0c0;
  background: -webkit-linear-gradient(to right, #f2b0c0 0%, #f4e3fb 100%);
  background: -moz-linear-gradient(to right, #f2b0c0 0%, #f4e3fb 100%);
  background: linear-gradient(to right, #f2b0c0 0%, #f4e3fb 100%);
}

.professional-section .images-column .inner-column .pattern-layer {
  position: absolute;
  left: 40px;
  top: -150px;
  width: 241px;
  height: 388px;
  background-repeat: no-repeat;
}

.professional-section .images-column .inner-column .pattern-layer-two {
  position: absolute;
  left: 0px;
  bottom: -110px;
  width: 241px;
  height: 388px;
  background-repeat: no-repeat;
}

.professional-section .images-column .image {
  position: relative;
  padding: 10px;
  display: inline-block;
  background-color: #ffffff;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
}

.professional-section .content-column {
  position: relative;
  margin-bottom: 40px;
}

.professional-section .content-column .inner-column {
  position: relative;
  padding-left: 35px;
}

.professional-section .content-column .inner-column .pattern-layer-three {
  position: absolute;
  right: -85px;
  bottom: 0px;
  width: 243px;
  height: 389px;
  background-repeat: no-repeat;
}

.professional-section .content-column .inner-column .btn-box {
  position: relative;
  margin-top: 40px;
}

.professional-section .content-column .inner-column .sec-title {
  margin-bottom: 25px;
}

.professional-section .content-column .inner-column .bold-text {
  position: relative;
  color: #06092d;
  font-size: 20px;
  line-height: 1.5em;
  padding-right: 100px;
  margin-bottom: 15px;
}

.professional-section .content-column .inner-column p {
  position: relative;
  color: #06092d;
  font-size: 16px;
  line-height: 1.9em;
  padding-right: 100px;
}

/*** 

====================================================================
	Feature Section Two
====================================================================

***/

.feature-section-two {
  position: relative;
  overflow: hidden;
  padding: 120px 0px 40px;
}

.feature-section-two .auto-container {
  max-width: 1570px;
}

.feature-section-two .background-layer-one {
  position: absolute;
  right: 0px;
  top: 110px;
  width: 774px;
  height: 416px;
  background-repeat: no-repeat;
}

.feature-section-two .circle-one {
  position: absolute;
  left: -400px;
  top: 100px;
  width: 595px;
  height: 595px;
  border-radius: 50%;
  background-color: #6af0fc;
}

.feature-section-two .circle-two {
  position: absolute;
  left: -450px;
  top: 250px;
  width: 595px;
  height: 595px;
  border-radius: 50%;
  background-color: #ffffff;
}

/*** 

====================================================================
	Event Section
====================================================================

***/

.event-section {
  position: relative;
  overflow: hidden;
  padding: 60px 0px 0px;
}

.event-section .images-column {
  position: relative;
  margin-bottom: 40px;
}

.event-section .images-column .inner-column {
  position: relative;
}

.event-section .images-column .image {
  position: relative;
}

.event-section .images-column .background-layer-one {
  position: absolute;
  left: -120px;
  top: 0px;
  width: 954px;
  height: 505px;
  background-repeat: no-repeat;
}

.event-section .content-column {
  position: relative;
  margin-bottom: 40px;
}

.event-section .content-column .inner-column {
  position: relative;
  padding-right: 100px;
}

.event-section .content-column .inner-column .btn-box {
  position: relative;
  margin-top: 40px;
}

.event-section .content-column .inner-column .sec-title {
  margin-bottom: 25px;
}

.event-section .content-column .inner-column .bold-text {
  position: relative;
  color: #06092d;
  font-size: 20px;
  line-height: 1.5em;
  margin-bottom: 15px;
}

.event-section .content-column .inner-column p {
  position: relative;
  color: #06092d;
  font-size: 16px;
  line-height: 1.9em;
}

/*** 

====================================================================
	Testimonial Section Two
====================================================================

***/

.testimonial-section-two {
  position: relative;
  overflow: hidden;
  padding: 110px 0px 100px;
}

.testimonial-section-two.style-two {
  padding-bottom: 40px;
}

.testimonial-section-two .circle-one {
  position: absolute;
  right: -150px;
  top: 150px;
  width: 400px;
  height: 400px;
  border-radius: 50%;
  display: inline-block;
  background-color: #6af0fc;
}

.testimonial-section-two .circle-two {
  position: absolute;
  right: -200px;
  top: 320px;
  width: 400px;
  height: 400px;
  border-radius: 50%;
  display: inline-block;
  background-color: #ffffff;
}

.testimonial-section-two .pattern-layer-two {
  position: absolute;
  left: 0px;
  bottom: 150px;
  width: 446px;
  height: 406px;
  background-repeat: no-repeat;
}

.testimonial-section-two .inner-container {
  position: relative;
}

.testimonial-section-two .inner-container .pattern-layer {
  position: absolute;
  left: 0px;
  top: 0px;
  right: 0px;
  height: 519px;
  background-position: center top;
  background-repeat: no-repeat;
}

.testimonial-section-two .owl-carousel .owl-stage-outer {
  padding: 20px 0px 40px;
}

.testimonial-section-two .owl-theme .testimonial-block-two {
  margin: 0px 15px;
}

.testimonial-section-two .owl-carousel {
  margin: 0px -15px;
  width: auto;
}

.testimonial-section-two .owl-dots {
  position: relative;
  text-align: center;
  margin-top: 40px;
}

.testimonial-section-two .owl-dots .owl-dot {
  position: relative;
  margin: 0px 5px;
  width: 10px;
  height: 10px;
  border-radius: 50px;
  display: inline-block;
  border: 2px solid #cce3d7;
  transition: all 500ms ease;
  -moz-transition: all 500ms ease;
  -webkit-transition: all 500ms ease;
  -ms-transition: all 500ms ease;
  -o-transition: all 500ms ease;
}

.testimonial-section-two .owl-dots .owl-dot.active,
.testimonial-section-two .owl-dots .owl-dot:hover {
  border-color: #007cba;
  background-color: #007cba;
}

.testimonial-section-two .owl-nav {
  display: none;
}

.testimonial-block-two {
  position: relative;
  margin-bottom: 30px;
}

.testimonial-block-two .inner-box {
  position: relative;
  padding: 45px 25px 50px;
}

.testimonial-block-two .inner-box .border-layer {
  position: absolute;
  left: 0px;
  bottom: -25px;
  right: 0px;
  height: 150px;
  z-index: -1;
  border-radius: 8px;
  border: 3px solid #06092c;
  -ms-transform: skewY(-2deg);
  transform: skewY(-2deg);
  transition: all 500ms ease;
  -moz-transition: all 500ms ease;
  -webkit-transition: all 500ms ease;
  -ms-transition: all 500ms ease;
  -o-transition: all 500ms ease;
}

.testimonial-block-two .inner-box:hover::before {
  -ms-transform: skewY(2deg);
  transform: skewY(2deg);
}

.testimonial-block-two .inner-box:hover .border-layer {
  -ms-transform: skewY(2deg);
  transform: skewY(2deg);
  border-color: #007cba;
}

.testimonial-block-two .inner-box:before {
  position: absolute;
  content: "";
  left: 0px;
  top: 0px;
  right: 0px;
  bottom: 0px;
  border-radius: 8px;
  background-color: #ffffff;
  -ms-transform: skewY(-2deg);
  transform: skewY(-2deg);
  transition: all 500ms ease;
  -moz-transition: all 500ms ease;
  -webkit-transition: all 500ms ease;
  -ms-transition: all 500ms ease;
  -o-transition: all 500ms ease;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
}

.testimonial-block-two .inner-box .text {
  position: relative;
  color: #06092d;
  font-size: 20px;
  line-height: 1.5em;
  margin-bottom: 30px;
}

.testimonial-block-two .inner-box .author-info {
  position: relative;
}

.testimonial-block-two .inner-box .author-info .info-inner {
  position: relative;
  padding-left: 82px;
  padding-top: 10px;
}

.testimonial-block-two .inner-box .author-info .info-inner .author-image {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 62px;
  height: 62px;
  overflow: hidden;
  border-radius: 50%;
  display: inline-block;
}

.testimonial-block-two .inner-box .author-info h6 {
  position: relative;
  color: #06092d;
  font-weight: 500;
  line-height: 1.3em;
}

.testimonial-block-two .inner-box .author-info .designation {
  position: relative;
  color: #007cba;
  font-weight: 400;
  line-height: 1.2em;
  margin-top: 4px;
}

.testimonial-block-two .inner-box .quote-icon {
  position: absolute;
  width: 60px;
  height: 60px;
  right: 40px;
  bottom: 30px;
  color: #eaf1ed;
  line-height: 60px;
  font-size: 36px;
  text-align: center;
  border-radius: 50px;
  background-color: #007cba;
}

/*** 

====================================================================
	Goal Section
====================================================================

***/

.goal-section {
  position: relative;
  overflow: hidden;
  padding: 100px 0px 110px;
}

.goal-section.style-two {
  padding-top: 120px;
  padding-bottom: 100px;
}

.goal-section .images-column {
  position: relative;
  margin-bottom: 40px;
}

.goal-section .images-column .inner-column {
  position: relative;
}

.goal-section .images-column .image {
  position: relative;
  border-radius: 5px;
  overflow: hidden;
  transform: rotate(-7deg);
}

.goal-section .images-column .image-two {
  position: absolute;
  right: 15px;
  bottom: -160px;
  border-radius: 5px;
  overflow: hidden;
  transform: rotate(7deg);
}

.goal-section .images-column .color-layer {
  position: absolute;
  left: 0px;
  top: 50px;
  width: 470px;
  height: 470px;
  border-radius: 50%;
  background-color: #6af0fc;
}

.goal-section .images-column .pattern-layer {
  position: absolute;
  right: 80px;
  top: 60px;
  width: 241px;
  height: 388px;
  background-repeat: no-repeat;
}

.goal-section .images-column .pattern-layer-two {
  position: absolute;
  left: 50px;
  bottom: -280px;
  width: 385px;
  height: 348px;
  background-repeat: no-repeat;
}

.goal-section .content-column {
  position: relative;
  margin-bottom: 40px;
}

.goal-section .content-column .inner-column {
  position: relative;
  padding-left: 20px;
}

.goal-section .content-column .inner-column .pattern-layer-three {
  position: absolute;
  right: -50px;
  top: 40px;
  width: 69px;
  height: 69px;
  background-repeat: no-repeat;
}

.goal-section .pattern-layer-four {
  position: absolute;
  right: 0px;
  top: 150px;
  width: 667px;
  height: 120px;
  background-repeat: no-repeat;
}

.goal-section .content-column .inner-column .btn-box {
  position: relative;
  margin-top: 40px;
}

.goal-section .content-column .inner-column .sec-title {
}

.goal-section .content-column .inner-column .bold-text {
  position: relative;
  color: #06092d;
  font-size: 20px;
  line-height: 1.5em;
  margin-bottom: 15px;
}

.goal-section .content-column .inner-column p {
  position: relative;
  color: #06092d;
  font-size: 16px;
  line-height: 1.9em;
}

/* Fact Counter */

.fact-counter {
  position: relative;
}

.fact-counter .column {
  position: relative;
  margin-bottom: 40px;
}

.fact-counter .column .inner {
  position: relative;
}

.fact-counter .column .inner .content {
  position: relative;
}

.fact-counter .column .inner .icon {
  position: absolute;
  left: 0px;
  top: 10px;
  color: #bbbbbb;
  font-size: 60px;
  text-align: center;
  line-height: 1em;
  font-weight: 400;
  margin-bottom: 0px;
  transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
}

.fact-counter .count-outer .percentage {
  display: inline-block;
  font-weight: 800;
  color: #06092d;
  font-size: 65px;
  line-height: 1em;
  text-transform: uppercase;
}

.fact-counter .column .inner .count-outer {
  position: relative;
  font-weight: 800;
  color: #06092d;
  font-size: 65px;
  line-height: 1em;
  text-transform: uppercase;
}

.fact-counter .column .inner .counter-title {
  position: relative;
  font-size: 19px;
  font-weight: 700;
  margin-top: 14px;
  color: #06092d;
}

/*** 

====================================================================
	Contact Section
====================================================================

***/

.contact-section {
  position: relative;
  padding: 20px 0px 150px;
}

.contact-section .pattern-layer {
  position: absolute;
  left: 0px;
  bottom: 80px;
  width: 774px;
  height: 416px;
  background-repeat: no-repeat;
}

.contact-section .pattern-layer-two {
  position: absolute;
  right: 0px;
  top: 180px;
  width: 454px;
  height: 120px;
  background-repeat: no-repeat;
}

.contact-section .icon-layer-two {
  position: absolute;
  right: 230px;
  top: 380px;
  width: 69px;
  height: 69px;
  opacity: 0.6;
  background-repeat: no-repeat;
}

.contact-section .icon-layer-three {
  position: absolute;
  right: 230px;
  bottom: 230px;
  width: 69px;
  height: 69px;
  background-repeat: no-repeat;
}

.contact-section .info-column {
  position: relative;
  margin-bottom: 40px;
}

.contact-section .info-column .inner-column {
  position: relative;
  padding-top: 180px;
}

.contact-section .info-column .icon-layer {
  position: absolute;
  left: 170px;
  top: 80px;
  width: 69px;
  height: 69px;
  background-repeat: no-repeat;
}

.contact-section .info-column .image {
  position: relative;
  margin-bottom: 35px;
}

.contact-section .info-column h5 {
  position: relative;
  color: #06092d;
  font-weight: 700;
  line-height: 1.3em;
}

.contact-section .info-column p {
  position: relative;
  color: #06092d;
  font-weight: 400;
  font-size: 16px;
  line-height: 1.7em;
  margin-top: 15px;
  margin-bottom: 18px;
}

.contact-section .info-column .contact-list {
  position: relative;
}

.contact-section .info-column .contact-list li {
  position: relative;
  margin-bottom: 10px;
}

.contact-section .info-column .contact-list li strong {
  position: relative;
  display: block;
  color: #00184f;
  font-size: 16px;
  margin-bottom: 3px;
}

.contact-section .info-column .contact-list li a {
  position: relative;
  color: #007cba;
  transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
}

.contact-section .info-column .contact-list li a:hover {
  color: #111111;
}

.contact-section .form-column {
  position: relative;
  margin-bottom: 40px;
}

.contact-section .form-column .inner-column {
  position: relative;
  padding: 60px 50px;
  margin-left: 40px;
  background-color: #ffffff;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.15);
}

.contact-section .color-layer {
  position: absolute;
  right: -50px;
  bottom: -50px;
  width: 465px;
  height: 465px;
  z-index: -1;
  border-radius: 50%;
  background-color: #6af0fc;
}

.default-form .form-group {
  position: relative;
  margin-bottom: 30px;
}

.default-form .form-group:last-child {
  margin-bottom: 0px;
}

.default-form .form-group input[type="text"],
.default-form .form-group input[type="email"],
.default-form .form-group select {
  position: relative;
  display: block;
  width: 100%;
  line-height: 24px;
  padding: 15px 20px;
  color: #777777;
  height: 50px;
  font-size: 14px;
  background: none;
  font-weight: 400;
  border-radius: 4px;
  border: 1px solid #efefef;
  -webkit-transition: all 300ms ease;
  -ms-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  -moz-transition: all 300ms ease;
  transition: all 300ms ease;
}

.default-form .form-group input::-webkit-input-placeholder,
.default-form .form-group textarea::-webkit-input-placeholder {
  color: #777777;
}

.default-form .form-group input[type="text"]:focus,
.default-form .form-group input[type="email"]:focus,
.default-form .form-group textarea:focus {
}

.default-form .form-group textarea {
  position: relative;
  display: block;
  width: 100%;
  line-height: 24px;
  padding: 15px 25px;
  color: #777777;
  height: 140px;
  border-radius: 4px;
  border: 1px solid #efefef;
  resize: none;
  font-size: 14px;
  font-weight: 400;
  -webkit-transition: all 300ms ease;
  -ms-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  -moz-transition: all 300ms ease;
  transition: all 300ms ease;
}

.default-form .form-group .theme-btn {
  cursor: pointer;
}

/* Custom Select */

.form-group .ui-selectmenu-button.ui-button {
  width: 100%;
  font-size: 14px;
  font-style: normal;
  height: 50px;
  padding: 10px 20px 10px 20px;
  line-height: 30px;
  color: #777777;
  border: none;
  background: #ffffff;
  border-radius: 4px;
  border: 1px solid #efefef;
}

.form-group .ui-button .ui-icon {
  background: none;
  position: relative;
  top: 3px;
  text-indent: 0px;
  color: #333333;
}

.form-group .ui-button .ui-icon:before {
  font-family: "FontAwesome";
  content: "\f107";
  position: absolute;
  right: 0px;
  top: 2px !important;
  top: 13px;
  width: 10px;
  height: 20px;
  display: block;
  color: #999999;
  line-height: 20px;
  font-size: 16px;
  font-weight: normal;
  text-align: center;
  z-index: 5;
  font-weight: 800;
}

.ui-widget.ui-widget-content {
}

.ui-menu .ui-menu-item {
  font-size: 14px;
}

.ui-menu .ui-menu-item:last-child {
  border: none;
}

.ui-state-active,
.ui-widget-content .ui-state-active {
  background: #007cba;
  border-color: #007cba;
}

.ui-menu .ui-menu-item-wrapper {
  position: relative;
  display: block;
  padding: 8px 20px;
  font-size: 14px;
  line-height: 24px;
}

.ui-menu-item:hover {
  background-color: #007cba;
}

/*** 

====================================================================
	Banner Section Three
====================================================================

***/

.banner-section-three {
  position: relative;
  overflow: hidden;
  padding-bottom: 50px;
  box-shadow: 0px 20px 20px rgba(0, 0, 0, 0.1);
}

.banner-section-three .pattern-layer-two {
  position: absolute;
  right: -80px;
  top: 240px;
  width: 410px;
  height: 436px;
  background-position: center bottom;
  background-repeat: no-repeat;
}

.banner-section-three .page-breadcrumb {
  position: relative;
  padding-top: 30px;
  margin-bottom: 80px;
  display: inline-block;
  border-bottom: 1px solid #06092d;
}

.banner-section-three .page-breadcrumb li {
  position: relative;
  padding-right: 10px;
  margin-right: 10px;
  color: #78787c;
  font-size: 16px;
  display: inline-block;
}

.banner-section-three .page-breadcrumb li:before {
  position: absolute;
  content: "-";
  right: -4px;
  top: 0px;
  font-size: 16px;
}

.banner-section-three .page-breadcrumb li a {
  position: relative;
  color: #06092d;
}

.banner-section-three .page-breadcrumb li:last-child {
  margin-right: 0px;
  padding-right: 0px;
}

.banner-section-three .page-breadcrumb li:last-child::before {
  display: none;
}

.banner-section-three .content-column {
  position: relative;
}

.banner-section-three .content-column .inner-column {
  position: relative;
  padding-top: 150px;
}

.banner-section-three .content-column .inner-column h2 {
  position: relative;
  color: #06092d;
  font-size: 55px;
  font-weight: 800;
  line-height: 1.2em;
}

.banner-section-three .content-column .pattern-layer-one {
  position: absolute;
  left: -240px;
  top: -20px;
  width: 467px;
  height: 327px;
  z-index: 2;
  background-position: center bottom;
  background-repeat: no-repeat;
}

.banner-section-three .content-column .icon-layer {
  position: absolute;
  left: -140px;
  bottom: 0px;
  width: 69px;
  height: 69px;
  opacity: 0.6;
}

.banner-section-three .content-column .icon-layer-two {
  position: absolute;
  left: 0px;
  bottom: -120px;
  width: 91px;
  height: 93px;
}

.banner-section-three .image-column {
  position: relative;
}

.banner-section-three .image-column .inner-column {
  position: relative;
  padding-left: 30px;
  padding-bottom: 80px;
  margin-right: -140px;
}

.banner-section-three .image-column .image {
  position: relative;
  display: inline-block;
}

.banner-section-three .image-column .image-two {
  position: absolute;
  right: 0px;
  bottom: 0px;
  box-shadow: 0px 20px 15px rgba(0, 0, 0, 0.15);
}

.banner-section-three .image-column .icon-layer-three {
  position: absolute;
  left: -10px;
  top: -40px;
  width: 91px;
  height: 93px;
}

.banner-section-three .image-column .icon-layer-four {
  position: absolute;
  left: -10px;
  bottom: 50px;
  width: 69px;
  height: 69px;
  z-index: 1;
}

.banner-section-three .image-column .icon-layer-five {
  position: absolute;
  right: -100px;
  top: 0px;
  width: 105px;
  height: 104px;
  z-index: 1;
}

.banner-section-three.style-two {
  padding-bottom: 120px;
}

/*** 

====================================================================
	Team Section
====================================================================

***/

.team-section {
  position: relative;
  overflow: hidden;
  padding: 120px 0px 60px;
  background-color: #f3fbf7;
}

.team-section .inner-container {
  position: relative;
}

.team-section .inner-container .color-layer {
  position: absolute;
  right: -140px;
  top: -50px;
  width: 490px;
  height: 490px;
  border-radius: 50%;
  background-color: #6af0fc;
}

.team-section .pattern-layer {
  position: absolute;
  left: 80px;
  bottom: -50px;
  width: 667px;
  height: 120px;
}

.team-section .title-column {
  position: relative;
  margin-bottom: 40px;
}

.team-section .title-column .inner-column {
  position: relative;
}

.team-section .blocks-column {
  position: relative;
  margin-bottom: 40px;
}

.team-section .blocks-column .inner-column {
  position: relative;
}

.team-block {
  position: relative;
  margin-bottom: 30px;
}

.team-block .inner-box {
  position: relative;
  padding: 35px 35px;
  border-radius: 8px;
  background-color: #ffffff;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
}

.team-block .inner-box .image {
  position: relative;
  overflow: hidden;
  background-color: #007cba;
}

.team-block .inner-box .image img {
  position: relative;
  width: 100%;
  display: block;
  -webkit-transition: all 600ms ease;
  -ms-transition: all 600ms ease;
  -o-transition: all 600ms ease;
  -moz-transition: all 600ms ease;
  transition: all 600ms ease;
}

.team-block .inner-box:hover .image img {
  opacity: 0.7;
  transform: scale(1.05, 1.05) rotate(2deg);
}

.team-block .inner-box .lower-content {
  position: relative;
  padding-top: 30px;
}

.team-block .inner-box .lower-content h5 {
  position: relative;
  font-weight: 700;
  line-height: 1.2em;
}

.team-block .inner-box .lower-content h5 a {
  position: relative;
  color: #06092d;
  -webkit-transition: all 300ms ease;
  -ms-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  -moz-transition: all 300ms ease;
  transition: all 300ms ease;
}

.team-block .inner-box .lower-content h5 a:hover {
  color: #007cba;
}

.team-block .inner-box .lower-content .designation {
  position: relative;
  color: #06092d;
  font-size: 16px;
  margin-top: 10px;
}

/*** 

====================================================================
	Page Title Section
====================================================================

***/

.page-title-section {
  position: relative;
  padding-bottom: 170px;
  background-color: #f3fef9;
}

.page-title-section .icon-layer-one {
  position: absolute;
  left: 90px;
  top: 200px;
  width: 91px;
  height: 93px;
  background-position: center bottom;
  background-repeat: no-repeat;
}

.page-title-section .icon-layer-two {
  position: absolute;
  right: 450px;
  top: 80px;
  width: 91px;
  height: 93px;
  background-position: center bottom;
  background-repeat: no-repeat;
}

.page-title-section .icon-layer-three {
  position: absolute;
  right: 70px;
  top: 125px;
  width: 105px;
  height: 104px;
  background-position: center bottom;
  background-repeat: no-repeat;
}

.page-title-section .icon-layer-four {
  position: absolute;
  left: 340px;
  bottom: 60px;
  width: 106px;
  height: 70px;
  background-position: center bottom;
  background-repeat: no-repeat;
}

.page-title-section .icon-layer-five {
  position: absolute;
  right: 340px;
  bottom: 60px;
  width: 106px;
  height: 70px;
  background-position: center bottom;
  background-repeat: no-repeat;
}

.page-title-section .icon-layer-six {
  position: absolute;
  left: 145px;
  bottom: -20px;
  width: 45px;
  height: 43px;
  background-position: center bottom;
  background-repeat: no-repeat;
}

.page-title-section .page-breadcrumb {
  position: relative;
  padding-top: 30px;
  margin-bottom: 90px;
  display: inline-block;
  border-bottom: 1px solid #06092d;
}

.page-title-section .page-breadcrumb li {
  position: relative;
  padding-right: 10px;
  margin-right: 10px;
  color: #78787c;
  font-size: 16px;
  display: inline-block;
}

.page-title-section .page-breadcrumb li:before {
  position: absolute;
  content: "-";
  right: -4px;
  top: 0px;
  font-size: 16px;
}

.page-title-section .page-breadcrumb li a {
  position: relative;
  color: #06092d;
}

.page-title-section .page-breadcrumb li:last-child {
  margin-right: 0px;
  padding-right: 0px;
}

.page-title-section .page-breadcrumb li:last-child::before {
  display: none;
}

.page-title-section .content-box {
  position: relative;
  text-align: center;
}

.page-title-section .content-box h2 {
  position: relative;
  color: #06142d;
  font-size: 36px;
  font-weight: 700;
  line-height: 1.2em;
}

.page-title-section .content-box .text {
  position: relative;
  color: #181818;
  font-size: 18px;
  font-weight: 400;
  line-height: 1.7em;
  margin-top: 18px;
}

/*** 

====================================================================
	Faq Blocks Section
====================================================================

***/

.faq-blocks-section {
  position: relative;
  padding-bottom: 60px;
}

.faq-blocks-section .inner-container {
  position: relative;
  margin-top: -100px;
}

.faq-block {
  position: relative;
  margin-bottom: 30px;
}

.faq-block .inner-box {
  position: relative;
  text-align: center;
  padding: 50px 30px 40px;
  background-color: #eee1e0;
}

.faq-block:nth-child(2) .inner-box {
  background-color: #e9f5ef;
}

.faq-block:nth-child(2) .inner-box .icon-box {
  color: #007cba;
}

.faq-block:nth-child(3) .inner-box {
  background-color: #f9f9e4;
}

.faq-block:nth-child(3) .inner-box .icon-box {
  color: #bdbd39;
}

.faq-block .inner-box .icon-box {
  position: relative;
  font-size: 64px;
  color: #c56b66;
  line-height: 1em;
}

.faq-block .inner-box h5 {
  position: relative;
  margin-top: 20px;
  color: #06142d;
}

.faq-block .inner-box h5 a {
  position: relative;
  color: #06142d;
  -webkit-transition: all 300ms ease;
  -ms-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  -moz-transition: all 300ms ease;
  transition: all 300ms ease;
}

.faq-block .inner-box h5 a:hover {
}

.faq-block .inner-box .text {
  position: relative;
  color: #06142d;
  font-size: 16px;
  line-height: 1.8em;
  margin: 12px 0px 22px;
}

.faq-block .inner-box .read-more {
  position: relative;
  color: #007cba;
  font-size: 16px;
  font-weight: 500;
  -webkit-transition: all 300ms ease;
  -ms-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  -moz-transition: all 300ms ease;
  transition: all 300ms ease;
}

.faq-block .inner-box .read-more:hover {
  color: #111111;
}

/*** 

====================================================================
	Faq Section
====================================================================

***/

.faq-section {
  position: relative;
  padding-bottom: 60px;
}

.faq-section .title-box {
  position: relative;
  margin-bottom: 25px;
}

.faq-section .title-box h4 {
  position: relative;
  color: #007cba;
  font-weight: 500;
  line-height: 1.3em;
}

.faq-section .column {
  position: relative;
  margin-bottom: 40px;
}

.accordion-box {
  position: relative;
}

.accordion-box .block {
  position: relative;
  margin-bottom: 10px;
  border-radius: 0px;
  border-bottom: 2px solid #f1f3f2;
}

.accordion-box .block.active-block {
  background-color: #f8f8f1;
  border-color: #f8f8f1;
}

.accordion-box .block .acc-btn {
  position: static;
  font-size: 16px;
  line-height: 35px;
  color: #06142d;
  line-height: 1.8em;
  font-weight: 500;
  cursor: pointer;
  padding: 14px 20px;
  padding-right: 25px;
  margin-bottom: 0px;
  -webkit-transition: all 500ms ease;
  -moz-transition: all 500ms ease;
  -ms-transition: all 500ms ease;
  -o-transition: all 500ms ease;
  transition: all 500ms ease;
}

.accordion-box .block .acc-btn.active {
  /* color: #1c17a0; */
  margin-bottom: 0px;
}

.accordion-box .block .acc-btn.active .icon {
}

.accordion-box .block .icon {
  position: absolute;
  right: 0px;
  top: 0px;
  bottom: 0px;
  width: 40px;
  font-size: 20px;
  line-height: 60px;
  color: #666666;
  text-align: center;
  transition: all 500ms ease;
  -moz-transition: all 500ms ease;
  -webkit-transition: all 500ms ease;
  -ms-transition: all 500ms ease;
  -o-transition: all 500ms ease;
  border-radius: 8px 8px 8px 0px;
}

.accordion-box .block .icon::before {
  transition: all 500ms ease;
  -moz-transition: all 500ms ease;
  -webkit-transition: all 500ms ease;
  -ms-transition: all 500ms ease;
  -o-transition: all 500ms ease;
}

.accordion-box .block .acc-btn.active .icon::before {
  transform: rotate(180deg);
  display: inline-block;
}

.accordion-box .block .acc-content {
  position: relative;
  display: none;
}

.accordion-box .block .content {
  position: relative;
  padding: 10px 20px 30px 20px;
}

.accordion-box .block .acc-content.current {
  display: block;
}

.accordion-box .block .content .text {
  position: relative;
}

.accordion-box .block .content .text p {
  position: relative;
  color: #06142d;
  font-size: 16px;
  line-height: 1.7em;
  margin-bottom: 15px;
}

.accordion-box .block .content .text p:last-child {
  margin-bottom: 0px;
}

/*** 

====================================================================
	Team Section Two
====================================================================

***/

.team-section-two {
  position: relative;
  overflow: hidden;
  padding: 120px 0px 80px;
  background-color: #f3fbf7;
}

.team-section-two .inner-container {
  position: relative;
}

.team-section-two .inner-container .color-layer {
  position: absolute;
  right: -140px;
  top: -50px;
  width: 490px;
  height: 490px;
  border-radius: 50%;
  background-color: #6af0fc;
}

.team-section-two .pattern-layer {
  position: absolute;
  left: 550px;
  top: 350px;
  width: 667px;
  height: 120px;
}

.team-section-two .pattern-layer-two {
  position: absolute;
  left: -120px;
  bottom: 80px;
  width: 774px;
  height: 416px;
  background-repeat: no-repeat;
}

.team-section-two .pattern-layer-three {
  position: absolute;
  left: 120px;
  top: 180px;
  width: 105px;
  height: 104px;
  background-repeat: no-repeat;
}

.team-section-two .pattern-layer-four {
  position: absolute;
  right: 120px;
  bottom: 180px;
  width: 105px;
  height: 104px;
  background-repeat: no-repeat;
}

.team-section-two .pattern-layer-five {
  position: absolute;
  right: -170px;
  top: 80px;
  z-index: 1;
  width: 105px;
  height: 104px;
  background-repeat: no-repeat;
}

/*** 

====================================================================
	User Profile Three
====================================================================

***/

.user-profile-section {
  position: relative;
  overflow: hidden;
  padding-bottom: 240px;
  background-color: #f3fef9;
}

.user-profile-section .pattern-layer-one {
  position: absolute;
  left: 100px;
  top: 250px;
  width: 91px;
  height: 93px;
  background-position: center bottom;
  background-repeat: no-repeat;
}

.user-profile-section .pattern-layer-two {
  position: absolute;
  left: 75%;
  top: 90px;
  width: 91px;
  height: 93px;
  background-position: center bottom;
  background-repeat: no-repeat;
}

.user-profile-section .pattern-layer-three {
  position: absolute;
  right: 70px;
  top: 110px;
  width: 105px;
  height: 104px;
  background-position: center bottom;
  background-repeat: no-repeat;
}

.user-profile-section .pattern-layer-four {
  position: absolute;
  left: 150px;
  bottom: 70px;
  width: 45px;
  height: 43px;
  background-position: center bottom;
  background-repeat: no-repeat;
}

.user-profile-section .page-breadcrumb {
  position: relative;
  padding-top: 30px;
  margin-bottom: 80px;
  display: inline-block;
  border-bottom: 1px solid #06092d;
}

.user-profile-section .page-breadcrumb li {
  position: relative;
  padding-right: 10px;
  margin-right: 10px;
  color: #78787c;
  font-size: 16px;
  display: inline-block;
}

.user-profile-section .page-breadcrumb li:before {
  position: absolute;
  content: "-";
  right: -4px;
  top: 0px;
  font-size: 16px;
}

.user-profile-section .page-breadcrumb li a {
  position: relative;
  color: #06092d;
}

.user-profile-section .page-breadcrumb li:last-child {
  margin-right: 0px;
  padding-right: 0px;
}

.user-profile-section .page-breadcrumb li:last-child::before {
  display: none;
}

.user-profile-section .profile-box {
  position: relative;
  margin-left: 135px;
}

.user-profile-section .profile-box .box-inner {
  position: relative;
  padding-left: 235px;
}

.user-profile-section .profile-box .box-inner .image {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 197px;
  height: 232px;
  border-radius: 5px;
}

.user-profile-section .profile-box .box-inner h4 {
  position: relative;
  color: #06142d;
  font-weight: 700;
  line-height: 1.3em;
}

.user-profile-section .profile-box .box-inner .text {
  position: relative;
  color: #06142d;
  font-size: 16px;
  margin-top: 15px;
  margin-bottom: 16px;
  line-height: 1.8em;
}

.user-profile-section .profile-box .box-inner .rating {
  position: relative;
  color: #fbb039;
  font-size: 16px;
}

.user-profile-section .profile-box .box-inner .rating strong {
  position: relative;
  color: #000000;
  margin-left: 10px;
  font-size: 16px;
}

.user-profile-section .profile-box .box-inner .rating i {
  position: relative;
  color: #000000;
  top: -2px;
  font-size: 14px;
  font-weight: 400;
  font-style: normal;
  margin-left: 5px;
}

.user-profile-section .profile-box .box-inner .price {
  position: relative;
  color: #423b3c;
  font-size: 20px;
  font-weight: 700;
  margin-top: 10px;
  margin-bottom: 22px;
}

.user-profile-section .profile-box .box-inner .price span {
  position: relative;
  font-weight: 400;
  font-size: 16px;
  margin-left: 10px;
  text-decoration: line-through;
}

.user-profile-section .social-box {
  position: relative;
  margin-top: 22px;
}

.user-profile-section .social-box li {
  position: relative;
  margin-right: 8px;
  display: inline-block;
}

.user-profile-section .social-box li a {
  position: relative;
  color: #ffffff;
  font-size: 15px;
  width: 34px;
  height: 34px;
  line-height: 34px;
  text-align: center;
  border-radius: 50px;
  display: inline-block;
  background-color: #55acee;
}

.user-profile-section .social-box li.twitter a {
  background-color: #55acee;
}

.user-profile-section .social-box li.pinterest a {
  background-color: #bd081c;
}

.user-profile-section .social-box li.facebook a {
  background-color: #3b5999;
}

.user-profile-section .social-box li.dribbble a {
  background-color: #ea4c89;
}

/*** 

====================================================================
	Courses Section Two
====================================================================

***/

.courses-section-two {
  position: relative;
  padding: 0px 0px 70px;
}

.courses-section-two .inner-container {
  position: relative;
  margin-top: -120px;
}

.course-block-two {
  position: relative;
  margin-bottom: 30px;
}

.course-block-two .inner-box {
  position: relative;
  text-align: center;
  padding: 50px 15px 50px;
  background-color: #eee1e0;
}

.course-block-two .inner-box .icon {
  position: relative;
  color: #c56b66;
  font-size: 70px;
  line-height: 1em;
  margin-bottom: 22px;
}

.course-block-two .inner-box h5 {
  position: relative;
  color: #06142d;
  font-size: 20px;
  font-weight: 700;
  line-height: 1.3em;
}

.course-block-two .inner-box .numbers {
  position: relative;
  color: #06142d;
  font-size: 40px;
  font-weight: 800;
  line-height: 1.2em;
  margin-top: 15px;
}

.course-block-two:nth-child(2) .inner-box {
  background-color: #e9f5ef;
}

.course-block-two:nth-child(2) .inner-box .icon {
  color: #36c67e;
}

.course-block-two:nth-child(3) .inner-box {
  background-color: #f9f9e4;
}

.course-block-two:nth-child(3) .inner-box .icon {
  color: #bdbd39;
}

.course-block-two:nth-child(4) .inner-box {
  background-color: #f3eaf3;
}

.course-block-two:nth-child(4) .inner-box .icon {
  color: #cf76cf;
}

.course-block-two:nth-child(5) .inner-box {
  background-color: #ecf3f9;
}

.course-block-two:nth-child(5) .inner-box .icon {
  color: #2a73b1;
}

/*** 

====================================================================
	Pricing Section
====================================================================

***/

.pricing-section {
  position: relative;
  overflow: hidden;
  padding: 130px 0px 40px;
}

.pricing-section .inner-container {
  position: relative;
}

.pricing-section .color-layer {
  position: absolute;
  right: -140px;
  top: -50px;
  width: 490px;
  height: 490px;
  border-radius: 50%;
  background-color: #6af0fc;
}

.pricing-section .pattern-layer {
  position: absolute;
  left: 550px;
  top: 350px;
  width: 667px;
  height: 120px;
}

.pricing-section .pattern-layer-two {
  position: absolute;
  left: -120px;
  bottom: 80px;
  width: 774px;
  height: 416px;
  background-repeat: no-repeat;
}

.pricing-section .pattern-layer-three {
  position: absolute;
  left: 120px;
  top: 320px;
  width: 105px;
  height: 104px;
  background-repeat: no-repeat;
}

.pricing-section .pattern-layer-four {
  position: absolute;
  right: 120px;
  bottom: 180px;
  width: 105px;
  height: 104px;
  background-repeat: no-repeat;
}

.pricing-section .pattern-layer-five {
  position: absolute;
  right: -170px;
  top: 80px;
  z-index: 1;
  width: 105px;
  height: 104px;
  background-repeat: no-repeat;
}

.price-block {
  position: relative;
}

.price-block .inner-box {
  position: relative;
  text-align: center;
  padding: 35px 15px 60px;
  background-color: #f9f9f9;
}

.price-block .inner-box .icon-box {
  position: relative;
  width: 110px;
  height: 110px;
  border-radius: 50%;
  line-height: 110px;
  text-align: center;
  display: inline-block;
  background-color: #ffffff;
}

.price-block .inner-box h4 {
  position: relative;
  font-weight: 700;
  color: #06092d;
  line-height: 1.3em;
  margin-top: 25px;
  margin-bottom: 22px;
}

.price-block .inner-box .price {
  position: relative;
  color: #06092d;
  font-size: 36px;
  font-weight: 700;
  margin-bottom: 35px;
}

.price-block .inner-box .price span {
  position: relative;
  font-weight: 400;
  font-size: 16px;
}

.price-block .inner-box .price-list {
  position: relative;
}

.price-block .inner-box .price-list li {
  position: relative;
  margin-bottom: 12px;
  color: #444557;
  font-size: 16px;
}

.price-block .inner-box .price-list li:last-child {
  margin-bottom: 0px;
}

.price-block .inner-box .btn-box {
  position: relative;
  margin-top: 35px;
}

.price-block .inner-box .btn-box .started-btn {
  position: relative;
  color: #06092d;
  font-size: 16px;
  padding: 12px 26px;
  border-radius: 5px;
  border: 1px solid #007cba;
}

.price-block .inner-box .btn-box .started-btn:hover {
  color: #ffffff;
  background-color: #007cba;
}

/*** 

====================================================================
	Pricing Section Two
====================================================================

***/

.pricing-section-two {
  position: relative;
  overflow: hidden;
  padding: 60px 0px 100px;
}

.pricing-section-two .inner-container {
  position: relative;
}

.pricing-section-two .pattern-layer {
  position: absolute;
  right: 0px;
  bottom: 150px;
  width: 667px;
  height: 120px;
}

.pricing-section-two .pattern-layer-two {
  position: absolute;
  left: -120px;
  bottom: 80px;
  width: 774px;
  height: 416px;
  background-repeat: no-repeat;
}

.pricing-section-two .pattern-layer-three {
  position: absolute;
  left: 120px;
  top: 320px;
  width: 105px;
  height: 104px;
  background-repeat: no-repeat;
}

.pricing-section-two .pattern-layer-four {
  position: absolute;
  right: 120px;
  bottom: 180px;
  width: 105px;
  height: 104px;
  background-repeat: no-repeat;
}

.pricing-tabs {
  position: relative;
  z-index: 2;
}

.pricing-tabs .tabs-content .tab {
  position: relative;
  display: none;
}

.pricing-tabs .tabs-content .tab.active-tab {
  display: block;
}

.pricing-tabs .buttons-outer {
  position: relative;
  text-align: center;
  margin-bottom: 60px;
}

.pricing-tabs .buttons-outer:before {
  position: absolute;
  content: "";
  left: 0px;
  bottom: 5px;
  right: 0px;
  height: 2px;
  background-color: #f1f1f1;
}

.pricing-tabs .tab-buttons {
  position: relative;
  text-align: center;
  display: inline-block;
  background-color: #ffffff;
}

.pricing-tabs .tab-buttons .tab-btn {
  position: relative;
  color: #06092d;
  font-size: 16px;
  font-weight: 700;
  float: left;
  z-index: 1;
  margin: 0px 2px;
  cursor: pointer;
  padding: 14px 32px;
  border-radius: 0px;
  margin-bottom: 0px;
  -webkit-transition: all 300ms ease;
  -ms-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  -moz-transition: all 300ms ease;
  transition: all 300ms ease;
}

.pricing-tabs .tab-buttons .tab-btn:before {
  position: absolute;
  content: "";
  left: 0px;
  top: 0px;
  right: 0px;
  bottom: 0px;
  z-index: -1;
  transform: skewX(10deg);
  background-color: #f3f3f3;
  -webkit-transition: all 300ms ease;
  -ms-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  -moz-transition: all 300ms ease;
  transition: all 300ms ease;
}

.pricing-tabs .tab-buttons .tab-btn:hover::before,
.pricing-tabs .tab-buttons .tab-btn.active-btn::before {
  background-color: #007cba;
}

.pricing-tabs .tab-buttons .tab-btn:hover,
.pricing-tabs .tab-buttons .tab-btn.active-btn {
  color: #ffffff;
}

.pricing-tabs .price-block-two {
  margin-top: 115px;
}

.pricing-tabs .price-block-two.active {
  margin-top: 0px;
}

.price-block-two {
  position: relative;
  margin-bottom: 30px;
}

.price-block-two .inner-box {
  position: relative;
  text-align: center;
  padding: 35px 15px 60px;
  border: 1px solid #ececec;
  background-color: #ffffff;
}

.price-block-two .inner-box .icon-box {
  position: relative;
  width: 110px;
  height: 110px;
  border-radius: 50%;
  line-height: 110px;
  text-align: center;
  display: inline-block;
  background-color: #ffffff;
}

.price-block-two .inner-box h4 {
  position: relative;
  font-weight: 700;
  color: #06092d;
  line-height: 1.3em;
  margin-top: 25px;
  margin-bottom: 22px;
}

.price-block-two .inner-box .price {
  position: relative;
  color: #06092d;
  font-size: 36px;
  font-weight: 700;
  margin-bottom: 35px;
}

.price-block-two .inner-box .price span {
  position: relative;
  font-weight: 400;
  font-size: 16px;
}

.price-block-two .inner-box .price-list {
  position: relative;
}

.price-block-two .inner-box .price-list li {
  position: relative;
  margin-bottom: 12px;
  color: #444557;
  font-size: 16px;
}

.price-block-two .inner-box .price-list li:last-child {
  margin-bottom: 0px;
}

.price-block-two .inner-box .btn-box {
  position: relative;
  margin-top: 35px;
}

.price-block-two .inner-box .btn-box .started-btn {
  position: relative;
  color: #06092d;
  font-size: 16px;
  padding: 12px 26px;
  border-radius: 5px;
  border: 1px solid #007cba;
}

.price-block-two .inner-box .btn-box .started-btn:hover {
  color: #ffffff;
  background-color: #007cba;
}

.price-block-two.active .inner-box {
  border-color: #f9f9f9;
  background-color: #f9f9f9;
}

/*** 

====================================================================
	Courses Page Section
====================================================================

***/

.courses-page-section {
  position: relative;
  overflow: hidden;
  padding: 110px 0px 100px;
}

.courses-page-section .outer-container {
  max-width: 1680px;
  padding: 0px 15px;
  margin: 0 auto;
}

.courses-page-section .pattern-layer {
  position: absolute;
  left: 0px;
  top: 200px;
  width: 236px;
  height: 435px;
  background-repeat: no-repeat;
}

.courses-page-section .pattern-layer-two {
  position: absolute;
  right: -100px;
  top: 200px;
  width: 774px;
  height: 416px;
  background-repeat: no-repeat;
}

.courses-page-section .pattern-layer-three {
  position: absolute;
  left: -100px;
  top: 1100px;
  width: 774px;
  height: 416px;
  background-repeat: no-repeat;
}

.courses-page-section .pattern-layer-four {
  position: absolute;
  right: 0px;
  bottom: 920px;
  width: 235px;
  height: 255px;
  background-repeat: no-repeat;
}

.courses-page-section .styled-pagination {
  margin-top: 30px;
}

.filter-box {
  position: relative;
  margin-bottom: 50px;
}

.filter-box .filter-categories {
  position: absolute;
  left: 0px;
  top: 100%;
  right: 0px;
  z-index: 1;
  opacity: 0;
  visibility: hidden;
  padding: 40px 30px;
  transition: all 500ms ease;
  -moz-transition: all 500ms ease;
  -webkit-transition: all 500ms ease;
  -ms-transition: all 500ms ease;
  -o-transition: all 500ms ease;
  background-color: #fffef7;
  box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.15);
}

.filter-box .filter-categories.active {
  opacity: 1;
  visibility: visible;
}

.filter-box .box-inner .filter-dropdown:hover .filter-categories {
  opacity: 1;
  visibility: visible;
}

.filter-box .filter-categories .column {
  position: relative;
  width: 20%;
  float: left;
}

.filter-box .filter-categories h6 {
  position: relative;
  color: #0c0d24;
  font-weight: 500;
  line-height: 1.3em;
  margin-bottom: 20px;
}

.filter-box .filter-categories .form-group {
  margin-bottom: 10px;
}

.filter-box .filter-categories .form-group:last-child {
  margin-bottom: 0px;
}

.filter-box .filter-categories .form-group .select-box {
  position: relative;
}

.filter-box .filter-categories .form-group .select-box label {
  position: relative;
  font-size: 15px;
  color: #0c0d24;
  font-weight: 400;
  cursor: pointer;
  margin-bottom: 0px;
  padding-left: 35px;
  line-height: 1.6em;
}

.filter-box .filter-categories .form-group .select-box input[type="checkbox"] {
  position: absolute;
  left: 0px;
  top: 2px;
  width: 19px;
  height: 19px;
  visibility: hidden;
  background-color: #ffffff;
}

.filter-box .filter-categories .form-group .select-box .default-check {
  position: absolute;
  left: 0px;
  top: 2px;
  width: 19px;
  height: 19px;
  background-color: #ffffff;
  border: 1px solid #cfcfcf;
}

.filter-box .filter-categories .form-group .select-box .check-icon {
  position: absolute;
  content: "\f00c";
  left: 0px;
  top: 2px;
  width: 19px;
  height: 19px;
  color: #007cba;
  z-index: 99;
  font-size: 14px;
  line-height: 19px;
  text-align: center;
  opacity: 0;
  font-family: "FontAwesome";
}

.filter-box
  .filter-categories
  .form-group
  .select-box
  input[type="checkbox"]:checked
  + label
  .check-icon {
  opacity: 1;
}

.default-form .select-amount {
  position: relative;
  margin-bottom: 20px;
}

.default-form .select-amount .select-box {
  position: relative;
  float: left;
  text-align: center;
  margin: 0px 20px 10px 0px;
}

.default-form .select-amount .input-box {
  position: relative;
  float: left;
  width: 290px;
}

.default-form .select-amount .select-box input[type="radio"] {
  left: 0px;
  top: 0px;
  visibility: hidden;
  position: absolute;
  opacity: 0;
}

.default-form .select-amount .select-box label {
  display: block;
  line-height: 22px;
  padding: 13px 28px;
  text-align: center;
  font-size: 16px;
  font-weight: 700;
  color: #333333;
  border: 1px solid #e0e0e0;
  cursor: pointer;
  border-radius: 50px;
  transition: all 500ms ease;
  font-family: "Roboto Slab", serif;
}

.default-form .select-amount .select-box input[type="radio"]:checked + label {
  background: #ff5722;
  border-color: #ff5722;
  color: #ffffff;
}

.filter-box .box-inner {
  position: relative;
  padding: 10px 10px;
  background-color: #f6f5ef;
}

.filter-box .box-inner .dropdown-outer {
  position: static;
  float: left;
}

.filter-box .box-inner .filter-dropdown {
  position: static;
  width: 125px;
  height: 45px;
  float: left;
  color: #007cba;
  font-size: 16px;
  margin-right: 10px;
  padding: 10px 20px;
  border-radius: 5px;
  display: inline-block;
  border: 1px solid #007cba;
}

.filter-box .box-inner .filter-dropdown:before {
  position: absolute;
  content: "";
  left: 0px;
  bottom: 0px;
  width: 125px;
  height: 10px;
  display: block;
}

.filter-box .box-inner .filter-dropdown .icon {
  position: relative;
  margin-right: 4px;
}

.filter-box .box-inner .filter-dropdown .arrow {
  position: absolute;
  left: 112px;
}

.filter-box .box-inner .view-list {
  position: relative;
  float: left;
  margin-top: 6px;
}

.filter-box .box-inner .view-list li {
  position: relative;
  margin-right: 5px;
  display: inline-block;
}

.filter-box .box-inner .view-list li a {
  position: relative;
  width: 36px;
  height: 33px;
  color: #7a7b83;
  line-height: 33px;
  border-radius: 3px;
  text-align: center;
  display: inline-block;
  background-color: #ffffff;
  -webkit-transition: all 300ms ease;
  -ms-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  -moz-transition: all 300ms ease;
  transition: all 300ms ease;
}

.filter-box .box-inner .view-list li.active a,
.filter-box .box-inner .view-list li a:hover {
  color: #ffffff;
  background-color: #007cba;
}

.filter-box .box-inner .total-course {
  position: relative;
  color: #06092d;
  font-size: 16px;
  margin-top: 8px;
  margin-right: 15px;
}

.filter-box .box-inner .total-course span {
  color: #007cba;
  font-size: 20px;
  font-weight: 700;
}

/*** 

====================================================================
	Styled Pagination
====================================================================

***/

.styled-pagination {
  position: relative;
}

.styled-pagination li {
  position: relative;
  margin: 0px 10px 10px;
  display: inline-block;
}

.styled-pagination li a {
  position: relative;
  color: #000000;
  font-size: 20px;
  font-weight: 500;
  display: inline-block;
  transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
}

.styled-pagination li.active a,
.styled-pagination li:hover a {
  color: #007cba;
}

.page-links-box {
  position: relative;
  text-align: center;
  margin-bottom: 30px;
}

.page-links-box a {
  position: relative;
  border-radius: 5px;
  font-size: 20px;
  color: #06092d;
  line-height: 32px;
  text-align: left;
  margin: 0px 9px 20px;
  display: inline-block;
  background-color: #fbeff7;
  padding: 15px 35px 15px 20px;
}

.page-links-box a .icon {
  position: relative;
  margin-right: 10px;
  padding-right: 15px;
  color: #e372bd;
  font-size: 34px;
  line-height: 1em;
  float: left;
  margin-right: 15px;
  border-right: 1px solid #dcdddf;
  -webkit-transition: all 600ms ease;
  -ms-transition: all 600ms ease;
  -o-transition: all 600ms ease;
  -moz-transition: all 600ms ease;
  transition: all 600ms ease;
}

.page-links-box a:hover .icon {
  -webkit-transform: scale(-1) rotate(180deg);
  -moz-transform: scale(-1) rotate(180deg);
  -ms-transform: scale(-1) rotate(180deg);
  -o-transform: scale(-1) rotate(180deg);
  transform: scale(-1) rotate(180deg);
}

.page-links-box a:nth-child(2) {
  color: #06092d;
  background-color: #f9eee1;
}

.page-links-box a:nth-child(2) .icon {
  border-color: #dcdddf;
  color: #fbb35f;
}

.page-links-box a:nth-child(3) {
  color: #06092d;
  background-color: #e4f5ff;
}

.page-links-box a:nth-child(3) .icon {
  border-color: #dcdddf;
  color: #45b6f8;
}

.page-links-box a:nth-child(4) {
  color: #06092d;
  background-color: #fdf0f0;
}

.page-links-box a:nth-child(4) .icon {
  border-color: #dcdddf;
  color: #fc9d9d;
}

.page-links-box a:nth-child(5) {
  color: #06092d;
  background-color: #eaf0ed;
}

.page-links-box a:nth-child(5) .icon {
  border-color: #dcdddf;
  color: #71e0a9;
}

.page-links-box a:nth-child(6) {
  color: #06092d;
  background-color: #f9f3e2;
}

.page-links-box a:nth-child(6) .icon {
  border-color: #dcdddf;
  color: #deb130;
}

.page-links-box a:nth-child(7) {
  color: #06092d;
  background-color: #edfbeb;
}

.page-links-box a:nth-child(7) .icon {
  border-color: #dcdddf;
  color: #85ce7a;
}

.page-links-box a:nth-child(8) {
  color: #06092d;
  background-color: #f0f2f9;
}

.page-links-box a:nth-child(8) .icon {
  border-color: #dcdddf;
  color: #8ea2ea;
}

.page-links-box a:nth-child(9) {
  color: #06092d;
  background-color: #fff8ef;
}

.page-links-box a:nth-child(9) .icon {
  border-color: #dcdddf;
  color: #d09d5c;
}

/* Course Block Three */

.course-block-three {
  position: relative;
  margin-bottom: 30px;
}

.course-block-three .inner-box {
  position: relative;
  padding-top: 25px;
  min-height: 251px;
  padding-right: 50px;
  padding-left: 225px;
  border: 1px solid #f5f3f3;
}

.course-block-three .inner-box .image {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 200px;
}

.course-block-three .inner-box .image .tag {
  position: absolute;
  left: 15px;
  bottom: 15px;
  color: #06092d;
  padding: 5px 16px;
  border-radius: 5px;
  font-weight: 500;
  letter-spacing: 1px;
  display: inline-block;
  background-color: #6af0fc;
  text-transform: uppercase;
}

.course-block-three .inner-box h4 {
  position: relative;
  font-weight: 700;
  line-height: 1.4em;
  padding-bottom: 20px;
  border-bottom: 1px solid #dadada;
}

.course-block-three .inner-box h4 a {
  position: relative;
  color: #000000;
  transition: all 500ms ease;
  -moz-transition: all 500ms ease;
  -webkit-transition: all 500ms ease;
  -ms-transition: all 500ms ease;
  -o-transition: all 500ms ease;
}

.course-block-three .inner-box h4 a:hover {
  color: #007cba;
}

.course-block-three .inner-box .uni-name {
  position: relative;
  color: #007cba;
  font-size: 15px;
  margin-top: 18px;
  margin-bottom: 12px;
}

.course-block-three .inner-box .rating {
  position: relative;
  color: #fbb039;
  font-size: 16px;
}

.course-block-three .inner-box .rating strong {
  position: relative;
  color: #000000;
  margin-left: 10px;
  font-size: 16px;
}

.course-block-three .inner-box .rating i {
  position: relative;
  color: #000000;
  top: -2px;
  font-size: 14px;
  font-weight: 400;
  font-style: normal;
  margin-left: 5px;
}

.course-block-three .inner-box .price {
  position: relative;
  color: #423b3c;
  font-size: 20px;
  font-weight: 700;
  margin-top: 10px;
  float: left;
  margin-bottom: 22px;
}

.course-block-three .inner-box .price span {
  position: relative;
  font-weight: 400;
  font-size: 16px;
  margin-left: 10px;
  text-decoration: line-through;
}

.course-block-three .inner-box .hovers {
  position: relative;
  color: #000000;
  font-size: 13px;
  float: left;
  margin-top: 12px;
  margin-left: 20px;
}

.course-block-three .inner-box .overlay-content-box {
  position: absolute;
  left: 100%;
  top: -35px;
  width: 360px;
  z-index: 1;
  opacity: 0;
  visibility: hidden;
  padding: 25px 25px;
  background-color: #ffffff;
  transition: all 500ms ease;
  -moz-transition: all 500ms ease;
  -webkit-transition: all 500ms ease;
  -ms-transition: all 500ms ease;
  -o-transition: all 500ms ease;
  transform: translateX(20px);
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
}

.course-block-three .inner-box:hover .overlay-content-box {
  opacity: 1;
  visibility: visible;
  transform: translateX(0px);
}

.course-block-three .inner-box .overlay-content-box:before {
  position: absolute;
  content: "";
  left: -10px;
  top: 32%;
  border-top: 6px solid transparent;
  border-right: 10px solid #ffffff;
  border-bottom: 6px solid transparent;
}

.course-block-three .inner-box .overlay-content-box h4 {
  position: relative;
  font-weight: 700;
  line-height: 1.3em;
  color: #000000;
  font-size: 24px;
  margin-bottom: 20px;
  padding-bottom: 18px;
  border-bottom: 1px solid #dadada;
}

.course-block-three .inner-box .overlay-content-box .uni-name {
  position: relative;
  color: #007cba;
  font-size: 15px;
  margin-top: 18px;
  margin-bottom: 12px;
}

.course-block-three .inner-box .overlay-content-box .rating {
  position: relative;
  color: #fbb039;
  font-size: 16px;
}

.course-block-three .inner-box .overlay-content-box .rating strong {
  position: relative;
  color: #000000;
  margin-left: 10px;
  font-size: 16px;
}

.course-block-three .inner-box .overlay-content-box .rating i {
  position: relative;
  color: #000000;
  top: -2px;
  font-size: 14px;
  font-weight: 400;
  font-style: normal;
  margin-left: 5px;
}

.course-block-three .inner-box .overlay-content-box .price {
  position: relative;
  color: #423b3c;
  font-size: 20px;
  font-weight: 700;
  margin-top: 10px;
  margin-bottom: 22px;
}

.course-block-three .inner-box .overlay-content-box .price span {
  position: relative;
  font-weight: 400;
  font-size: 16px;
  margin-left: 10px;
  text-decoration: line-through;
}

.course-block-three .inner-box .overlay-content-box .hovers {
  position: relative;
  color: #000000;
  font-size: 13px;
}

.course-block-three .inner-box .overlay-content-box .enroll-now {
  position: relative;
  color: #ffffff;
  font-size: 13px;
  padding: 12px 18px;
  border-radius: 5px;
  letter-spacing: 1px;
  background-color: #007cba;
  text-transform: uppercase;
  transition: all 500ms ease;
  -moz-transition: all 500ms ease;
  -webkit-transition: all 500ms ease;
  -ms-transition: all 500ms ease;
  -o-transition: all 500ms ease;
}

.course-block-three .inner-box .overlay-content-box .enroll-now:hover {
  background-color: #111111;
}

.course-block-three .inner-box .overlay-content-box .text {
  position: relative;
  color: #000000;
  font-size: 14px;
  line-height: 1.8em;
  margin-top: 15px;
}

.course-block-three .inner-box .overlay-content-box .lists {
  position: relative;
  margin-top: 20px;
}

.course-block-three .inner-box .overlay-content-box .lists li {
  position: relative;
  margin-bottom: 12px;
  color: #000000;
  font-size: 14px;
  padding-left: 25px;
}

.course-block-three .inner-box .overlay-content-box .lists li:before {
  position: absolute;
  content: "";
  left: 0px;
  top: 10px;
  width: 15px;
  height: 3px;
  background-color: #007cba;
}

.course-block-three .inner-box .overlay-content-box .lists li:last-child {
  margin-bottom: 0px;
}

.course-block-three .inner-box .overlay-content-box .btns-box {
  position: relative;
  margin-top: 22px;
}

.course-block-three .inner-box .overlay-content-box .btns-box .enrol-btn {
  position: relative;
  color: #000000;
  font-size: 16px;
  padding: 16px 26px;
  border-radius: 6px;
  font-weight: 500;
  margin-right: 10px;
  display: inline-block;
  background-color: #6af0fc;
}

.course-block-three .inner-box .overlay-content-box .btns-box .wishlist-btn {
  position: relative;
  color: #000000;
  font-size: 16px;
  padding: 16px 26px;
  border-radius: 6px;
  font-weight: 500;
  display: inline-block;
  background-color: #eef7f3;
}

/* Course Block Four */

.course-block-four {
  position: relative;
  margin-bottom: 30px;
}

.course-block-four .inner-box {
  position: relative;
  padding-top: 25px;
  min-height: 251px;
  padding-left: 30px;
  text-align: left;
  padding-right: 225px;
  border: 1px solid #f5f3f3;
  background-color: #ffffff;
}

.course-block-four .inner-box .image {
  position: absolute;
  right: 0px;
  top: 0px;
  width: 200px;
}

.course-block-four .inner-box .image .tag {
  position: absolute;
  right: 15px;
  bottom: 15px;
  color: #06092d;
  padding: 5px 16px;
  border-radius: 5px;
  font-weight: 500;
  letter-spacing: 1px;
  display: inline-block;
  background-color: #6af0fc;
  text-transform: uppercase;
}

.course-block-four .inner-box h4 {
  position: relative;
  font-weight: 700;
  line-height: 1.4em;
  padding-bottom: 20px;
  border-bottom: 1px solid #dadada;
}

.course-block-four .inner-box h4 a {
  position: relative;
  color: #000000;
  transition: all 500ms ease;
  -moz-transition: all 500ms ease;
  -webkit-transition: all 500ms ease;
  -ms-transition: all 500ms ease;
  -o-transition: all 500ms ease;
}

.course-block-four .inner-box h4 a:hover {
  color: #007cba;
}

.course-block-four .inner-box .uni-name {
  position: relative;
  color: #007cba;
  font-size: 15px;
  margin-top: 18px;
  margin-bottom: 12px;
}

.course-block-four .inner-box .rating {
  position: relative;
  color: #fbb039;
  font-size: 16px;
}

.course-block-four .inner-box .rating strong {
  position: relative;
  color: #000000;
  margin-left: 10px;
  font-size: 16px;
}

.course-block-four .inner-box .rating i {
  position: relative;
  color: #000000;
  top: -2px;
  font-size: 14px;
  font-weight: 400;
  font-style: normal;
  margin-left: 5px;
}

.course-block-four .inner-box .price {
  position: relative;
  color: #423b3c;
  font-size: 20px;
  font-weight: 700;
  margin-top: 10px;
  float: left;
  margin-bottom: 22px;
}

.course-block-four .inner-box .price span {
  position: relative;
  font-weight: 400;
  font-size: 16px;
  margin-left: 10px;
  text-decoration: line-through;
}

.course-block-four .inner-box .hovers {
  position: relative;
  color: #000000;
  font-size: 13px;
  float: left;
  margin-top: 12px;
  margin-left: 20px;
}

.course-block-four .inner-box .overlay-content-box {
  position: absolute;
  left: 60%;
  top: -35px;
  width: 360px;
  z-index: 1;
  opacity: 0;
  visibility: hidden;
  padding: 25px 25px;
  background-color: #ffffff;
  transition: all 500ms ease;
  -moz-transition: all 500ms ease;
  -webkit-transition: all 500ms ease;
  -ms-transition: all 500ms ease;
  -o-transition: all 500ms ease;
  transform: translateX(20px);
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
}

.course-block-four .inner-box:hover .overlay-content-box {
  opacity: 1;
  visibility: visible;
  transform: translateX(0px);
}

.course-block-four .inner-box .overlay-content-box:before {
  position: absolute;
  content: "";
  left: -10px;
  top: 32%;
  border-top: 6px solid transparent;
  border-right: 10px solid #ffffff;
  border-bottom: 6px solid transparent;
}

.course-block-four .inner-box .overlay-content-box h4 {
  position: relative;
  font-weight: 700;
  line-height: 1.3em;
  color: #000000;
  font-size: 24px;
  margin-bottom: 20px;
  padding-bottom: 18px;
  border-bottom: 1px solid #dadada;
}

.course-block-four .inner-box .overlay-content-box .uni-name {
  position: relative;
  color: #007cba;
  font-size: 15px;
  margin-top: 18px;
  margin-bottom: 12px;
}

.course-block-four .inner-box .overlay-content-box .rating {
  position: relative;
  color: #fbb039;
  font-size: 16px;
}

.course-block-four .inner-box .overlay-content-box .rating strong {
  position: relative;
  color: #000000;
  margin-left: 10px;
  font-size: 16px;
}

.course-block-four .inner-box .overlay-content-box .rating i {
  position: relative;
  color: #000000;
  top: -2px;
  font-size: 14px;
  font-weight: 400;
  font-style: normal;
  margin-left: 5px;
}

.course-block-four .inner-box .overlay-content-box .price {
  position: relative;
  color: #423b3c;
  font-size: 20px;
  font-weight: 700;
  margin-top: 10px;
  margin-bottom: 22px;
}

.course-block-four .inner-box .overlay-content-box .price span {
  position: relative;
  font-weight: 400;
  font-size: 16px;
  margin-left: 10px;
  text-decoration: line-through;
}

.course-block-four .inner-box .overlay-content-box .hovers {
  position: relative;
  color: #000000;
  font-size: 13px;
}

.course-block-four .inner-box .overlay-content-box .enroll-now {
  position: relative;
  color: #ffffff;
  font-size: 13px;
  padding: 12px 18px;
  border-radius: 5px;
  letter-spacing: 1px;
  background-color: #007cba;
  text-transform: uppercase;
  transition: all 500ms ease;
  -moz-transition: all 500ms ease;
  -webkit-transition: all 500ms ease;
  -ms-transition: all 500ms ease;
  -o-transition: all 500ms ease;
}

.course-block-four .inner-box .overlay-content-box .enroll-now:hover {
  background-color: #111111;
}

.course-block-four .inner-box .overlay-content-box .text {
  position: relative;
  color: #000000;
  font-size: 14px;
  line-height: 1.8em;
  margin-top: 15px;
}

.course-block-four .inner-box .overlay-content-box .lists {
  position: relative;
  margin-top: 20px;
}

.course-block-four .inner-box .overlay-content-box .lists li {
  position: relative;
  margin-bottom: 12px;
  color: #000000;
  font-size: 14px;
  padding-left: 25px;
}

.course-block-four .inner-box .overlay-content-box .lists li:before {
  position: absolute;
  content: "";
  left: 0px;
  top: 10px;
  width: 15px;
  height: 3px;
  background-color: #007cba;
}

.course-block-four .inner-box .overlay-content-box .lists li:last-child {
  margin-bottom: 0px;
}

.course-block-four .inner-box .overlay-content-box .btns-box {
  position: relative;
  margin-top: 22px;
}

.course-block-four .inner-box .overlay-content-box .btns-box .enrol-btn {
  position: relative;
  color: #000000;
  font-size: 16px;
  padding: 16px 26px;
  border-radius: 6px;
  font-weight: 500;
  margin-right: 10px;
  display: inline-block;
  background-color: #6af0fc;
}

.course-block-four .inner-box .overlay-content-box .btns-box .wishlist-btn {
  position: relative;
  color: #000000;
  font-size: 16px;
  padding: 16px 26px;
  border-radius: 6px;
  font-weight: 500;
  display: inline-block;
  background-color: #eef7f3;
}

/*** 

====================================================================
	Course Detail Banner Section
====================================================================

***/

.cource-detail-banner-section {
  position: relative;
  overflow: hidden;
  padding-bottom: 60px;
  background-color: #f3fef9;
}

.cource-detail-banner-section .pattern-layer-one {
  position: absolute;
  left: 100px;
  top: 250px;
  width: 91px;
  height: 93px;
  background-position: center bottom;
  background-repeat: no-repeat;
}

.cource-detail-banner-section .pattern-layer-two {
  position: absolute;
  left: 75%;
  top: 90px;
  width: 91px;
  height: 93px;
  background-position: center bottom;
  background-repeat: no-repeat;
}

.cource-detail-banner-section .pattern-layer-three {
  position: absolute;
  right: 70px;
  top: 110px;
  width: 105px;
  height: 104px;
  background-position: center bottom;
  background-repeat: no-repeat;
}

.cource-detail-banner-section .pattern-layer-four {
  position: absolute;
  left: 150px;
  bottom: 70px;
  width: 45px;
  height: 43px;
  background-position: center bottom;
  background-repeat: no-repeat;
}

.cource-detail-banner-section .page-breadcrumb {
  position: relative;
  padding-top: 30px;
  margin-bottom: 80px;
  display: inline-block;
  border-bottom: 1px solid #06092d;
}

.cource-detail-banner-section .page-breadcrumb li {
  position: relative;
  padding-right: 10px;
  margin-right: 10px;
  color: #78787c;
  font-size: 16px;
  display: inline-block;
}

.cource-detail-banner-section .page-breadcrumb li:before {
  position: absolute;
  content: "-";
  right: -4px;
  top: 0px;
  font-size: 16px;
}

.cource-detail-banner-section .page-breadcrumb li a {
  position: relative;
  color: #06092d;
}

.cource-detail-banner-section .page-breadcrumb li:last-child {
  margin-right: 0px;
  padding-right: 0px;
}

.cource-detail-banner-section .page-breadcrumb li:last-child::before {
  display: none;
}

.cource-detail-banner-section .content-box {
  position: relative;
  max-width: 700px;
}

.cource-detail-banner-section .content-box .title {
  position: relative;
  color: #ff6815;
  font-size: 16px;
}

.cource-detail-banner-section .content-box h2 {
  position: relative;
  font-weight: 700;
  line-height: 1.3em;
  color: #000000;
  font-size: 36px;
  margin-top: 15px;
}

.cource-detail-banner-section .content-box .course-info {
  position: relative;
  margin-top: 30px;
}

.cource-detail-banner-section .content-box .course-info li {
  position: relative;
  color: #000000;
  font-size: 15px;
  padding-left: 20px;
  margin-right: 20px;
  display: inline-block;
}

.cource-detail-banner-section .content-box .course-info li .icon {
  position: absolute;
  left: 0px;
  top: 0px;
}

.cource-detail-banner-section .content-box .development {
  position: relative;
  color: #007cba;
  font-size: 15px;
  margin-top: 20px;
  margin-bottom: 14px;
}

.cource-detail-banner-section .content-box .rating {
  position: relative;
  color: #fbb039;
  font-size: 16px;
  float: left;
}

.cource-detail-banner-section .content-box .rating strong {
  position: relative;
  color: #000000;
  margin-left: 10px;
  font-size: 16px;
}

.cource-detail-banner-section .content-box .rating i {
  position: relative;
  color: #000000;
  top: -2px;
  font-size: 14px;
  font-weight: 400;
  font-style: normal;
  margin-left: 5px;
}

.cource-detail-banner-section .content-box .hovers {
  position: relative;
  color: #000000;
  font-size: 13px;
  float: left;
  margin-left: 20px;
}

.cource-detail-banner-section .content-box .social-box {
  position: relative;
  top: -5px;
  display: inline-block;
  margin-left: 90px;
}

.cource-detail-banner-section .content-box .social-box span {
  position: relative;
  margin-right: 15px;
  color: #6b6a6a;
  top: 3px;
  font-size: 22px;
}

.cource-detail-banner-section .content-box .social-box li {
  position: relative;
  margin-right: 8px;
  display: inline-block;
}

.cource-detail-banner-section .content-box .social-box li a {
  position: relative;
  color: #ffffff;
  font-size: 15px;
  width: 34px;
  height: 34px;
  line-height: 34px;
  text-align: center;
  border-radius: 50px;
  display: inline-block;
  background-color: #55acee;
}

.cource-detail-banner-section .content-box .social-box li.twitter a {
  background-color: #55acee;
}

.cource-detail-banner-section .content-box .social-box li.pinterest a {
  background-color: #bd081c;
}

.cource-detail-banner-section .content-box .social-box li.facebook a {
  background-color: #3b5999;
}

.cource-detail-banner-section .content-box .social-box li.dribbble a {
  background-color: #ea4c89;
}

/*** 

====================================================================
	Course Detail Section
====================================================================

***/

.course-detail-section {
  position: relative;
  padding-bottom: 60px;
}

.course-detail-section .content-column {
  position: relative;
  padding-bottom: 40px;
}

.course-detail-section .content-column .inner-column {
  position: relative;
  padding-top: 80px;
}

.course-detail-section .content-column h5 {
  position: relative;
  font-weight: 700;
  color: #000000;
  line-height: 1.3em;
  margin-bottom: 15px;
}

.course-detail-section .content-column p {
  position: relative;
  color: #000000;
  font-size: 16px;
  line-height: 1.8em;
  margin-bottom: 25px;
}

.course-detail-section .content-column .learn-box {
  position: relative;
  margin-bottom: 50px;
  padding: 35px 30px 45px;
  border: 1px solid #e9e9e9;
  background-color: #f9f8f3;
}

.course-detail-section .content-column .learn-box .learn-list {
  position: relative;
}

.course-detail-section .content-column .learn-box .learn-list li {
  position: relative;
  color: #000000;
  font-size: 14px;
  line-height: 1.9em;
  padding-left: 36px;
  margin-bottom: 18px;
}

.course-detail-section .content-column .learn-box .learn-list li:before {
  position: absolute;
  content: "";
  left: 0px;
  top: 5px;
  width: 23px;
  height: 23px;
  background: url(../images/icons/check.png) no-repeat;
}

.course-detail-section .content-column .learn-box .learn-list li:last-child {
  margin-bottom: 0px;
}

.course-detail-section .content-column .learn-box {
  position: relative;
  margin-bottom: 50px;
  padding: 35px 30px 45px;
  border: 1px solid #e9e9e9;
  background-color: #f9f8f3;
}

.course-detail-section .content-column .learn-list-two {
  position: relative;
  margin-bottom: 30px;
}

.course-detail-section .content-column .learn-list-two li {
  position: relative;
  color: #000000;
  font-size: 14px;
  line-height: 1.9em;
  padding-left: 36px;
  margin-bottom: 18px;
}

.course-detail-section .content-column .learn-list-two li:before {
  position: absolute;
  content: "";
  left: 0px;
  top: 5px;
  width: 23px;
  height: 23px;
  background: url(../images/icons/check-1.png) no-repeat;
}

.course-detail-section .content-column .learn-list-two li:last-child {
  margin-bottom: 0px;
}

.course-detail-section .content-column .total-lectures {
  position: relative;
  color: #000000;
  font-size: 14px;
  margin-bottom: 25px;
}

.accordion-box-two {
  position: relative;
  border: 1px solid #e9e9e9;
}

.accordion-box-two .block {
  position: relative;
  margin-bottom: 0px;
  border-radius: 0px;
  background-color: #f9f8f3;
  border-bottom: 1px solid #e9e9e9;
}

.accordion-box-two .block:last-child {
  border-bottom: none;
}

.accordion-box-two .block.active-block {
  background-color: #f8f8f1;
  border-color: #f8f8f1;
}

.accordion-box-two .block .acc-btn {
  position: relative;
  font-size: 16px;
  color: #06142d;
  line-height: 1.8em;
  font-weight: 500;
  cursor: pointer;
  padding: 14px 60px;
  padding-right: 25px;
  margin-bottom: 0px;
  -webkit-transition: all 500ms ease;
  -moz-transition: all 500ms ease;
  -ms-transition: all 500ms ease;
  -o-transition: all 500ms ease;
  transition: all 500ms ease;
}

.accordion-box-two .block .acc-btn .side-text {
  position: absolute;
  right: 20px;
}

.accordion-box-two .block .acc-btn.active {
  margin-bottom: 0px;
  border-bottom: 1px solid #e9e9e9;
}

.accordion-box-two .block .acc-btn.active .icon {
}

.accordion-box-two .block .icon {
  position: absolute;
  left: 0px;
  top: 0px;
  bottom: 0px;
  width: 40px;
  font-size: 20px;
  line-height: 60px;
  color: #666666;
  text-align: center;
  transition: all 500ms ease;
  -moz-transition: all 500ms ease;
  -webkit-transition: all 500ms ease;
  -ms-transition: all 500ms ease;
  -o-transition: all 500ms ease;
  border-right: 1px solid #e9e9e9;
}

.accordion-box-two .block .icon::before {
  transition: all 500ms ease;
  -moz-transition: all 500ms ease;
  -webkit-transition: all 500ms ease;
  -ms-transition: all 500ms ease;
  -o-transition: all 500ms ease;
}

.accordion-box-two .block .acc-btn.active .icon::before {
  transform: rotate(180deg);
  display: inline-block;
}

.accordion-box-two .block .acc-content {
  position: relative;
  display: none;
  background-color: #fafaf9;
}

.accordion-box-two .block .content {
  position: relative;
  padding: 0px 25px 20px 25px;
}

.accordion-box-two .block .acc-content.current {
  display: block;
}

.accordion-box-two .block .content .accordion-list {
  position: relative;
}

.accordion-box-two .block .content .accordion-list li {
  position: relative;
  padding: 16px 0px;
  border-bottom: 1px solid #e9e9e9;
}

.accordion-box-two .block .content .accordion-list li:last-child {
  border-bottom: 0px;
  margin-bottom: 0px;
}

.accordion-box-two .block .content .accordion-list li a {
  position: relative;
  color: #000000;
  font-size: 15px;
  display: block;
  padding-left: 28px;
}

.accordion-box-two .block .content .accordion-list li a .time {
  position: absolute;
  right: 0px;
}

.accordion-box-two .block .content .accordion-list li a .list-icon {
  position: absolute;
  left: 0px;
  top: 1px;
}

.course-detail-section .read-more {
  position: relative;
  text-align: right;
  margin-top: 25px;
  margin-bottom: 25px;
}

.course-detail-section .read-more a {
  position: relative;
  color: #007cba;
}

/* Author Box */

.author-box {
  position: relative;
  margin-bottom: 65px;
}

.author-box .box-inner {
  position: relative;
  padding-left: 230px;
}

.author-box .box-inner .image {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 200px;
  height: 230px;
  border-radius: 5px;
  overflow: hidden;
}

.author-box .box-inner h6 {
  position: relative;
  font-weight: 700;
  color: #000000;
  font-size: 20px;
  line-height: 1.3em;
}

.author-box .box-inner h6 .icon {
  position: relative;
  width: 30px;
  height: 30px;
  color: #ffffff;
  font-size: 14px;
  text-align: center;
  line-height: 30px;
  margin-left: 20px;
  border-radius: 50px;
  display: inline-block;
  background-color: #007cba;
}

.author-box .box-inner .designation {
  position: relative;
  color: #000000;
  font-size: 14px;
  margin-top: 8px;
  margin-bottom: 10px;
}

.author-box .box-inner .list {
  position: relative;
}

.author-box .box-inner .list li {
  position: relative;
  margin-bottom: 4px;
  color: #000000;
  font-size: 14px;
  padding-left: 25px;
}

.author-box .box-inner .list .icon {
  position: absolute;
  left: 0px;
  top: 1px;
  font-size: 16px;
}

.author-box .box-inner .text {
  position: relative;
  color: #000000;
  font-size: 14px;
  opacity: 0.7;
  margin-top: 15px;
}

/*** 

====================================================================
	Comments Area
====================================================================

 ***/

.course-detail-section .comments-area {
  position: relative;
}

.course-detail-section .group-title {
  position: relative;
}

.course-detail-section .group-title h3 {
  position: relative;
  color: #222222;
  font-weight: 700;
  margin-bottom: 30px;
  text-transform: capitalize;
}

.course-detail-section .comments-area .comment-box {
  position: relative;
  padding: 0px 0px 0px;
  margin-bottom: 50px;
  -webkit-transition: all 300ms ease;
  -ms-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  -moz-transition: all 300ms ease;
  transition: all 300ms ease;
}

.course-detail-section .comments-area .comment-box:last-child {
  margin-bottom: 0px;
}

.course-detail-section .comments-area .comment {
  position: relative;
  font-size: 14px;
  min-height: 120px;
  margin-bottom: 40px;
  padding: 5px 0px 0px 110px;
}

.course-detail-section .comments-area .reply-comment {
  margin-left: 60px;
}

.course-detail-section .comments-area .comment-box .author-thumb {
  position: absolute;
  left: 0px;
  top: 5px;
  width: 80px;
  border-radius: 5px;
  margin-bottom: 20px;
  overflow: hidden;
}

.course-detail-section .comments-area .comment-box .author-thumb img {
  width: 100%;
  display: block;
}

.course-detail-section .comments-area .comment-info {
  margin-bottom: 8px;
}

.course-detail-section .comments-area .comment-info .rating {
  position: relative;
  color: #fcb120;
  font-size: 18px;
  display: inline-block;
}

.course-detail-section .comments-area .comment-box strong {
  font-size: 16px;
  font-weight: 700;
  color: #000000;
  display: block;
  line-height: 1.3em;
  margin-bottom: 6px;
  text-transform: capitalize;
}

.course-detail-section .comments-area .comment-box .text {
  color: #000000;
  opacity: 0.7;
  font-size: 14px;
  line-height: 1.8em;
  margin-bottom: 0px;
  padding-top: 0px;
}

.course-detail-section .comments-area .comment-info .comment-time {
  font-size: 15px;
  color: #777777;
  margin-left: 20px;
  display: inline-block;
}

.course-detail-section .comments-area .like-dislike {
  position: relative;
  margin-left: 20px;
  display: inline-block;
}

.course-detail-section .comments-area .like-dislike li {
  position: relative;
  margin-right: 8px;
  display: inline-block;
}

.course-detail-section .comments-area .like-dislike li a {
  position: relative;
  width: 34px;
  height: 34px;
  color: #;
  line-height: 32px;
  text-align: center;
  border-radius: 50px;
  display: inline-block;
  border: 1px solid #dde1df;
  -webkit-transition: all 300ms ease;
  -ms-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  -moz-transition: all 300ms ease;
  transition: all 300ms ease;
}

.course-detail-section .comments-area .like-dislike li a:hover {
  color: #ffffff;
  border-color: #007cba;
  background-color: #007cba;
}

/*** 

====================================================================
	Comment Form
====================================================================

***/

.comment-form .form-group {
  margin-bottom: 20px;
}

.comment-form .form-group input[type="text"],
.comment-form .form-group input[type="password"],
.comment-form .form-group input[type="tel"],
.comment-form .form-group input[type="email"],
.comment-form .form-group select {
  position: relative;
  display: block;
  width: 100%;
  line-height: 28px;
  padding: 10px 25px;
  color: #888888;
  border: 1px solid #dddddd;
  height: 55px;
  font-size: 14px;
  border-radius: 5px;
  background: #ffffff;
  -webkit-transition: all 300ms ease;
  -ms-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  -moz-transition: all 300ms ease;
  transition: all 300ms ease;
}

.comment-form .form-group input[type="text"]:focus,
.comment-form .form-group input[type="password"]:focus,
.comment-form .form-group input[type="tel"]:focus,
.comment-form .form-group input[type="email"]:focus,
.comment-form .form-group select:focus,
.comment-form .form-group textarea:focus {
  border-color: #007cba;
}

.comment-form .form-group textarea {
  position: relative;
  display: block;
  width: 100%;
  line-height: 24px;
  padding: 15px 25px 25px;
  color: #888888;
  border: 1px solid #dddddd;
  height: 185px;
  background: #ffffff;
  resize: none;
  font-size: 14px;
  border-radius: 5px;
  -webkit-transition: all 300ms ease;
  -ms-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  -moz-transition: all 300ms ease;
  transition: all 300ms ease;
}

.comment-form .theme-btn {
  cursor: pointer;
  margin-top: 10px;
}

.course-detail-section .info-column {
  position: relative;
  padding-bottom: 40px;
}

.course-detail-section .info-column .inner-column {
  position: relative;
  padding: 35px 35px;
  margin-top: -315px;
  background-color: #ffffff;
  box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.15);
}

.course-detail-section .info-column .inner-column:before {
  position: absolute;
  content: "";
  left: -10px;
  top: 29%;
  border-top: 6px solid transparent;
  border-right: 10px solid #ffffff;
  border-bottom: 6px solid transparent;
}

.course-detail-section .info-column .price {
  position: relative;
  color: #007cba;
  font-size: 36px;
  font-weight: 700;
  line-height: 1.2em;
  padding-bottom: 15px;
  border-bottom: 2px solid #dadada;
}

.course-detail-section .info-column .price i {
  position: relative;
  color: #7c7979;
  font-size: 16px;
  font-weight: 500;
  font-style: normal;
  text-decoration: line-through;
}

.course-detail-section .info-column .price span {
  position: relative;
  color: #7c7979;
  font-size: 16px;
  font-weight: 500;
}

.course-detail-section .info-column h5 {
  position: relative;
  font-weight: 700;
  color: #000000;
  line-height: 1.3em;
  margin-top: 25px;
}

.course-detail-section .info-column .text {
  position: relative;
  color: #000000;
  font-size: 14px;
  line-height: 1.9em;
  margin-top: 15px;
  margin-bottom: 20px;
}

.course-detail-section .info-column .level-list {
  position: relative;
}

.course-detail-section .info-column .level-list li {
  position: relative;
  padding-left: 30px;
  color: #000000;
  font-size: 16px;
  max-width: 240px;
  width: 100%;
  margin-bottom: 10px;
}

.course-detail-section .info-column .level-list li:before {
  position: absolute;
  content: "";
  left: 0px;
  top: 12px;
  width: 12px;
  height: 3px;
  background-color: #007cba;
}

.course-detail-section .info-column .level-list li span {
  position: absolute;
  right: 0px;
  color: #000000;
  font-size: 14px;
}

.course-detail-section .info-column .level-list li:last-child {
  margin-bottom: 0px;
}

.course-detail-section .info-column .level-list-two {
  position: relative;
  margin-top: 25px;
}

.course-detail-section .info-column .level-list-two li {
  position: relative;
  padding-left: 30px;
  color: #000000;
  font-size: 14px;
  margin-bottom: 8px;
}

.course-detail-section .info-column .level-list-two li:before {
  position: absolute;
  content: "";
  left: 0px;
  top: 2px;
  width: 23px;
  height: 23px;
  background: url(../images/icons/check-1.png) no-repeat;
}

.course-detail-section .info-column .btns-box {
  position: relative;
  margin-top: 22px;
}

.course-detail-section .info-column .btns-box .enrol-btn {
  position: relative;
  color: #000000;
  font-size: 16px;
  padding: 16px 26px;
  border-radius: 6px;
  font-weight: 500;
  margin-right: 2px;
  display: inline-block;
  background-color: #6af0fc;
}

.course-detail-section .info-column .btns-box .wishlist-btn {
  position: relative;
  color: #000000;
  font-size: 16px;
  padding: 16px 26px;
  border-radius: 6px;
  font-weight: 500;
  display: inline-block;
  background-color: #eef7f3;
}

/* Coupon Form */

.coupon-form {
  position: relative;
  margin-bottom: 35px;
  margin-top: 20px;
}

.coupon-form h6 {
  position: relative;
  color: #00184f;
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 15px;
}

.coupon-form h6 .fa {
  position: relative;
  margin-left: 5px;
  color: #007cba;
}

.coupon-form .form-group {
  position: relative;
  display: block;
  z-index: 1;
  margin-bottom: 0px;
}

.coupon-form .form-group input[type="text"],
.coupon-form .form-group input[type="tel"],
.coupon-form .form-group input[type="email"],
.coupon-form .form-group textarea {
  position: relative;
  width: 100%;
  line-height: 28px;
  padding: 10px 25px;
  height: 50px;
  color: #00184f;
  display: inline-block;
  font-size: 14px;
  letter-spacing: 1px;
  border-radius: 0px;
  -webkit-transition: all 300ms ease;
  -ms-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  -moz-transition: all 300ms ease;
  transition: all 300ms ease;
  background-color: #e8ebef;
}

.coupon-form .form-group input:focus {
}

.coupon-form .form-group input[type="submit"],
.coupon-form .form-group button {
  position: absolute;
  width: 65px;
  height: 50px;
  right: 0px;
  top: 0px;
  bottom: 5px;
  color: #ffffff;
  cursor: pointer;
  border-radius: 0px;
  background-color: #007cba;
  display: inline-block;
}

/*** 

====================================================================
	Blog Page Section
====================================================================

***/

.blog-page-section {
  position: relative;
  padding-top: 0px;
  padding-bottom: 0px;
  background-color: #f3fef9;
}

.blog-classic {
  position: relative;
  margin-top: -100px;
}

.blog-page-section.style-two {
  padding-bottom: 140px;
}

.blog-page-section .pattern-layer-one {
  position: absolute;
  left: 100px;
  top: 250px;
  width: 91px;
  height: 93px;
  background-position: center bottom;
  background-repeat: no-repeat;
}

.blog-page-section .pattern-layer-two {
  position: absolute;
  left: 75%;
  top: 90px;
  width: 91px;
  height: 93px;
  background-position: center bottom;
  background-repeat: no-repeat;
}

.blog-page-section .pattern-layer-three {
  position: absolute;
  right: 70px;
  top: 110px;
  width: 105px;
  height: 104px;
  background-position: center bottom;
  background-repeat: no-repeat;
}

.blog-page-section .page-breadcrumb {
  position: relative;
  padding-top: 30px;
  margin-bottom: 70px;
  display: inline-block;
  border-bottom: 1px solid #06092d;
}

.blog-page-section .page-breadcrumb li {
  position: relative;
  padding-right: 10px;
  margin-right: 10px;
  color: #78787c;
  font-size: 16px;
  display: inline-block;
}

.blog-page-section .page-breadcrumb li:before {
  position: absolute;
  content: "-";
  right: -4px;
  top: 0px;
  font-size: 16px;
}

.blog-page-section .page-breadcrumb li a {
  position: relative;
  color: #06092d;
}

.blog-page-section .page-breadcrumb li:last-child {
  margin-right: 0px;
  padding-right: 0px;
}

.blog-page-section .page-breadcrumb li:last-child::before {
  display: none;
}

.blog-page-section .content-box {
  position: relative;
  max-width: 700px;
  margin-bottom: 80px;
}

.blog-page-section .content-box .text {
  position: relative;
  color: #06092d;
  font-size: 20px;
  line-height: 1.6em;
  margin-top: 20px;
}

.blog-page-section .content-box h2 {
  position: relative;
  font-weight: 700;
  line-height: 1.4em;
  color: #000000;
  font-size: 36px;
  margin-top: 15px;
}

.blog-page-section .outer-container {
  position: relative;
  max-width: 1800px;
  padding: 0px 15px;
  margin: 0 auto;
}

.blog-page-section .news-block {
  margin-bottom: 80px;
}

/*** 

====================================================================
	Sidebar Page Container
====================================================================

***/

.sidebar-page-container {
  position: relative;
  padding: 0px 0px 60px;
}

.sidebar-page-container .content-side,
.sidebar-page-container .sidebar-side {
  margin-bottom: 40px;
}

.sidebar-page-container .sidebar-side .sidebar {
  padding-top: 0px;
}

.sidebar-page-container.style-two {
  padding-top: 95px;
}

.sidebar-title {
  position: relative;
  margin-bottom: 28px;
}

.sidebar-title h4 {
  position: relative;
  color: #06142d;
  font-size: 24px;
  font-weight: 500;
  line-height: 1.3em;
  padding-bottom: 12px;
  text-transform: capitalize;
  border-bottom: 1px solid #dddddd;
}

.sidebar-widget {
  margin-bottom: 35px;
}

/*Search Box Widget*/

.sidebar .search-box .form-group {
  position: relative;
  margin: 0px;
}

.sidebar .search-box .form-group input[type="text"],
.sidebar .search-box .form-group input[type="search"] {
  position: relative;
  line-height: 43px;
  padding: 10px 50px 10px 25px;
  background: #fbfaf4;
  display: block;
  font-size: 14px;
  width: 100%;
  height: 60px;
  color: #000000;
  border-radius: 0px;
  transition: all 500ms ease;
  -moz-transition: all 500ms ease;
  -webkit-transition: all 500ms ease;
  -ms-transition: all 500ms ease;
  -o-transition: all 500ms ease;
}

.sidebar .search-box .form-group button {
  position: absolute;
  right: 0px;
  top: 0px;
  height: 60px;
  width: 60px;
  display: block;
  font-size: 16px;
  color: #dbdbd9;
  border-radius: 0px 5px 5px 0px;
  line-height: 100%;
  font-weight: normal;
  background: #fbfaf4;
}

.sticky-top {
  z-index: 1;
  top: 90px;
}

/*Post Widget*/

.sidebar .popular-posts .post {
  position: relative;
  font-size: 14px;
  color: #666666;
  padding: 0px 0px;
  padding-left: 90px;
  min-height: 100px;
  margin-bottom: 25px;
  border-bottom: 1px solid #dddddd;
}

.sidebar .popular-posts .post:last-child {
  margin-bottom: 0px;
  border-bottom: none;
  min-height: auto;
}

.sidebar .popular-posts .post .post-thumb {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 70px;
  border-radius: 50%;
  overflow: hidden;
}

.sidebar .popular-posts .post .post-thumb .overlay-box {
  position: absolute;
  content: "";
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  display: block;
  color: #ffffff;
  font-size: 18px;
  opacity: 0;
  text-align: center;
  transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  background-color: rgba(0, 0, 0, 0.7);
}

.sidebar .popular-posts .post .post-thumb .overlay-box .fa {
  top: 50%;
  position: relative;
  margin-top: -12px;
  display: block;
}

.sidebar .popular-posts .post:hover .post-thumb .overlay-box {
  opacity: 1;
}

.sidebar .popular-posts .post .post-thumb img {
  display: block;
  width: 100%;
  transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
}

.sidebar .popular-posts .post .text {
  position: relative;
  top: 0px;
  font-size: 16px;
  margin: 0px 0px 0px;
  font-weight: 500;
  color: #06142d;
  line-height: 1.5em;
  text-transform: capitalize;
}

.sidebar .popular-posts .post .text a {
  color: #222222;
  transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
}

.sidebar .popular-posts .post a:hover {
  color: #007cba;
}

.sidebar .popular-posts .post-info {
  position: relative;
  font-size: 16px;
  color: #777777;
  font-weight: 400;
  margin-bottom: 4px;
}

/* Blog Cat */

.blog-cat {
  position: relative;
}

.blog-cat li {
  position: relative;
  margin-bottom: 12px;
}

.blog-cat li a {
  position: relative;
  color: #06142d;
  font-size: 16px;
}

/* Popular Tags */

.sidebar .popular-tags a {
  position: relative;
  display: inline-block;
  line-height: 24px;
  padding: 10px 26px 10px;
  margin: 0px 6px 10px 0px;
  color: #161216;
  text-align: center;
  font-size: 16px;
  background: none;
  font-weight: 600;
  border-radius: 0px;
  background-color: #fbfaf4;
  text-transform: capitalize;
  transition: all 300ms ease;
  -webkit-transition: all 300ms ease;
  -ms-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  -moz-transition: all 300ms ease;
}

.sidebar .popular-tags a:hover {
  background-color: #007cba;
  color: #ffffff;
}

.news-block-two {
  position: relative;
  margin-bottom: 40px;
}

.news-block-two .inner-box {
  position: relative;
}

.news-block-two .inner-box .image {
  position: relative;
  overflow: hidden;
  background-color: #111111;
}

.news-block-two .inner-box .image img {
  position: relative;
  width: 100%;
  display: block;
  transition: all 600ms ease;
  -webkit-transition: all 600ms ease;
  -ms-transition: all 600ms ease;
  -o-transition: all 600ms ease;
  -moz-transition: all 600ms ease;
}

.news-block-two .inner-box:hover .image img {
  overflow: hidden;
  opacity: 0.7;
}

.news-block-two .inner-box .lower-content {
  position: relative;
  padding: 28px 35px 35px;
  background-color: #fbfaf4;
}

.news-block-two .inner-box .lower-content .post-info {
  position: relative;
}

.news-block-two .inner-box .lower-content .post-info li {
  position: relative;
  color: #06142d;
  margin-right: 15px;
  display: inline-block;
}

.news-block-two .inner-box .lower-content .post-info li .icon {
  position: absolute;
  left: 0px;
}

.news-block-two .inner-box .lower-content .post-info li a {
  position: relative;
  color: #06142d;
  padding-left: 20px;
}

.news-block-two .inner-box .lower-content h2 {
  position: relative;
  line-height: 1.3em;
  font-weight: 500;
  margin-top: 12px;
  margin-bottom: 25px;
}

.news-block-two .inner-box .lower-content h2 a {
  position: relative;
  color: #06142d;
  transition: all 300ms ease;
  -webkit-transition: all 300ms ease;
  -ms-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  -moz-transition: all 300ms ease;
}

.news-block-two .inner-box .lower-content h2 a:hover {
  color: #007cba;
}

.news-block-two .inner-box .lower-content .text {
  position: relative;
  color: #06142d;
  opacity: 0.8;
  font-size: 16px;
  line-height: 1.8em;
  margin-bottom: 22px;
}

.news-block-two .inner-box .lower-content .learn-more {
  position: relative;
  color: #007cba;
  font-size: 16px;
  text-transform: uppercase;
  transition: all 300ms ease;
  -webkit-transition: all 300ms ease;
  -ms-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  -moz-transition: all 300ms ease;
}

.news-block-two .inner-box .lower-content .learn-more .icon {
  position: relative;
  margin-left: 5px;
  font-size: 18px;
}

.news-block-two .inner-box .lower-content .learn-more:hover {
  color: #111111;
}

/*** 

====================================================================
	Styled Pagination
====================================================================

***/

.styled-pagination-two {
  position: relative;
}

.styled-pagination-two li {
  position: relative;
  margin-right: 8px;
  display: inline-block;
}

.styled-pagination-two li a {
  position: relative;
  width: 50px;
  height: 50px;
  color: #161216;
  font-size: 16px;
  font-weight: 400;
  line-height: 50px;
  text-align: center;
  border-radius: 50px;
  display: inline-block;
  background-color: #fbfaf4;
  transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
}

.styled-pagination-two li.active a,
.styled-pagination-two li:hover a {
  color: #161216;
  background-color: #6af0fc;
}

/*** 

====================================================================
	Blog Detail Banner Section
====================================================================

***/

.blog-detail-banner-section {
  position: relative;
  overflow: hidden;
  padding-bottom: 120px;
  background-color: #f3fef9;
}

.blog-detail-banner-section .pattern-layer-one {
  position: absolute;
  left: 100px;
  top: 250px;
  width: 91px;
  height: 93px;
  background-position: center bottom;
  background-repeat: no-repeat;
}

.blog-detail-banner-section .pattern-layer-two {
  position: absolute;
  left: 75%;
  top: 90px;
  width: 91px;
  height: 93px;
  background-position: center bottom;
  background-repeat: no-repeat;
}

.blog-detail-banner-section .pattern-layer-three {
  position: absolute;
  right: 70px;
  top: 110px;
  width: 105px;
  height: 104px;
  background-position: center bottom;
  background-repeat: no-repeat;
}

.blog-detail-banner-section .pattern-layer-four {
  position: absolute;
  left: 150px;
  bottom: 70px;
  width: 45px;
  height: 43px;
  background-position: center bottom;
  background-repeat: no-repeat;
}

.blog-detail-banner-section .page-breadcrumb {
  position: relative;
  padding-top: 30px;
  margin-bottom: 80px;
  display: inline-block;
  border-bottom: 1px solid #06092d;
}

.blog-detail-banner-section .page-breadcrumb li {
  position: relative;
  padding-right: 10px;
  margin-right: 10px;
  color: #78787c;
  font-size: 16px;
  display: inline-block;
}

.blog-detail-banner-section .page-breadcrumb li:before {
  position: absolute;
  content: "-";
  right: -4px;
  top: 0px;
  font-size: 16px;
}

.blog-detail-banner-section .page-breadcrumb li a {
  position: relative;
  color: #06092d;
}

.blog-detail-banner-section .page-breadcrumb li:last-child {
  margin-right: 0px;
  padding-right: 0px;
}

.blog-detail-banner-section .page-breadcrumb li:last-child::before {
  display: none;
}

.blog-detail-banner-section .content-box {
  position: relative;
  max-width: 700px;
}

.blog-detail-banner-section .content-box .title {
  position: relative;
  color: #ff6815;
  font-size: 16px;
}

.blog-detail-banner-section .content-box h2 {
  position: relative;
  font-weight: 700;
  line-height: 1.3em;
  color: #000000;
  font-size: 36px;
  margin-top: 15px;
  margin-bottom: 35px;
}

.blog-detail-banner-section .content-box .author-info {
  position: relative;
  font-size: 16px;
  padding-left: 65px;
  color: rgba(6, 20, 45, 0.7);
}

.blog-detail-banner-section .content-box .author-info strong {
  position: relative;
  color: #000000;
  margin-bottom: 6px;
  font-size: 16px;
  display: block;
}

.blog-detail-banner-section .content-box .author-info .author-image {
  position: absolute;
  color: #000000;
  left: 0px;
  top: 0px;
  width: 54px;
  height: 54px;
  overflow: hidden;
  border-radius: 50%;
  display: inline-block;
}

.blog-detail-banner-section .content-box .social-box {
  position: relative;
  margin-top: 22px;
}

.blog-detail-banner-section .content-box .social-box span {
  position: relative;
  margin-right: 15px;
  color: #6b6a6a;
  top: 3px;
  font-size: 22px;
}

.blog-detail-banner-section .content-box .social-box li {
  position: relative;
  margin-right: 6px;
  display: inline-block;
}

.blog-detail-banner-section .content-box .social-box li a {
  position: relative;
  color: #ffffff;
  font-size: 15px;
  width: 34px;
  height: 34px;
  line-height: 34px;
  text-align: center;
  border-radius: 50px;
  display: inline-block;
  background-color: #55acee;
}

.blog-detail-banner-section .content-box .social-box li.twitter a {
  background-color: #55acee;
}

.blog-detail-banner-section .content-box .social-box li.pinterest a {
  background-color: #bd081c;
}

.blog-detail-banner-section .content-box .social-box li.facebook a {
  background-color: #3b5999;
}

.blog-detail-banner-section .content-box .social-box li.dribbble a {
  background-color: #ea4c89;
}

/* Blog Detail */

.blog-detail {
  position: relative;
}

.blog-detail .inner-box {
  position: relative;
}

.blog-detail .inner-box p {
  position: relative;
  color: #06142d;
  font-size: 16px;
  opacity: 0.8;
  line-height: 1.7em;
  margin-bottom: 15px;
}

.blog-detail .inner-box h4 {
  position: relative;
  color: #0b0f18;
  font-weight: 700;
  line-height: 1.3em;
  margin-bottom: 15px;
}

.blog-detail .inner-box .bold-text {
  position: relative;
  font-size: 20px;
  color: #06142d;
  margin-bottom: 0px;
  line-height: 1.5em;
  margin-bottom: 17px;
}

.blog-detail .inner-box .blockquote-box {
  position: relative;
  margin-top: 30px;
  margin-bottom: 40px;
}

.blog-detail .inner-box .blockquote-box .blockquote-column {
  position: relative;
}

.blog-detail .inner-box .blockquote-box .blockquote-column .inner-column {
  position: relative;
  z-index: 1;
  margin-top: 12px;
  padding: 40px 30px;
  margin-right: -30px;
  background-color: #6af0fc;
}

.blog-detail .inner-box .blockquote-box .blockquote-column .inner-column p {
  font-size: 20px;
  opacity: 1;
  z-index: 1;
  line-height: 1.6em;
  margin-bottom: 0px;
}

.blog-detail
  .inner-box
  .blockquote-box
  .blockquote-column
  .inner-column
  .quote-icon {
  position: absolute;
  left: 30px;
  top: 25px;
}

.blog-detail
  .inner-box
  .blockquote-box
  .blockquote-column
  .inner-column
  .quote-icon-two {
  position: absolute;
  right: 10px;
  bottom: 10px;
}

.blog-detail .inner-box .blockquote-box .image-column {
  position: relative;
}

.blog-detail .inner-box .blockquote-box .image-column .inner-column {
  position: relative;
  margin-left: -120px;
}

.blog-detail .inner-box .big-image {
  position: relative;
  margin-top: 35px;
  margin-bottom: 30px;
}

.blog-detail .inner-box .big-image img {
  position: relative;
  width: 100%;
  display: block;
}

/* post share options */

.blog-detail .post-share-options {
  position: relative;
  margin-top: 40px;
}

.blog-detail .post-share-options .tags {
  position: relative;
}

.blog-detail .post-share-options .tags li {
  position: relative;
  margin-right: 4px;
  display: inline-block;
}

.blog-detail .post-share-options .tags li span {
  position: relative;
  color: #2f2f2f;
  font-size: 18px;
  margin-right: 10px;
}

.blog-detail .post-share-options .tags li a {
  position: relative;
  color: #0c0d24;
  font-size: 15px;
  padding: 5px 20px 7px;
  border-radius: 50px;
  border: 1px solid #e6e6e9;
  transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
}

.blog-detail .post-share-options .tags li a:hover {
  color: #ffffff;
  border-color: #007cba;
  background-color: #007cba;
}

.blog-detail .related-projects {
  position: relative;
  margin-top: 80px;
}

/* News Block Three */

.news-block-three {
  position: relative;
  margin-bottom: 30px;
}

.news-block-three .inner-box {
  position: relative;
}

.news-block-three .inner-box .image {
  position: relative;
}

.news-block-three .inner-box .image img {
  position: relative;
  width: 100%;
  display: block;
}

.news-block-three .inner-box .lower-content {
  position: relative;
  padding-top: 20px;
  text-align: center;
}

.news-block-three .inner-box .lower-content h6 {
  position: relative;
  line-height: 1.4em;
  font-weight: 700;
}

.news-block-three .inner-box .lower-content h6 a {
  position: relative;
  color: #06142d;
  font-size: 16px;
  transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
}

.news-block-three .inner-box .lower-content h6 a:hover {
  color: #007cba;
}

.news-block-three .inner-box .lower-content .learn-more {
  position: relative;
  color: #007cba;
  font-size: 14px;
  font-weight: 500;
  margin-top: 15px;
  display: inline-block;
  text-transform: uppercase;
  transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
}

.news-block-three .inner-box .lower-content .learn-more:hover {
  color: #111111;
}

/*** 

====================================================================
	Comment Form
====================================================================

***/

.comment-form {
  position: relative;
  margin-top: 30px;
}

.comment-form .group-title h4 {
  position: relative;
  color: #06142d;
  font-weight: 700;
  line-height: 1.3em;
  margin-bottom: 30px;
}

.comment-form .form-group {
  margin-bottom: 20px;
}

.comment-form .form-group:last-child {
  margin-bottom: 0px;
}

.comment-form .form-group input[type="text"],
.comment-form .form-group input[type="password"],
.comment-form .form-group input[type="tel"],
.comment-form .form-group input[type="email"],
.comment-form .form-group select {
  position: relative;
  display: block;
  width: 100%;
  line-height: 28px;
  padding: 10px 25px;
  color: #888888;
  border: 1px solid #dddddd;
  height: 54px;
  font-size: 14px;
  border-radius: 0px;
  background: #ffffff;
  -webkit-transition: all 300ms ease;
  -ms-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  -moz-transition: all 300ms ease;
  transition: all 300ms ease;
}

.comment-form .form-group input[type="text"]:focus,
.comment-form .form-group input[type="password"]:focus,
.comment-form .form-group input[type="tel"]:focus,
.comment-form .form-group input[type="email"]:focus,
.comment-form .form-group select:focus,
.comment-form .form-group textarea:focus {
  border-color: #007cba;
}

.comment-form .form-group .check-box label {
  position: relative;
  color: #06142d;
  font-size: 15px;
  opacity: 0.8;
}

.comment-form .form-group textarea {
  position: relative;
  display: block;
  width: 100%;
  line-height: 24px;
  padding: 15px 25px 25px;
  color: #888888;
  border: 1px solid #dddddd;
  height: 215px;
  background: #ffffff;
  resize: none;
  font-size: 14px;
  border-radius: 0px;
  -webkit-transition: all 300ms ease;
  -ms-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  -moz-transition: all 300ms ease;
  transition: all 300ms ease;
}

.comment-form .theme-btn {
  cursor: pointer;
}

/*** 

====================================================================
	Contact Banner Section
====================================================================

***/

.contact-banner-section {
  position: relative;
  padding-top: 0px;
  padding-bottom: 100px;
  background-color: #f3fef9;
}

.contact-banner-section .pattern-layer-one {
  position: absolute;
  left: 100px;
  top: 250px;
  width: 91px;
  height: 93px;
  background-position: center bottom;
  background-repeat: no-repeat;
}

.contact-banner-section .pattern-layer-two {
  position: absolute;
  left: 75%;
  top: 90px;
  width: 91px;
  height: 93px;
  background-position: center bottom;
  background-repeat: no-repeat;
}

.contact-banner-section .pattern-layer-three {
  position: absolute;
  right: 70px;
  top: 110px;
  width: 105px;
  height: 104px;
  background-position: center bottom;
  background-repeat: no-repeat;
}

.contact-banner-section .page-breadcrumb {
  position: relative;
  padding-top: 30px;
  margin-bottom: 70px;
  display: inline-block;
  border-bottom: 1px solid #06092d;
}

.contact-banner-section .page-breadcrumb li {
  position: relative;
  padding-right: 10px;
  margin-right: 10px;
  color: #78787c;
  font-size: 16px;
  display: inline-block;
}

.contact-banner-section .page-breadcrumb li:before {
  position: absolute;
  content: "-";
  right: -4px;
  top: 0px;
  font-size: 16px;
}

.contact-banner-section .page-breadcrumb li a {
  position: relative;
  color: #06092d;
}

.contact-banner-section .page-breadcrumb li:last-child {
  margin-right: 0px;
  padding-right: 0px;
}

.contact-banner-section .page-breadcrumb li:last-child::before {
  display: none;
}

.contact-banner-section .content-box {
  position: relative;
  max-width: 700px;
}

.contact-banner-section .content-box .text {
  position: relative;
  color: #06092d;
  font-size: 20px;
  line-height: 1.6em;
  margin-top: 20px;
}

.contact-banner-section .content-box h2 {
  position: relative;
  font-weight: 700;
  line-height: 1.4em;
  color: #000000;
  font-size: 36px;
  margin-top: 15px;
}

/*** 

====================================================================
	Contact Page Section
====================================================================

***/

.contact-page-section {
  position: relative;
  padding: 110px 0px 70px;
}

.contact-page-section .pattern-layer-three {
  position: absolute;
  left: 10%;
  top: 55%;
  width: 45px;
  height: 43px;
  background-repeat: no-repeat;
}

.contact-page-section .info-column {
  position: relative;
  margin-bottom: 30px;
}

.contact-page-section .info-column .inner-column {
  position: relative;
}

.contact-page-section .info-column .title {
  position: relative;
  color: #007cba;
  font-size: 16px;
  text-transform: uppercase;
}

.contact-page-section .info-column h2 {
  position: relative;
  color: #06142d;
  font-weight: 700;
  line-height: 1.2em;
  font-size: 36px;
  margin-top: 20px;
  margin-bottom: 22px;
}

.contact-page-section .info-column .text {
  position: relative;
  color: #181818;
  font-size: 18px;
  line-height: 1.7em;
  margin-bottom: 18px;
}

.contact-page-section .info-column ul {
  position: relative;
}

.contact-page-section .info-column ul li {
  position: relative;
  color: #06142d;
  font-size: 16px;
  line-height: 1.7em;
  margin-bottom: 17px;
}

.contact-page-section .info-column ul li span {
  position: relative;
  display: block;
  color: #fe6915;
  display: block;
  margin-bottom: 5px;
}

.contact-page-section .form-column {
  position: relative;
  margin-bottom: 30px;
}

.contact-page-section .form-column .inner-column {
  position: relative;
  padding: 40px 35px;
  background-color: #f4f7fa;
}

.contact-page-section .form-column .circle-layer {
  position: absolute;
  left: -130px;
  bottom: -60px;
  width: 565px;
  height: 565px;
  z-index: -1;
  border-radius: 50%;
  background-color: #fff7f0;
}

.contact-page-section .form-column .pattern-layer-one {
  position: absolute;
  right: -40px;
  top: -25px;
  width: 106px;
  height: 70px;
  z-index: -1;
  background-repeat: no-repeat;
}

.contact-page-section .form-column .pattern-layer-two {
  position: absolute;
  left: -50px;
  bottom: -25px;
  width: 106px;
  height: 153px;
  z-index: -1;
  background-repeat: no-repeat;
}

.contact-page-section .form-column h2 {
  position: relative;
  color: #06142d;
  font-size: 30px;
  font-weight: 700;
  text-align: center;
}

.contact-page-section .form-column h2:before {
  position: absolute;
  content: "";
  right: 20px;
  top: 0px;
  width: 91px;
  height: 38px;
  background: url(../images/icons/contact-arrow.png);
}

.contact-page-section .form-column .text {
  position: relative;
  color: #181818;
  font-size: 16px;
  line-height: 1.8em;
  text-align: center;
  margin-top: 12px;
  margin-bottom: 30px;
}

/*** 

====================================================================
	Contact Form
====================================================================

***/

.contact-form .form-group {
  margin-bottom: 20px;
}

.contact-form .form-group input[type="text"],
.contact-form .form-group input[type="password"],
.contact-form .form-group input[type="tel"],
.contact-form .form-group input[type="email"],
.contact-form .form-group select {
  position: relative;
  display: block;
  width: 100%;
  line-height: 28px;
  padding: 10px 25px;
  color: #888888;
  height: 55px;
  font-size: 14px;
  border-radius: 3px;
  background: #ffffff;
  border: 1px solid transparent;
  -webkit-transition: all 300ms ease;
  -ms-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  -moz-transition: all 300ms ease;
  transition: all 300ms ease;
}

.contact-form .form-group input[type="text"]:focus,
.contact-form .form-group input[type="password"]:focus,
.contact-form .form-group input[type="tel"]:focus,
.contact-form .form-group input[type="email"]:focus,
.contact-form .form-group select:focus,
.contact-form .form-group textarea:focus {
  border-color: #007cba;
}

.contact-form .form-group textarea {
  position: relative;
  display: block;
  width: 100%;
  line-height: 24px;
  padding: 15px 25px 25px;
  color: #888888;
  height: 185px;
  background: #ffffff;
  resize: none;
  font-size: 14px;
  border-radius: 3px;
  border: 1px solid transparent;
  -webkit-transition: all 300ms ease;
  -ms-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  -moz-transition: all 300ms ease;
  transition: all 300ms ease;
}

.contact-form .theme-btn {
  cursor: pointer;
  margin-top: 10px;
  text-transform: uppercase;
}

.contact-form input.error,
.contact-form select.error,
.contact-form textarea.error {
  border-color: #ff0000 !important;
}

.contact-form label.error {
  display: block;
  line-height: 24px;
  padding: 5px 0px 0px;
  margin: 0px;
  text-transform: uppercase;
  font-size: 12px;
  color: #ff0000;
  font-weight: 500;
}

.map-contact-section {
  position: relative;
  padding: 60px 0px 110px;
}

/*** 

====================================================================
	Error Section
====================================================================

***/

.error-section {
  position: relative;
  text-align: center;
  padding-top: 110px;
  padding-bottom: 100px;
}

.error-section .content {
  position: relative;
  display: inline-block;
}

.error-section .content .color-layer {
  position: absolute;
  right: -100px;
  top: -80px;
  width: 570px;
  height: 570px;
  border-radius: 50%;
  background-color: #fff7f0;
}

.error-section .content .image {
  position: relative;
  z-index: 1;
}

.error-section .content .pattern-layer-one {
  position: absolute;
  left: 37%;
  top: 57%;
  z-index: 1;
  width: 106px;
  height: 143px;
  background-repeat: no-repeat;
}

.error-section .content h1 {
  position: relative;
  color: #1d3d56;
  z-index: 1;
  font-size: 150px;
  font-weight: 700;
  line-height: 1em;
  margin-top: -260px;
}

.error-section .content h3 {
  position: relative;
  color: #06142d;
  z-index: 1;
  font-size: 36px;
  font-weight: 700;
  margin-top: 80px;
}

.error-section .content .text {
  position: relative;
  color: #181818;
  z-index: 1;
  font-size: 18px;
  font-weight: 400;
  margin-top: 20px;
  line-height: 1.7em;
}

/*** 

====================================================================
	Donate Banner Section
====================================================================

***/

.donate-banner-section {
  position: relative;
  padding-top: 0px;
  padding-bottom: 100px;
  background-color: #f3fef9;
}

.donate-banner-section .pattern-layer-one {
  position: absolute;
  left: 100px;
  top: 250px;
  width: 91px;
  height: 93px;
  background-position: center bottom;
  background-repeat: no-repeat;
}

.donate-banner-section .pattern-layer-two {
  position: absolute;
  left: 75%;
  top: 90px;
  width: 91px;
  height: 93px;
  background-position: center bottom;
  background-repeat: no-repeat;
}

.donate-banner-section .pattern-layer-three {
  position: absolute;
  right: 70px;
  top: 110px;
  width: 105px;
  height: 104px;
  background-position: center bottom;
  background-repeat: no-repeat;
}

.donate-banner-section .page-breadcrumb {
  position: relative;
  padding-top: 30px;
  margin-bottom: 70px;
  display: inline-block;
  border-bottom: 1px solid #06092d;
}

.donate-banner-section .page-breadcrumb li {
  position: relative;
  padding-right: 10px;
  margin-right: 10px;
  color: #78787c;
  font-size: 16px;
  display: inline-block;
}

.donate-banner-section .page-breadcrumb li:before {
  position: absolute;
  content: "-";
  right: -4px;
  top: 0px;
  font-size: 16px;
}

.donate-banner-section .page-breadcrumb li a {
  position: relative;
  color: #06092d;
}

.donate-banner-section .page-breadcrumb li:last-child {
  margin-right: 0px;
  padding-right: 0px;
}

.donate-banner-section .page-breadcrumb li:last-child::before {
  display: none;
}

.donate-banner-section .content-box {
  position: relative;
  max-width: 700px;
}

.donate-banner-section .content-box .text {
  position: relative;
  color: #06092d;
  font-size: 20px;
  line-height: 1.6em;
  margin-top: 20px;
}

.donate-banner-section .content-box h2 {
  position: relative;
  font-weight: 700;
  line-height: 1.4em;
  color: #000000;
  font-size: 36px;
  margin-top: 15px;
}

/*** 

====================================================================
	Donate Page Section
====================================================================

***/

.donate-page-section {
  position: relative;
  padding: 110px 0px 70px;
}

.donate-page-section .pattern-layer-one {
  position: absolute;
  left: 10%;
  top: 55%;
  width: 45px;
  height: 43px;
  background-repeat: no-repeat;
}

.donate-page-section .content-column {
  position: relative;
  margin-bottom: 30px;
}

.donate-page-section .content-column .inner-column {
  position: relative;
  padding-top: 50px;
}

.donate-page-section .content-column .title {
  position: relative;
  color: #007cba;
  font-size: 16px;
  text-transform: capitalize;
}

.donate-page-section .content-column h2 {
  position: relative;
  color: #06142d;
  font-weight: 700;
  line-height: 1.2em;
  font-size: 36px;
  margin-top: 20px;
  margin-bottom: 22px;
}

.donate-page-section .content-column p {
  position: relative;
  color: #181818;
  font-size: 18px;
  line-height: 1.7em;
  margin-bottom: 18px;
  padding-right: 30px;
}

.donate-page-section .content-column h4 {
  position: relative;
  color: #06142d;
  font-weight: 700;
  line-height: 1.2em;
  margin-top: 20px;
  margin-bottom: 20px;
}

.donate-page-section .content-column .image {
  position: relative;
  margin-top: 50px;
}

.donate-page-section .donate-column {
  position: relative;
  margin-bottom: 30px;
}

.donate-page-section .donate-column .inner-column {
  position: relative;
  padding: 40px 35px 20px;
  background-color: #e2efef;
}

.donate-page-section .donate-column .title-box {
  position: relative;
  text-align: center;
  padding-bottom: 22px;
  margin-bottom: 40px;
  border-bottom: 1px solid #d7dcdc;
}

.donate-page-section .donate-column .title-box h3 {
  position: relative;
  color: #06142d;
  font-weight: 700;
  line-height: 1.2em;
}

.donate-page-section .donate-column .title-box .text {
  position: relative;
  color: #181818;
  font-size: 18px;
  line-height: 1.7em;
  margin-top: 12px;
}

.donate-page-section .donate-column .title {
  position: relative;
  color: #06142d;
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 15px;
}

.donate-tabs .tab-btns {
  position: relative;
  margin-bottom: 30px;
}

.donate-tabs .tab-btns .tab-btn {
  position: relative;
  display: block;
  margin-right: 8px;
  font-size: 16px;
  background: #ffffff;
  color: #06142d;
  text-transform: capitalize;
  font-weight: 400;
  line-height: 24px;
  cursor: pointer;
  border-radius: 4px;
  margin-bottom: 12px;
  display: inline-block;
  padding: 12px 30px 14px;
  transition: all 500ms ease;
}

.donate-tabs .tab-btns .tab-btn:hover,
.donate-tabs .tab-btns .tab-btn.active-btn {
  color: #ffffff;
  background: #50bd87;
}

.donate-tabs .tabs-content {
  position: relative;
}

.donate-tabs .tabs-content .tab {
  position: relative;
  display: none;
}

.donate-tabs .tabs-content .tab.active-tab {
  display: block;
}

.donate-form .form-group {
  position: relative;
}

.donate-form .form-group label {
  position: relative;
  color: #06142d;
  font-size: 18px;
  display: block;
  font-weight: 600;
  margin-bottom: 20px;
}

.donate-form .form-group label.margin {
  margin-bottom: 0px;
}

.donate-form .select-amount {
  position: relative;
  margin-bottom: 20px;
}

.donate-form .select-amount .select-box {
  position: relative;
  float: left;
  text-align: center;
  margin: 0px 10px 10px 0px;
}

.donate-form .select-amount .input-box {
  position: relative;
  float: left;
  width: 290px;
}

.donate-form .select-amount .select-box input[type="radio"] {
  left: 0px;
  top: 0px;
  visibility: hidden;
  position: absolute;
  opacity: 0;
}

.donate-form .select-amount .select-box label {
  display: block;
  line-height: 22px;
  padding: 13px 24px !important;
  text-align: center;
  font-size: 16px;
  font-weight: 500;
  color: #06142d;
  cursor: pointer;
  border-radius: 5px;
  transition: all 500ms ease;
  background-color: #ffffff;
}

.donate-form .select-amount .select-box input[type="radio"]:checked + label {
  background: #50bd87;
  color: #ffffff;
}

.donate-form .form-group .select-box {
  position: relative;
}

.donate-form .form-group .select-box label {
  position: relative;
  font-size: 16px;
  color: #06142d;
  font-weight: 500;
  cursor: pointer;
  margin-bottom: 0px;
  padding-left: 35px;
  line-height: 1.6em;
}

.donate-form .form-group .select-box input[type="checkbox"] {
  position: absolute;
  left: 0px;
  top: 2px;
  width: 19px;
  height: 19px;
  visibility: hidden;
  background-color: #ffffff;
}

.donate-form .form-group .select-box .default-check {
  position: absolute;
  left: 0px;
  top: 4px;
  width: 19px;
  height: 19px;
  background-color: #ffffff;
  border: 1px solid #cfcfcf;
}

.donate-form .form-group .select-box .check-icon {
  position: absolute;
  content: "\f00c";
  left: 0px;
  top: 4px;
  width: 19px;
  height: 19px;
  color: #50bd87;
  z-index: 99;
  font-size: 12px;
  line-height: 19px;
  text-align: center;
  font-weight: 800;
  opacity: 0;
  font-family: "FontAwesome";
}

.donate-form
  .form-group
  .select-box
  input[type="checkbox"]:checked
  + label
  .check-icon {
  opacity: 1;
}

.donate-form .row {
  margin: 0px -5px;
}

.donate-form .form-group {
  position: relative;
  padding: 0px 5px;
  margin-bottom: 20px;
}

.donate-form .form-group .cards {
  position: relative;
  margin-top: 15px;
}

.donate-form .form-group .captcha {
  position: relative;
  color: #0e2a4e;
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 12px;
  display: block;
}

.donate-form input[type="text"],
.donate-form input[type="email"],
.donate-form input[type="password"],
.donate-form select,
.donate-form textarea {
  display: block;
  width: 100%;
  line-height: 28px;
  height: 45px;
  font-size: 15px;
  padding: 10px 20px;
  background: #ffffff;
  color: #06142d;
  border-radius: 3px;
  transition: all 500ms ease;
  -webkit-transition: all 500ms ease;
  -ms-transition: all 500ms ease;
  -o-transition: all 500ms ease;
}

.donate-form textarea {
  height: 240px;
  resize: none;
  font-size: 16px;
  background: #ffffff;
}

.donate-form input[type="text"]:focus,
.donate-form input[type="email"]:focus,
.donate-form input[type="password"]:focus,
.donate-form select:focus,
.donate-form textarea:focus {
  border-color: #ff5921;
}

.donate-form .form-group button {
  margin-top: 20px;
  width: 100%;
  font-weight: 500;
  text-transform: uppercase;
}
