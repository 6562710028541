.section-2 .container .right ul {
  text-align: left;
}
.section-2 .container .right ul li {
  list-style: disc;
  padding: auto;
}

.importance_ul {
  font-size: 1.1rem;
  margin-left: 20px;
}
.importance_ul li {
  margin: 15px 0;
  list-style: disc;
}
